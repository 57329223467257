import React, { Component } from "react";
import { Table } from "reactstrap";
import "./L1SubTable.css";


class TechTargetSubTable extends Component<any, any> {
    render() {
        return (
            <div>


                <div>
                    <Table className="table-sm table-striped table-hover table-borderless">
                        <thead>
                            <tr>
                                <th className="font-weight-bold border-bottom-0">Keywords</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.laading ? (
                                <>
                                    <tr>
                                        <td>Loading...</td>
                                    </tr>
                                </>
                            ) : (
                                    <>
                                        {this.props.data &&
                                            this.props.data.leadAppendDetailTechTargetKeywords.map((item: any, index: number) => {
                                                return (
                                                    <tr key={`techTarget_sub_table_${index}`}>
                                                        <td className="text-capitalize">{item.techTarget}</td>
                                                    </tr>
                                                );
                                            })}
                                    </>
                                )}
                        </tbody>
                    </Table>
                </div>

            </div>
        );
    }
}

export default TechTargetSubTable;
