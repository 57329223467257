import React, { Component } from "react";
import { message, Radio, Select, Checkbox } from "antd";
import { connect } from "react-redux";
import { fetchCampaignFilters } from "../../api/campaignFiles";
import { fetchDomains } from "../../api/target-account";
import { withRouter } from "react-router-dom";
import SidebarLoader from "../loaders/SidebarLoader";
import CustomRange from "./CustomRange";
import FiltersHeader from "./FiltersHeader";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomLabel from "./CustomLabel";
import staticDefaultValues from "./FilterMockData";
import generateFilterQuery from "./filtersQueryGenerator";
import ApplyBtn from "./ApplyBtn";
import { debounce } from "lodash";

import "./Slidebar.css";

class SideBar extends Component<any, any, any> {
  state: any = {
    siteVisitorCount: "biMonthlySiteVisitorUnique",
    currnetWeekCountRadio: "topKeywordsTwoMonthCount",
    clickCount: "bimonthlyClicksCurrent",
    impressionsCount: "bimonthlyImpressionsCurrent",
    leadCount: "bimonthlyLeadsCurrent",
    loading: false,
    defaultValues: { ...staticDefaultValues },
    filtersData: {
      ...staticDefaultValues,
    },
  };

  clearFilters = async () => {
    await this.setState({
      defaultValues: { ...staticDefaultValues },
      filtersData: { ...staticDefaultValues },
    });
    await this.fetchLeadAppendFilters();
    this.props.clearFilters();
    this.domainSearch("");
  };

  fetchLeadAppendFilters = async () => {
    try {
      this.setState({ loading: true });
      let id = this.props.match.params.id;
      let response: any = await fetchCampaignFilters({
        campaignId: id,
        type: this.props.type,
      });
      //Set default values
      await this.setState({
        loading: false,
        defaultValues: {
          ...staticDefaultValues,
          ...response.data,
          ...{
            weeklyClicksCurrent: {
              min: 0,
              max: 100,
            },
            monthlyClicksCurrent: {
              min: 0,
              max: 100,
            },
            bimonthlyClicksCurrent: {
              min: 0,
              max: 100,
            },
            weeklyLeadsCurrent: {
              min: 0,
              max: 100,
            },
            monthlyLeadsCurrent: {
              min: 0,
              max: 100,
            },
            bimonthlyLeadsCurrent: {
              min: 0,
              max: 100,
            },
            weeklyImpressionsCurrent: {
              min: 0,
              max: 100,
            },
            monthlyImpressionsCurrent: {
              min: 0,
              max: 100,
            },
            bimonthlyImpressionsCurrent: {
              min: 0,
              max: 100,
            },
            
          },
        },
        filtersData:{ ...staticDefaultValues }
      });
      console.log(this.state)
     
    } catch (error) {
      this.setState({ loading: false });
      message.error("Error while filter data!");
    }
  };

  applyFilters = async () => {
    try {
      let query = generateFilterQuery(this.state.filtersData);
      await this.props.applyFilters({ query });
    } catch (error) {
      message.error("There is an issue with filters please try again.");
      this.setState({ loading: false });
      await this.props.applyFilters({ query: `` });
    }
  };

  hanldeSlider = async (key: string, values: any) => {
   
    let objectToUpdate: any = {...this.state.filtersData[key]};
   
    objectToUpdate.min = values[0];
    objectToUpdate.max = values[1];
    await this.setState({
      filtersData: {
        ...this.state.filtersData,
        [key]: { ...objectToUpdate },
      },
    });
   
  };

  handleSelectChange = async (key: string, values: any) => {
    await this.setState({
      filtersData: {
        ...this.state.filtersData,
        [key]: [...values],
      },
    });
  };
  deltaChange = async (key: string, val: any) => {
    await this.setState({
      filtersData: {
        ...this.state.filtersData,
        [key]: val,
      },
    });
    return;
  };

  domainSearch = async (value: string) => {
    let domains = await fetchDomains(this.props.campaignSetupId, value);
    this.setState({
      defaultValues: { ...this.state.defaultValues, domains: domains.data },
    });
  };
  findDoamin = debounce(this.domainSearch, 250, { maxWait: 1000 });

  componentDidMount = async () => {
    await this.fetchLeadAppendFilters();
    this.domainSearch("");
  };

  render() {
    const { defaultValues, filtersData } = this.state;

    const options = defaultValues.domains.map((d: any) => (
      <Select.Option key={d.domain} value={d.domain}>
        {d.domain}
      </Select.Option>
    ));
    return (
      <>
        <FiltersHeader label="Filters" handleClear={this.clearFilters} />
        {this.state.loading ? (
          <SidebarLoader />
        ) : (
          <div className={this.props.type}>
            <div className="font-weight-bold mt-2 text-muted">
              <div>
                {/* <CustomRange
                    filtersData={filtersData}
                    name="fractionScoreNumerator"
                    label="Active Source"
                    defaultValues={defaultValues}
                    handleChange={this.hanldeSlider}
                  />
                  <CustomRange
                    filtersData={filtersData}
                    name="unifiedScore"
                    label="Score"
                    defaultValues={defaultValues}
                    handleChange={this.hanldeSlider}
                  />
                   
                   <Checkbox  className="small text-muted" style={{marginLeft: "0px"}}
                    onChange={(event: any) => {
                       const  isChecked = event.target.checked;
            this.deltaChange('percentageActiveSource', isChecked );
          }}
                    >Active Source Delta</Checkbox>
                   
                    <Checkbox  className="small text-muted mb-2" style={{marginLeft: "0px"}}
                    onChange={(event: any) => {
                       const  isChecked = event.target.checked;
            this.deltaChange('percentageAggregateScore', isChecked );
          }}
                    >Aggregate  Score Delta</Checkbox> */}
                <CustomLabel label="Domain" />
                <Select
                  style={{ height: "12%", borderRadius: "5px", width: "100%" }}
                  bordered={false}
                  className="bg-white py-1"
                  placeholder="Please select"
                  showSearch
                  value={this.state.value}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={this.findDoamin}
                  mode="multiple"
                  onChange={(values: any) => {
                    this.handleSelectChange("domains", values);
                  }}
                  notFoundContent={null}
                >
                  {options}
                </Select>
                {/* <CustomMultiSelect
                  label={"Tier"}
                  name="tier"
                  data={[...defaultValues.tier]}
                  handleChange={this.handleSelectChange}
                /> */}
                <CustomMultiSelect
                  name="surgingTopicListCurrentWeek"
                  label="Surging Topics"
                  data={[...defaultValues.surgingTopicListCurrentWeek]}
                  handleChange={this.handleSelectChange}
                />
                <CustomRange
                  filtersData={filtersData}
                  label="Total Surging Topics"
                  defaultValues={defaultValues}
                  name="totalSurgingTopics"
                  handleChange={this.hanldeSlider}
                />
                {this.props.auth.Role.name === "admin" && (
                  <>
                    <CustomMultiSelect
                      name="l1TrendingTopics"
                      label="HVC"
                      data={[...defaultValues.l1TrendingTopics]}
                      handleChange={this.handleSelectChange}
                    />
                  </>
                )}
                <CustomRange
                  filtersData={filtersData}
                  label="L1Trending Topics Count"
                  name="l1TrendingTopicsCountCurrentWeek"
                  defaultValues={defaultValues}
                  handleChange={this.hanldeSlider}
                />
                {/* <CustomMultiSelect
                  label="Keywords"
                  name="topKeywordsCurrentWeek"
                  data={[...defaultValues.topKeywordsCurrentWeek]}
                  handleChange={this.handleSelectChange}
                />
                <CustomLabel label="Keyword Count" /> 
                <Radio.Group
                  onChange={async (e: any) => {
                    await this.setState({
                      filtersData: {
                        ...this.state.filtersData,
                        topKeywordsCurrentWeekCount: {
                          min: 0,
                          max: 0,
                        },
                        topKeywordsCurrentMonthCount: {
                          min: 0,
                          max: 0,
                        },
                        topKeywordsTwoMonthCount: {
                          min: 0,
                          max: 0,
                        },
                      },
                      currnetWeekCountRadio: e.target.value,
                    });
                  }}
                  value={this.state.currnetWeekCountRadio}
                >
                  <Radio value={"topKeywordsCurrentWeekCount"}>
                    <small className="text-muted">7 days</small>
                  </Radio>
                  <Radio value={"topKeywordsCurrentMonthCount"}>
                    <small className="text-muted">30 Days</small>
                  </Radio>
                  <Radio value={"topKeywordsTwoMonthCount"}>
                    <small className="text-muted">60 Days</small>
                  </Radio>
                </Radio.Group>
                <CustomRange
                  filtersData={filtersData}
                  label=""
                  name={this.state.currnetWeekCountRadio}
                  defaultValues={defaultValues}
                  handleChange={this.hanldeSlider}
                />
                */}
                {/* <CustomMultiSelect
                  label="Bombora"
                  name="bomboraTopics"
                  data={[...defaultValues.bomboraTopics]}
                  handleChange={this.handleSelectChange}
                /> */}
                <CustomMultiSelect
                  label="Top Locations"
                  name="surgingLocations"
                  data={[...defaultValues.surgingLocations]}
                  handleChange={this.handleSelectChange}
                />
                <CustomLabel label="Unique Site Visitors" />
                <Radio.Group
                  onChange={(e: any) => {
                    this.setState({
                      filtersData: {
                        ...this.state.filtersData,
                        weeklySiteVisitorUniqueCurrent: {
                          min: 0,
                          max: 0,
                        },
                        monthlySiteVisitorUniqueCurrent: {
                          min: 0,
                          max: 0,
                        },
                        biMonthlySiteVisitorUnique: {
                          min: 0,
                          max: 0,
                        },
                      },
                      siteVisitorCount: e.target.value,
                    });
                  }}
                  value={this.state.siteVisitorCount}
                >
                  <Radio value={"weeklySiteVisitorUniqueCurrent"}>
                    <small className="text-muted">7 days</small>
                  </Radio>
                  <Radio value={"monthlySiteVisitorUniqueCurrent"}>
                    <small className="text-muted">30 Days</small>
                  </Radio>
                  <Radio value={"biMonthlySiteVisitorUnique"}>
                    <small className="text-muted">60 Days</small>
                  </Radio>
                </Radio.Group>
                <CustomRange
                  filtersData={filtersData}
                  label=""
                  handleChange={this.hanldeSlider}
                  name={this.state.siteVisitorCount}
                  defaultValues={defaultValues}
                />
                <CustomLabel label="Clicks" />
                <Radio.Group
                  onChange={(e: any) => {
                    this.setState({
                      filtersData: {
                        ...this.state.filtersData,
                        weeklyClicksCurrent: {
                          min: 0,
                          max: 0,
                        },
                        monthlyClicksCurrent: {
                          min: 0,
                          max: 0,
                        },
                        bimonthlyClicksCurrent: {
                          min: 0,
                          max: 0,
                        },
                      },
                      clickCount: e.target.value,
                    });
                  }}
                  value={this.state.clickCount}
                >
                  <Radio value={"weeklyClicksCurrent"}>
                    <small className="text-muted">7 days</small>
                  </Radio>
                  <Radio value={"monthlyClicksCurrent"}>
                    <small className="text-muted">30 Days</small>
                  </Radio>
                  <Radio value={"bimonthlyClicksCurrent"}>
                    <small className="text-muted">60 Days</small>
                  </Radio>
                </Radio.Group>
                <CustomRange
                  filtersData={filtersData}
                  label=""
                  handleChange={this.hanldeSlider}
                  name={this.state.clickCount}
                  defaultValues={defaultValues}
                />

                <CustomLabel label="Impressions" />
                <Radio.Group
                  onChange={(e: any) => {
                    this.setState({
                      filtersData: {
                        ...this.state.filtersData,
                        weeklyImpressionsCurrent: {
                          min: 0,
                          max: 0,
                        },
                        monthlyImpressionsCurrent: {
                          min: 0,
                          max: 0,
                        },
                        bimonthlyImpressionsCurrent: {
                          min: 0,
                          max: 0,
                        },
                      },
                      impressionsCount: e.target.value,
                    });
                  }}
                  value={this.state.impressionsCount}
                >
                  <Radio value={"weeklyImpressionsCurrent"}>
                    <small className="text-muted">7 days</small>
                  </Radio>
                  <Radio value={"monthlyImpressionsCurrent"}>
                    <small className="text-muted">30 Days</small>
                  </Radio>
                  <Radio value={"bimonthlyImpressionsCurrent"}>
                    <small className="text-muted">60 Days</small>
                  </Radio>
                </Radio.Group>
                <CustomRange
                  filtersData={filtersData}
                  label=""
                  handleChange={this.hanldeSlider}
                  name={this.state.impressionsCount}
                  defaultValues={defaultValues}
                />

                {/* <CustomLabel label="Leads" />
                <Radio.Group
                  onChange={(e: any) => {
                    this.setState({
                      filtersData: {
                        ...this.state.filtersData,
                        weeklyLeadsCurrent: {
                          min: 0,
                          max: 0,
                        },
                        monthlyLeadsCurrent: {
                          min: 0,
                          max: 0,
                        },
                        bimonthlyLeadsCurrent: {
                          min: 0,
                          max: 0,
                        },
                      },
                      leadCount: e.target.value,
                    });
                  }}
                  value={this.state.leadCount}
                >
                  <Radio value={"weeklyLeadsCurrent"}>
                    <small className="text-muted">7 days</small>
                  </Radio>
                  <Radio value={"monthlyLeadsCurrent"}>
                    <small className="text-muted">30 Days</small>
                  </Radio>
                  <Radio value={"bimonthlyLeadsCurrent"}>
                    <small className="text-muted">60 Days</small>
                  </Radio>
                </Radio.Group>
                <CustomRange
                  filtersData={filtersData}
                  label=""
                  handleChange={this.hanldeSlider}
                  name={this.state.leadCount}
                  defaultValues={defaultValues}
                /> */}
              </div>
            </div>
            <ApplyBtn onApply={this.applyFilters} label="Apply" />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(SideBar));
