import * as types from "./types";

export const doUserLogin = (
  payload: any,
  onSuccess: Function,
  onFailure: Function
) => {
  return {
    type: types.DO_USER_LOGIN,
    payload: payload,
    meta: {
      onSuccess,
      onFailure
    }
  };
};

export const doAdminLogin = (
  payload: any,
  onSuccess: Function,
  onFailure: Function
) => {
  return {
    type: types.DO_ADMIN_LOGIN,
    payload: payload,
    meta: {
      onSuccess,
      onFailure
    }
  };
};

export const doUserLogout = (payload: any) => {
  return {
    type: types.LOGOUT_USER,
    payload: payload
  };
};

export const editUser = (payload: any) => {
  return {
    type: "EDIT_USER",
    payload: payload
  };
};
