import React from "react";
import { MDBDataTable } from "mdbreact";
import Line from "./Line";

const TableLoader = ({
  columns,
  length = 10,
}: {
  columns: Array<any>;
  length?: number;
}): JSX.Element => {
  const data: any = {
    columns: columns,
  };
  let rowItem: any = {};
  columns.forEach((col: any, index) => {
    rowItem[col.field] = <Line />;
  });
  let multipleRows = [];
  for (let i = 0; i < length; i++) {
    multipleRows.push(rowItem);
  }
  data.rows = [...multipleRows];
  return (
    <MDBDataTable
      striped
      responsive
      bordered
      noBottomColumns
      hover
      data={data}
      small={true}
      className="mt-3"
    />
  );
};

export default TableLoader;
