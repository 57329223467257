import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CreateProfileURL from "../../commons/CreateProfileURL";

interface Props {
  modal: Boolean | any;
  toggle: Function | any;
  profileURL: String;
  reteiveLoading: Boolean;
}

class GetLinkModal extends Component<Props, any> {
  render(): JSX.Element {
    let { modal, toggle, profileURL, reteiveLoading } = this.props;
    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} className="modal-lg">
          <ModalHeader toggle={toggle} className="shadow-none">
            User Link
          </ModalHeader>
          <ModalBody>
            <CreateProfileURL
              profileURl={profileURL}
              reteiveLoading={reteiveLoading}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default GetLinkModal;
