export const isUniqueShow = (data) => {

    return data.weeklySiteVisitorUniqueCurrent || data.weeklySiteVisitorUniquePrevious ||
        data.monthlySiteVisitorUniquePrevious || data.monthlySiteVisitorUniqueCurrent
}
export const isSessionsShow = (data) => {
    return data.weeklySiteVisitorSessionsCurrent || data.weeklySiteVisitorSessionsPrevious ||
        data.monthlySiteVisitorSessionsCurrent || data.monthlySiteVisitorSessionsPrevious
}
export const isPageVisitShow = (data) => {
    return data.weeklySitePageVisitsCurrent || data.weeklySitePageVisitsPrevious ||
        data.monthlySitePageVisitsCurrent || data.monthlySitePageVisitsPrevious
}
export const isSiteVisitorTabShow = (data) => {
    return isUniqueShow(data) || isSessionsShow(data) || isPageVisitShow(data)
}
export const isImpressionShow = (data) => {
    return data.weeklyImpressionsCurrent || data.weeklyImpressionsPrevious ||
        data.monthlyImpressionsCurrent || data.monthlyImpressionsPrevious
}
export const isClickShow = (data) => {
    return data.weeklyClicksCurrent || data.weeklyClicksPrevious ||
        data.monthlyClicksCurrent || data.monthlyClicksPrevious
}
export const isLeadShow = (data) => {
    return data.weeklyLeadsCurrent || data.weeklyLeadsPrevious ||
        data.monthlyLeadsCurrent || data.monthlyLeadsPrevious
}
export const isDigitalTabShow = (data) => {
    return isImpressionShow(data) || isClickShow(data) || isLeadShow(data)
}
export const isTopLocationTabShow = (data) => {
    let locations = data.filter(v => v.count > 0);
    return locations.length ? true : false
}

