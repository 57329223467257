import React from "react";

const DigitalStatsTd = ({ data }: { data: any }) => {
  return (
    <td className="text-center">
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Current Week: &nbsp;&nbsp; Impressions({data.weeklyImpressionsCurrent}
          )
        </small>
      </div>
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Current Month: &nbsp; Impressions({data.monthlyImpressionsCurrent})
        </small>
      </div>
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Current Week: &nbsp;&nbsp; Clicks({data.weeklyClicksCurrent})
        </small>
      </div>
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Current Month: &nbsp; Clicks({data.monthlyClicksCurrent})
        </small>
      </div>
    </td>
  );
};

export default DigitalStatsTd;
