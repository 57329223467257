import axios from "./axios-configured";
import { $USERS_FETCH_LIMIT } from "../configs/limitConfig";

interface UserPayload {
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
  tag: string;
  type: string | null;
  company_id: string;
}

/* @suleman please change the fuction name */
export const addUserGetToken = async (payload: UserPayload) => {
  return axios.post("/create/user", payload);
};

/* @suleman please remove `or` in the function name  */
export const compelteProfileOrUpdate = async (payload: any) => {
  const data = {
    email: payload.email,
    Username: payload.Username,
    password: payload.password,
  };
  return axios.put(`/user/${payload.id}/update`, data);
};

export const getTokenResponse = async (payload: any) => {
  return axios.get(`/get/user/profile?token=${payload.token}`);
};

export const retriveCompleteProfileURL = async (id: any) => {
  return axios.get(`/retrieve/completeProfile/url/${id}`);
};

export const fatchAllUserListOfCompany = async (payload: any) => {
  return axios.get(
    `/company/users/list?currentPage=${1}&limit=${$USERS_FETCH_LIMIT}&text=${
      payload.text
    }&company=${payload.companyId}`
  );
};

export const userDelete = async (userId: any) => {
  return axios.delete(`/user/${userId}`);
};

export const sendUserEmail = async ({ userId, email }: any) => {
  return axios.put(`/user/send-email/${userId}`, { email });
};

export const userUpdateProfile = async (payload: any) => {
  const data = {
    Username: payload.userName,
    email: payload.email,
    password: payload.newPassword,
  };
  return axios.put(`/user/${payload.userId}/update`, data);
};

export const userPasswordReset = async (payload: any) => {
  const data = {
    oldPassword: payload.oldPassword,
    newPassword: payload.newPassword,
  };
  return axios.post(`/update/password/${payload.userId}`, data);
};

// this api is not in use
export const changeUserPermission = async (payload: any) => {
  let data = { scope: payload.scope, type: payload.type };
  return axios.put(`/edit/${payload.userId}/permissions`, data);
};

export const setUpUser = async (payload: any) => {
  return axios.post(`/setup/user`, payload);
};

export const editUser = async (payload: any) => {
  return axios.put(`/edit/permission`, payload);
};

export const forgotPassword = async (payload: any) => {
  return axios.post(`/user/forget/password`, payload);
};

export const newPassword = async (payload: any) => {
  return axios.post("/set/new/password", payload);
};

export const getUserById = async (userId: any) => {
  return axios.get(`/users/${userId}`);
};
