import { Input, Select as AntSelect } from "antd";
import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import { Card, CardBody, Col, Form, FormGroup, Label } from "reactstrap";
import CreateProfileUrl from "../../commons/CreateProfileURL";
import { CustomInput } from "reactstrap";
import Select from "react-select";
const { Option } = AntSelect;
class NewUserForm extends Component<any, any> {
  timeout: any = 0;
  state: any = {};

  render() {
    const {
      email,
      password,
      confirmPassword,
      name,
      bwAccountId,
      errorMsg,
      companies,
      profileURl,
      sendLinkInEmail,
      editUser,
      handleChange,
      accounts,
      showSelection,
      handleSelect,
      advertisers,
      advertiserIds,
      isAdmin,
    } = this.props;
    return (
      <Col md="6">
        <Card className="mt-4">
          <CardBody className=" mb-5">
            <div>
              <h4 className="card-title">New User's Credentials</h4>
              <p className="text-muted">All the accounts settings go here</p>
            </div>
            <Form className="w-75 mt-3" autoComplete="off">
              {editUser && !Object.keys(editUser).length ? (
                <FormGroup>
                  <>
                    <Label for="name" className="text-muted">
                      Company Name
                    </Label>
                    <CreatableSelect
                      isClearable
                      options={(() => {
                        return companies.map((company: any) => {
                          return { value: company, label: company.name };
                        });
                      })()}
                      onChange={(event: any) => {
                        if (event) {
                          if (event.value.id) {
                            this.props.handleCompanySelect(event.value);
                          } else {
                            this.props.handleCompanySelect({
                              name: event.value,
                            });
                          }
                        } else {
                          this.props.handleCompanySelect(null);
                        }
                      }}
                    />
                  </>
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="emailAddress" className="text-muted">
                    Company Name
                  </Label>
                  <Input readOnly value={editUser.company.name} />
                </FormGroup>
              )}

              <FormGroup>
                <Label for="emailAddress" className="text-muted">
                  Email Address
                </Label>
                <Input
                  type="email"
                  onChange={handleChange}
                  name="email"
                  id="emailAddress"
                  value={email}
                  placeholder="Enter the email address of the person you are inviting..."
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup>
                <Label for="name" className="text-muted">
                  Name
                </Label>
                <Input
                  value={name}
                  id="name"
                  type="text"
                  onChange={handleChange}
                  name="name"
                  placeholder="Enter the name of the person you are inviting..."
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword" className="text-muted">
                  Password
                </Label>
                <Input
                  value={password}
                  onChange={handleChange}
                  type="password"
                  name="password"
                  id="examplePassword"
                  placeholder="Enter Password here"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleConPassword" className="text-muted">
                  Confirm Password
                </Label>
                <Input
                  value={confirmPassword}
                  type="password"
                  name="confirmPassword"
                  id="exampleConPassword"
                  onChange={handleChange}
                  placeholder="Enter Confirm Password here"
                />
              </FormGroup>
              {showSelection && isAdmin && !Object.keys(editUser)?.length && (
                <FormGroup>
                  <Label for="emailAddress" className="text-muted">
                    DSP Account ID
                  </Label>
                  <AntSelect
                    placeholder="Select account id"
                    style={{ width: "100%" }}
                    onChange={(value) => handleSelect(value, "accountId")}
                  >
                    {accounts.length
                      ? accounts.map((account: any) => (
                          <Option value={account.id}>{account.name}</Option>
                        ))
                      : null}
                  </AntSelect>
                </FormGroup>
              )}
              {showSelection && !isAdmin && !Object.keys(editUser)?.length && (
                <FormGroup>
                  <Label for="emailAddress" className="text-muted">
                    DSP Advertiser ID
                  </Label>
                  <Select
                    isMulti={true}
                    onChange={(value: any) => {
                      handleSelect(value, "advertiserIds");
                    }}
                    options={advertisers}
                    value={(() => {
                      let options: any = [];
                      if (advertiserIds && advertiserIds.length) {
                        advertisers.forEach((item: any) => {
                          advertiserIds.forEach((x: any) => {
                            if (item.value === x.value) {
                              options.push(item);
                            }
                          });
                        });
                        return options;
                      }
                    })()}
                  />
                </FormGroup>
              )}
              {/* <FormGroup>
                <Label for="bwAccountId" className="text-muted">
                  {constants.beeswaxKeywordCapital} Account Id
                </Label>
                <Input
                  value={bwAccountId}
                  id="bwAccountId"
                  type="text"
                  onChange={handleChange}
                  name="bwAccountId"
                  placeholder={`Enter your ${constants.beeswaxKeywordSmall} account id!`}
                  autoComplete="off"
                />
              </FormGroup> */}
              <FormGroup>
                <div>
                  {editUser && !Object.keys(editUser).length ? (
                    <CustomInput
                      type="checkbox"
                      id="exampleCustomCheckbox"
                      onChange={handleChange}
                      name="sendLinkInEmail"
                      label="Send email invite"
                      checked={sendLinkInEmail}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {!this.props.errorMsg && (
                  <CreateProfileUrl profileURl={profileURl} />
                )}
                {errorMsg && (
                  <div className="alert alert-danger mt-2" role="alert">
                    {errorMsg}
                  </div>
                )}
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default NewUserForm;
