import React, { Component } from "react";
import { message, Radio } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* Custom Components | functions */
import CustomRange from "./CustomRange";
import FiltersHeader from "./FiltersHeader";
import ApplyBtn from "./ApplyBtn";
import CustomLabel from "./CustomLabel";
import staticDefaultValues from "./FilterMockData";
import SidebarLoader from "../loaders/SidebarLoader";
import CustomMultiSelect from "./CustomMultiSelect";
import generateFilterQuery from "./filtersQueryGenerator";
import { fetchCampaignFilters } from "../../api/campaignFiles";
import { fetchCampaignStaticValuesForAll } from "../../api/target-account";

/* Styles */
import "./Slidebar.css";

class PublisherSidebar extends Component<any, any, any> {
  state: any = {
    currnetWeekCountRadio: "topKeywordsCurrentMonthCount",
    loading: false,
    defaultValues: { ...staticDefaultValues },
    filtersData: {
      ...staticDefaultValues,
    },
  };

  clearFilters = async () => {
    await this.setState({
      defaultValues: { ...staticDefaultValues },
      filtersData: { ...staticDefaultValues },
    });
    this.fetchLeadAppendFilters();
    this.props.clearFilters();
  };

  fetchLeadAppendFilters = async () => {
    try {
      this.setState({ loading: true });
      let id = this.props.match.params.id;
      let response: any = "";
      if (this.props.showAll) {
        response = await fetchCampaignStaticValuesForAll();
      } else {
        response = await fetchCampaignFilters({
          campaignId: id,
          type: this.props.type,
        });
      }

      //Set default values
      await this.setState({
        loading: false,
        defaultValues: {
          ...staticDefaultValues,
          ...response.data,
        },
      });
    } catch (error) {
      this.setState({ loading: false });
      message.error("Error while filter data!");
    }
  };

  applyFilters = async () => {
    try {
      let query = generateFilterQuery(this.state.filtersData);
      await this.props.applyFilters({ query });
    } catch (error) {
      console.log("error in applying filters");
      message.error("There is an issue with filters please try again.");
      this.setState({ loading: false });
      await this.props.applyFilters({ query: `` });
    }
  };

  hanldeSlider = (key: string, values: any) => {
    let objectToUpdate: any = this.state.filtersData[key];
    objectToUpdate.min = values[0];
    objectToUpdate.max = values[1];
    this.setState({
      filtersData: {
        ...this.state.filtersData,
        [key]: { ...objectToUpdate },
      },
    });
  };

  handleSelectChange = async (key: string, values: any) => {
    await this.setState({
      filtersData: {
        ...this.state.filtersData,
        [key]: [...values],
      },
    });
  };

  componentDidMount() {
    this.fetchLeadAppendFilters();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.showAll && !prevProps.showAll) {
      this.fetchLeadAppendFilters();
    }
    if (!this.props.showAll && prevProps.showAll) {
      this.fetchLeadAppendFilters();
    }
  }

  render() {
    const { defaultValues, filtersData } = this.state;
    return (
      <>
        <FiltersHeader label="Filters" handleClear={this.clearFilters} />
        {this.state.loading ? (
          <SidebarLoader />
        ) : (
          <div className={this.props.type}>
            <div>
              <div className="font-weight-bold mt-4 text-muted">
                <div className="mt-2">
                  <CustomMultiSelect
                    label={"Tier"}
                    name="tier"
                    data={[...defaultValues.tier]}
                    handleChange={this.handleSelectChange}
                  />
                  {this.props.showAll && (
                    <>
                      <CustomMultiSelect
                        label="Campaign Keys"
                        name="campaignKeys"
                        data={[...defaultValues.campaignKeys]}
                        handleChange={this.handleSelectChange}
                      />
                    </>
                  )}
                  <CustomMultiSelect
                    label="Keywords"
                    name="topKeywordsCurrentWeek"
                    data={[...defaultValues.topKeywordsCurrentWeek]}
                    handleChange={this.handleSelectChange}
                  />
                  <CustomLabel label="Keyword Count" />
                  <Radio.Group
                    onChange={async (e: any) => {
                      await this.setState({
                        filtersData: {
                          ...this.state.filtersData,
                          topKeywordsCurrentWeekCount: {
                            min: 0,
                            max: 0,
                          },
                          topKeywordsCurrentMonthCount: {
                            min: 0,
                            max: 0,
                          },
                          topKeywordsTwoMonthCount: {
                            min: 0,
                            max: 0,
                          },
                        },
                        currnetWeekCountRadio: e.target.value,
                      });
                    }}
                    value={this.state.currnetWeekCountRadio}
                  >
                    <Radio value={"topKeywordsCurrentWeekCount"}>
                      <small className="text-muted">7 days</small>
                    </Radio>
                    <Radio value={"topKeywordsCurrentMonthCount"}>
                      <small className="text-muted">30 Days</small>
                    </Radio>
                    <Radio value={"topKeywordsTwoMonthCount"}>
                      <small className="text-muted">60 Days</small>
                    </Radio>
                  </Radio.Group>
                  <CustomRange
                    filtersData={filtersData}
                    label=""
                    name={this.state.currnetWeekCountRadio}
                    defaultValues={defaultValues}
                    handleChange={this.hanldeSlider}
                  />
                  <CustomMultiSelect
                    label="Top Locations"
                    name="surgingLocations"
                    data={[...defaultValues.surgingLocations]}
                    handleChange={this.handleSelectChange}
                  />
                </div>
              </div>
              <ApplyBtn onApply={this.applyFilters} label="Apply" />
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(withRouter(PublisherSidebar));
