import React from "react";
import { Table, Button } from "antd";
import _ from "lodash";

const CustomAntTable = ({
  columns,
  tableData,
  loading,
  filters,
  filterChangeHandler,
  setColumns,
}: any) => {
  const setColumnHandler = ({ sortCol, sortorder }: any) => {
    const columnsToUpdate: any = columns.map((col: any) => {
      if (col.key === sortCol)
        return { ...col, sortOrder: !sortorder ? false : sortorder };
      else {
        return { ...col, sortOrder: null };
      }
    });
    setColumns(columnsToUpdate);
  };
  return (
    <div>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          pageSize: filters.recordsperpage,
          position: undefined,
          style: { display: "none" },
        }}
        loading={loading}
        onChange={(pagination, filters, sorter: any) => {
          setColumnHandler({
            sortCol: sorter["columnKey"],
            sortorder: sorter.order,
          });
          filterChangeHandler({
            sortcol: !sorter.order ? null : sorter["columnKey"],
            sortorder: !sorter.order
              ? null
              : sorter.order === "descend"
              ? "D"
              : "A",
          });
        }}
        style={{ overflowX: "auto" }}
      />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          disabled={filters.pagenumber === 1}
          onClick={() => {
            filterChangeHandler({
              pagenumber: filters.pagenumber - 1,
            });
          }}
        >
          Previous
        </Button>
        <Button
          disabled={
            !tableData.length || tableData.length < filters.recordsperpage
          }
          onClick={() => {
            filterChangeHandler({
              pagenumber: filters.pagenumber + 1,
            });
          }}
          style={{ marginLeft: "8px" }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default CustomAntTable;
