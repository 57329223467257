import React, { Component } from "react";
import { Alert } from "reactstrap";

export class CompaniesAlert extends Component<any, any> {
  render() {
    return (
      <div>
        <Alert className="mt-3" color="warning" isOpen={this.props.visible}>
          The total number of companies in the system db is very large. You may
          experience degraded performance or only a subset of companies will be
          shown in the dropdown selection. Please contact Contanuity to fix this
          issue as soon as possible, or delete some unused companies.
        </Alert>
      </div>
    );
  }
}
