import { SET_LEAD_APPEND_ID } from "./types";

let initialState = {
  leadAppendId: null,
};

const campaigns = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LEAD_APPEND_ID:
      return {
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default campaigns;
