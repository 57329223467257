import compact from "../../utility/compact";

const generateFilterQuery = (filtersData: any) => {
  // GenerateFilterDat
  let query: any = compact({
    rootWhere: compact({
      domain:filtersData.domains,
      topKeywordsCurrentWeekCount: compact({
        $gte: filtersData.topKeywordsCurrentWeekCount.min,
        $lte: filtersData.topKeywordsCurrentWeekCount.max,
      }),
      topKeywordsCurrentMonthCount: compact({
        $gte: filtersData.topKeywordsCurrentMonthCount.min,
        $lte: filtersData.topKeywordsCurrentMonthCount.max,
      }),
      monthlySiteVisitorUniqueCurrent: compact({
        $gte: filtersData.monthlySiteVisitorUniqueCurrent.min,
        $lte: filtersData.monthlySiteVisitorUniqueCurrent.max,
      }),
      biMonthlySiteVisitorUnique: compact({
        $gte: filtersData.biMonthlySiteVisitorUnique.min,
        $lte: filtersData.biMonthlySiteVisitorUnique.max,
      }),
      topKeywordsTwoMonthCount: compact({
        $gte: filtersData.topKeywordsTwoMonthCount.min,
        $lte: filtersData.topKeywordsTwoMonthCount.max,
      }),
      weeklySiteVisitorUniqueCurrent: compact({
        $gte: filtersData.weeklySiteVisitorUniqueCurrent.min,
        $lte: filtersData.weeklySiteVisitorUniqueCurrent.max,
      }),
      totalSurgingTopics: compact({
        $gte: filtersData.totalSurgingTopics.min,
        $lte: filtersData.totalSurgingTopics.max,
      }),
      unifiedScore: compact({
        $gte: filtersData.unifiedScore.min,
        $lte: filtersData.unifiedScore.max,
      }),
      rank: compact({
        $gte: filtersData.rank.min,
        $lte: filtersData.rank.max,
      }),
      fractionScoreNumerator: compact({
        $gte: filtersData.fractionScoreNumerator.min,
        $lte: filtersData.fractionScoreNumerator.max,
      }),
      bimonthlyClicksCurrent: compact({
        $gte: filtersData.bimonthlyClicksCurrent.min,
        $lte: filtersData.bimonthlyClicksCurrent.max,
      }),
      monthlyClicksCurrent: compact({
        $gte: filtersData.monthlyClicksCurrent.min,
        $lte: filtersData.monthlyClicksCurrent.max,
      }),
      weeklyClicksCurrent: compact({
        $gte: filtersData.weeklyClicksCurrent.min,
        $lte: filtersData.weeklyClicksCurrent.max,
      }),
      bimonthlyImpressionsCurrent: compact({
        $gte: filtersData.bimonthlyImpressionsCurrent.min,
        $lte: filtersData.bimonthlyImpressionsCurrent.max,
      }),
      monthlyImpressionsCurrent: compact({
        $gte: filtersData.monthlyImpressionsCurrent.min,
        $lte: filtersData.monthlyImpressionsCurrent.max,
      }),
      weeklyImpressionsCurrent: compact({
        $gte: filtersData.weeklyImpressionsCurrent.min,
        $lte: filtersData.weeklyImpressionsCurrent.max,
      }),
      bimonthlyLeadsCurrent: compact({
        $gte: filtersData.bimonthlyLeadsCurrent.min,
        $lte: filtersData.bimonthlyLeadsCurrent.max,
      }),
      monthlyLeadsCurrent: compact({
        $gte: filtersData.monthlyLeadsCurrent.min,
        $lte: filtersData.monthlyLeadsCurrent.max,
      }),
      weeklyLeadsCurrent: compact({
        $gte: filtersData.weeklyLeadsCurrent.min,
        $lte: filtersData.weeklyLeadsCurrent.max,
      }),
      l1TrendingTopicsCountCurrentWeek: compact({
        $gte: filtersData.l1TrendingTopicsCountCurrentWeek.min,
        $lte: filtersData.l1TrendingTopicsCountCurrentWeek.max,
      }),
      tier: filtersData.tier,
    }),
    LeadAppendDetailL1TrendingTopics: compact({
      l1TrendingTopic: filtersData.l1TrendingTopics,
    }),
    campaignKeys: filtersData.campaignKeys,
    LeadAppendDetailBomboraTopics: compact({
      bomboraTopic: filtersData.bomboraTopics,
    }),
    LeadAppendDetailKeywords: compact({
      keyword: [...filtersData.topKeywordsCurrentWeek],
      type: filtersData.topKeywordsCurrentWeek && filtersData.topKeywordsCurrentWeek.length ? "week" : undefined,
    }),
    LeadAppendDetailTopics: compact({
      topic: [...filtersData.surgingTopicListCurrentWeek],
    }),
    LeadAppendDetailLocations: compact({
      loc: [...filtersData.surgingLocations],
    }),
    LeadAppendDetailDelta: compact({
         percentageActiveSource:filtersData.percentageActiveSource ? {$gte:1} :undefined,
        percentageAggregateScore:filtersData.percentageAggregateScore ? {$gte:1} :undefined,
}),
  });
  return query;
};

export default generateFilterQuery;
