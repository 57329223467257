import React from "react";
import { Tooltip, Popover } from "antd";
import { TAConstants } from "../targetAccountConstants";

const remainingItmes = (data: any) => (
  <ul
    style={{
      overflowY: "auto",
      maxHeight: "150px",
      listStyleType: "none",
      padding: 0,
    }}
  >
    {data &&
      data.map((item: any, index: number) => {
        return (
          <li className="text-capitalize" key={`remaining_${index}`}>
            {item.topic} ({item.score})
          </li>
        );
      })}
  </ul>
);

const SurginTopicsTd = ({ data }: { data: any }) => {
  data && data.sort((a: any, b: any) => {
    return b.score - a.score
  })
  return (
    <td>
      {data &&
        data.slice(0, TAConstants.MAX_TD_ROWS).map((topic: any, index: any) => {
          return (
            <Tooltip
              key={`topic_${index}`}
              title={<span className="text-capitalize">{topic.topic} ({topic.score})</span>}
            >
              <div className="d-flex">
                <small
                  className="d-inline-block text-capitalize text-truncate text-muted"
                  style={{ maxWidth: "120px" }}
                >
                  {topic.topic} ({topic.score})
                </small>
              </div>
            </Tooltip>
          );
        })}
      {data && data.length > TAConstants.MAX_TD_ROWS && (
        <Popover content={remainingItmes(data)}>
          <small
            className="d-inline-block text-truncate"
            style={{ maxWidth: "120px", color: "#007bff", cursor: "cursor" }}
          >
            View All {data.length} 
          </small>
        </Popover>
      )}
    </td>
  );
};

export default SurginTopicsTd;
