import axios from 'axios';
const axiosConfigured = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});
const client =  axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

export const setAuthHeader = (token: string | any) => {
    axiosConfigured.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

axiosConfigured.interceptors.response.use(
    response => {
        return response;
    }, error => {
        if (error.response.data.statusCode === 401 && error.response.data.message === "Expired token") {
            window.localStorage.removeItem('token');
            window.location.href = ('/login?session=expired')
        }
        return Promise.reject(error);
    });

setAuthHeader(window.localStorage.getItem('token'));
const onSuccess = function (response:any) {
    console.debug("Request Successful!", response);
    return response.data;
  };
  
  const onError = function (error:any) {
    console.error("Request Failed:", error.config);
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    }
     else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }
  
    return Promise.reject({
      errMsg: error?.response?.data?.message || error.message,
      status: error?.response?.status,
    });
  };
client.interceptors.response.use(onSuccess, onError);
client.interceptors.request.use(
  (config:any) => {
    config.headers["Authorization"] =
      "Bearer " + window.localStorage.getItem("token");
    return config;
  },
  (error:any) => {
    Promise.reject(error);
  }
);

export { client };
export default axiosConfigured;
