import React from "react";
import { Button } from "antd";

const ApplyBtn = ({ onApply, label }: { onApply: Function; label: string }) => {
  return (
    <Button
      className="w-100 px-4 mt-3 text-decoration-underline border-0"
      color="transparent"
      onClick={() => onApply()}
    >
      <small>
        <u>{label}</u>
      </small>
    </Button>
  );
};

export default ApplyBtn;
