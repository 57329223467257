import React from "react";
import classes from "./styles/TopDomains.module.css";
import { Typography } from "antd";

const TopDomains = () => {
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <Typography className={classes.title}>Top Performers</Typography>
      </div>
    </div>
  );
};

export default TopDomains;
