import React, { Component } from "react";
import Select from "react-select";
import {
  Modal,
  Label,
  FormGroup,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "reactstrap";
import { Select as AntSelect } from "antd";
import { getAdvertisersList } from "../../api/beeswax";
const { Option } = AntSelect;
interface Props {
  modal: boolean;
  companyName: string | any;
  dspAdvertiserId?: Number | string | any;
  toggle: Function | any;
  handleComapnyNameChange: Function | any;
  handleSubmit: Function | any;
  handleFileIds: Function | any;
  companyFileList: Array<any>;
  advertisers: Array<any>;
  advertiserIds: Array<any>;
  handleSelect: Function | any;
  isAdmin: boolean;
  accounts: Array<any>;
  accountId: string | number;
}

const EditModal = ({
  toggle,
  companyName,
  modal,
  handleComapnyNameChange,
  handleSubmit,
  advertisers,
  advertiserIds,
  handleSelect,
  isAdmin,
  accounts,
  accountId,
}: Props) => {
  console.log({ advertiserIds });
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="shadow-none">
        Edit Company Name
      </ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            value={companyName}
            onChange={handleComapnyNameChange}
            name="companyName"
          />
        </FormGroup>
        {isAdmin ? (
          <FormGroup>
            <Label for="emailAddress" className="text-muted">
              DSP Account ID
            </Label>
            <AntSelect
              placeholder="Select account id"
              style={{ width: "100%" }}
              onChange={(value) => handleSelect(value, "accountId")}
              value={accountId ?? ""}
            >
              {accounts.length
                ? accounts.map((account: any) => (
                    <Option value={account.id}>{account.name}</Option>
                  ))
                : null}
            </AntSelect>
          </FormGroup>
        ) : (
          <FormGroup>
            <Label for="emailAddress" className="text-muted">
              DSP Advertiser ID
            </Label>
            <Select
              isMulti={true}
              onChange={(value: any) =>
                handleSelect(value, "selectedAdvertiserIds")
              }
              options={advertisers}
              value={(() => {
                let options: any = [];
                if (advertiserIds && advertiserIds.length) {
                  advertisers.forEach((item: any) => {
                    advertiserIds.forEach((x: any) => {
                      if (item.value === x.value) {
                        options.push(item);
                      }
                    });
                  });
                  return options;
                }
              })()}
            />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary rounded"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(advertiserIds);
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditModal;
