import React from 'react';
import arrowUp from "../../assest/images/up-arrow.svg";
import arrowDownRed from "../../assest/images/down-arrow.svg";
import {MinusOutlined } from "@ant-design/icons"
function toFixed(num:any, fixed:any) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}
const Expression = ({
  value,
  type,
  percentAgeValue ,
  oldValue,
  showValue=true,
  showPercentage = true
}: {
  value?: any;
  type?: string;
  percentAgeValue?: string;
  oldValue?: any,
  showValue?:boolean ,
  showPercentage?:boolean
}
) => {
  if (!type) {

    if (typeof value !== 'undefined' && typeof oldValue !== 'undefined') {
      value = parseInt(value);
      oldValue = parseInt(oldValue);
      type = value >= oldValue ? "up" : "down";

    }
    else {
      value = parseInt(value);
      type = value >= 0 ? "up" : "down";
    }
    value = Math.abs(value);

  }
  if (!percentAgeValue) {
    
    oldValue = parseInt(oldValue);
    let absValue = 0;
    if (oldValue) {
       absValue = Math.abs(100 * (value - oldValue) / oldValue);
    }
    else {
       absValue = Math.abs(Math.floor(100 * (value - oldValue)));
    }
    
    absValue = isNaN(absValue)  ? 0 : absValue;
    percentAgeValue = `${toFixed(absValue,1)}%`;
  
  }

  let arrowUp = (<svg style={{ fontSize: "1.4rem", marginLeft: -4, 
  marginRight: -4, color: "green" }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em" className="bi bi-arrow-up-short">
    <path fill-rule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5z"></path>
    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8 5.707 5.354 8.354a.5.5 0 1 1-.708-.708l3-3z"></path>
  </svg>);
  let arrowDown = (<svg style={{ fontSize: "1.4rem", marginLeft: -4, marginRight: -4, color: "red" }} className="bi bi-arrow-down-short" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"></path>
    <path fill-rule="evenodd" d="M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z"></path>
  </svg>);
  let line = (<svg style={{ fontSize: "1.4rem", marginLeft: -4, marginRight: -4, color: "red" }} className="bi bi-arrow-down-short" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <line  x1="40" x2="250" y1="20" y2="20" /></svg>)
  
 
  return (
    <>
      { value } (

      
      {
      
      type === "up" ? arrowUp : arrowDown}

      {showPercentage ? (percentAgeValue  ? percentAgeValue : "0%") : ''})
      
    </>
  );
};

export default Expression;
