import React, { Component } from "react";
import { Table } from "reactstrap";
class LeadSiftSubTable extends Component<any, any> {
  render() {
    return (
      <div>

          <div>
              <Table className="table-sm table-striped table-hover table-borderless">
                  {/* <thead>
                      <tr>
                          <th className="font-weight-bold border-bottom-0"></th>
                      </tr>
                  </thead> */}
                  <tbody>
                      {this.props.laading ? (
                          <>
                              <tr>
                                  <td>Loading...</td>
                              </tr>
                          </>
                      ) : (
                              <>
                                  {this.props.data &&this.props.data.leadsifttriggerkeyword &&
                                      this.props.data.leadsifttriggerkeyword.split('|').map((item: any, index: number) => {
                                          return (
                                              <tr key={`bombora_sub_table_${index}`}>
                                                  <td className="text-capitalize">{item.trim()}</td>
                                              </tr>
                                          );
                                      })}
                              </>
                          )}
                  </tbody>
              </Table>
          </div>

      </div>
  );
  }
}
export default LeadSiftSubTable;