import React from "react";
import { Tabs, Select, message, Tooltip } from "antd";
import TopicsSubTable from "./TopicsSubTable";
import KeywordsSubTable from "./KeywordsSubTable";
import { withRouter } from "react-router";
import { fetchLeadAppendForDomainGeo } from "../../api/campaignFiles";
import SimpleTableLoader from "../../commons/loaders/SimpleTableLoader";
import L1SubTable from "./L1SubTable";
import SiteVisitorTable from "./SiteVisitor";
import AberdeenSubTable from './AberdeenSubTable'
import TechTargetSubTable from './TechTargetSubTable'
import BomboraSubTable from './BomboraSubTable'
import LeadSiftSubTable from "./LeadSiftSubTable";
import DigitalSubTable from './DigitalSubTable'
import { isDigitalTabShow  } from '../../utility/campaign-view'
import { Table } from "reactstrap";
class TopLocationSubTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      mode: "left",
      waiting: false,
      locationDetail: {},
      leadAppendDetailTopicJson: [],
      leadAppendDetailKeywordJson: [],
      leadAppendDetailAberdeenKeywords: [],
      leadAppendDetailBomboraTopics: [],
      leadAppendDetailTechTargetKeywords:[],
      l1SubTableData: {
        LeadAppendDetailL1TrendingTopics: [],
        l1TrendingTopicsCountPreviousWeek: null,
        l1TrendingTopicsCountCurrentWeek: null

      },
      locations: [],
      selectedLocation: null,
      dataObj : {}
    };
  }
  componentDidMount = async () => {
    let locations = this.props.data ? this.props.data
      .filter((v: { loc: string, count: number }) => v.count > 0)
      .sort((a: any, b: any) => {
        return b.count - a.count
      })
      : [];
    if (locations.length) {
      await this.setState({ locations, selectedLocation: locations[0].loc })
      await this.fetchLeadAppendGeoDetails(this.state.selectedLocation);

    }
  }

  fetchLeadAppendGeoDetails = async (selectedLocation: any) => {
    try {
      await this.setState({ waiting: true, selectedLocation });
      let payload = {
        campaignid: this.props.campaignKey,
        domain: this.props.domain,
        locality: selectedLocation,
      };
      let response: any = await fetchLeadAppendForDomainGeo(payload);
      let leadAppendDetailTopicJson = JSON.parse(
        response.data.leadAppendDetailTopicJson
      );
    
      let leadAppendDetailKeywordJson = JSON.parse(
        response.data.leadAppendDetailKeywordJson
      );
      let LeadAppendDetailL1TrendingTopics = JSON.parse(
        response.data.leadAppendDetailL1TrendingTopicJson
      );
        
      let leadAppendDetailAberdeenKeywords    = JSON.parse(
        response.data.leadAppendDetailAberdeenJson|| '[]'
      );
       let leadAppendDetailBomboraTopics    = JSON.parse(
        response.data.leadAppendDetailBomboraTopicJson || '[]'
      );
       let leadAppendDetailTechTargetKeywords    = JSON.parse(
        response.data.leadAppendDetailTechTargetJson|| '[]'
      );
     

      await this.setState({
        leadAppendDetailTopicJson,
        leadAppendDetailKeywordJson,
        leadAppendDetailAberdeenKeywords,
        leadAppendDetailBomboraTopics,
        leadAppendDetailTechTargetKeywords,
        l1SubTableData: {
          LeadAppendDetailL1TrendingTopics,
          l1TrendingTopicsCountPreviousWeek:
            response.data.l1TrendingTopicsCountPreviousWeek,
          l1TrendingTopicsCountCurrentWeek:
            response.data.l1TrendingTopicsCountCurrentWeek,
        },
        locationDetail: response.data,
        dataObj:response.data,
        waiting: false,
      });


    } catch (error) {

      await this.setState({ waiting: false });
      console.log(error)
      message.error("Error while fetch leadAppendGeoDetails");
    }
  };
  render() {
    const { mode } = this.state;
    let filteredData = this.state.locations
    return (
      <div>
        <Select
          className="text-capitalize"
          showSearch
          style={{ width: 220, marginBottom: 8 }}
          placeholder="Select a location"
          optionFilterProp="children"
          value={
            this.state.selectedLocation
          }
          onChange={this.fetchLeadAppendGeoDetails}
        >
          {filteredData &&
            filteredData.length &&
            filteredData.map((item: any, index: number) => (
              <Select.Option key={`location_item_${index}`} value={item.loc} className="text-capitalize">
                <Tooltip
                  key={index}
                  title={<span className="text-capitalize">{item.loc} ({item.count})</span>}
                >
                  {item.loc} ({item.count})
            </Tooltip>

              </Select.Option>
            ))}
        </Select>{" "}
        <Tabs
          tabBarStyle={{ height: "100%" }}
          defaultActiveKey="0"
          className="my-2"
          tabPosition={mode}
          style={{ height: "100%", border: "1px solid #f5f5f5" }}
        >
          <Tabs.TabPane tab={"Score"} key={"0"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (

              <Table className="table-sm table-striped table-hover table-borderless">
              <thead>
                <tr>
                  <th className="font-weight-bold border-bottom-0">Active Sources</th>
                  <th className="font-weight-bold border-bottom-0">
                  Aggregate Score
                  </th>
                  
                </tr>
              </thead>
             <tbody>
            
               <tr>
                 <td>
                  <Tooltip
                  key={`score_1`}
                  title={this.state.dataObj.fractionScore || 0}
                >
                 {this.state.dataObj.fractionScore?.split('/')[0] || 0}
                 </Tooltip>

                 </td>
                 <td>
                   {this.state.dataObj.unifiedScore || 0 }
                   
                   </td>
                 
               </tr>
             </tbody>
             </Table>

              )}
          </Tabs.TabPane>

          <Tabs.TabPane tab={"Topics"} key={"1"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
                <TopicsSubTable data={this.state.leadAppendDetailTopicJson} />
              )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Keywords"} key={"2"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
                <KeywordsSubTable data={this.state.leadAppendDetailKeywordJson} />
              )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={"HVC (L1 Topics)"} key={"3"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
                <L1SubTable
                  data={{
                    ...this.state.l1SubTableData,
                  }}
                // data={this.props.data}
                />
              )}
          </Tabs.TabPane>

          <Tabs.TabPane tab={"Site Visitor"} key={"4"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
                <SiteVisitorTable data={this.state.locationDetail} />

              )}
          </Tabs.TabPane>


          {this.state.leadAppendDetailAberdeenKeywords.length ?
              <Tabs.TabPane tab={"Aberdeen"} key={"5"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
                 <AberdeenSubTable data={{
                                          leadAppendDetailAberdeenKeywords:this.state.leadAppendDetailAberdeenKeywords

                                        }} />

              )}
          </Tabs.TabPane>
    : ''}
          {this.state.dataObj && this.state.dataObj.leadsifttriggerkeyword ?
            <Tabs.TabPane tab={"leadsift"} key={"6"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
                <LeadSiftSubTable data={this.state.dataObj} />

              )}
            </Tabs.TabPane>
            : ''}
         
          {this.state.leadAppendDetailTechTargetKeywords.length ?
            <Tabs.TabPane tab={"Tech Target"} key={"7"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
               <TechTargetSubTable data={{
                                          leadAppendDetailTechTargetKeywords: this.state.leadAppendDetailTechTargetKeywords

                                        }} />

              )}
            </Tabs.TabPane>
            : ''}
         
          {this.state.leadAppendDetailBomboraTopics.length ?
            <Tabs.TabPane tab={"Bombora"} key={"8"}>
            {this.state.waiting ? (
              <SimpleTableLoader />
            ) : (
               <BomboraSubTable data={{
                                              leadAppendDetailBomboraTopics:this.state.leadAppendDetailBomboraTopics

                                            }} />

              )}
            </Tabs.TabPane>
            : ''}

            {
                                        isDigitalTabShow(this.state.dataObj) ? 
                                         <Tabs.TabPane tab={"Digital"} key={"9"}>
                                        <DigitalSubTable data={this.state.dataObj} />
                                      </Tabs.TabPane>
                                        : ''
                                      }
         
        </Tabs>
      </div>
    );
  }
}
export default withRouter(TopLocationSubTable);
