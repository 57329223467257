import React from "react";
import { toast } from "react-toastify";
import {
  Form,
  FormGroup,
  Label,
  // Input,
  Card,
  CardBody,
  Button,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { Input, Checkbox } from "antd";
import RenderToast from "../../commons/RenderToast";
import { CustomInput } from "reactstrap";
import CreateProfileUrl from "../../commons/CreateProfileURL";
import { subAdminValidations } from "../../utility/validations";
import { createSubAdmin } from "../../api/sub-admin";

const SubAdmin = () => {
  const [profileUrl, setProfileUrl] = React.useState<any>("");
  const [data, setData] = React.useState<any>({
    email: "",
    password: "",
    confirmPassword: "",
    sendLinkInEmail: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  const handleChange = (event: any) => {
    if (event.target.type === "checkbox") {
      setData({
        ...data,
        [event.target.name]: event.target.checked,
      });
    } else {
      if (errMsg) setErrMsg("");
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const fieldValidations = subAdminValidations(data);
      if (fieldValidations.status) {
        if (errMsg) setErrMsg("");
        setLoading(true);
        const res = await createSubAdmin({
          email: data.email,
          password: data.password,
        });
        debugger;
        setProfileUrl(res?.data?.completeProfileUser);
        toast.success("Associate Admin created successfully");
        setLoading(false);
        setData({
          email: "",
          password: "",
          confirmPassword: "",
          sendLinkInEmail: false,
        });
      } else {
        setErrMsg(fieldValidations.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <RenderToast />
      <Row>
        <Col md="6">
          <Card className="mt-4">
            <CardBody className=" mb-5">
              <div>
                <h4 className="card-title">Associate Admin's Credentials</h4>
              </div>
              <Form
                className="w-75 mt-3"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <FormGroup>
                  <Label for="emailAddress" className="text-muted">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    onChange={handleChange}
                    name="email"
                    id="emailAddress"
                    value={data.email}
                    placeholder="Enter email address of sub-admin..."
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword" className="text-muted">
                    Password
                  </Label>
                  <Input
                    value={data.password}
                    onChange={handleChange}
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder="Enter Password here"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleConPassword" className="text-muted">
                    Confirm Password
                  </Label>
                  <Input
                    value={data.confirmPassword}
                    type="password"
                    name="confirmPassword"
                    id="exampleConPassword"
                    onChange={handleChange}
                    placeholder="Enter Confirm Password here"
                  />
                </FormGroup>
                <FormGroup>
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="exampleCustomCheckbox"
                      onChange={handleChange}
                      name="sendLinkInEmail"
                      label="Send email invite"
                      checked={data.sendLinkInEmail}
                    />
                  </div>
                  {!errMsg && profileUrl ? (
                    <CreateProfileUrl profileURl={profileUrl} />
                  ) : null}
                  {errMsg ? (
                    <div className="alert alert-danger mt-2" role="alert">
                      {errMsg}
                    </div>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    size="sm"
                    className="px-5 rounded mt-3"
                    type="submit"
                  >
                    {loading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SubAdmin;
