import qs from "query-string";

const constants: any = {
  beeswaxKeywordSmall: "beeswax",
  beeswaxKeywordCapital: "Beeswax",
};

const formatUrl = (url: any, queryParam: any) => {
  if (Object.keys(queryParam).length !== 0) {
    queryParam = qs.stringify(queryParam);
    return `${url}?${queryParam}`;
  }
  return url;
};

const getQueryParams = (query = "") => {
  const params = query?.split("?")[1];
  const paramsArr = params?.split("&");
  let paramsObj = {};
  paramsArr?.length &&
    paramsArr.map((param, index) => {
      const arr = param.split("=");
      paramsObj = { ...paramsObj, [arr[0]]: arr[1] };
    });
  return paramsObj;
};

export { constants, formatUrl, getQueryParams };
