import React from "react";
import { Modal } from "antd";
import { Table } from "antd";
import { getInfluenceMetricsForCustomIdDomain } from "../../../api/abm-stats";
import moment from "moment";

const antColumns = [
  {
    title: "Page Url",
    dataIndex: "pageurl",
    key: "pageurl",
  },
  {
    title: "Page Visits",
    dataIndex: "pagevisits",
    key: "pagevisits",
  },
  {
    title: "Influenced Page Visits",
    dataIndex: "influencedpagevisits",
    key: "influencedpagevisits",
  },
  {
    title: "Unique Users",
    dataIndex: "uniqueusers",
    key: "uniqueusers",
  },
  {
    title: "Influenced Unique Users",
    dataIndex: "influenceduniqueusers",
    key: "influenceduniqueusers",
  },
];

const InfluencedMetricsModal = ({
  isModalVisible,
  handleCancel,
  selectedDomain,
  filters,
  campaigns,
}: any) => {
  const [tableData, setTableData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      let startDate = "";
      let endDate = "";
      if (filters.range) {
        const range = filters.range.split(",");
        startDate = range[0];
        endDate = range[1];
      } else {
        startDate = moment()
          .subtract(filters.dayspan, "day")
          .format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }
      const campaign = campaigns?.find(
        (item: any) => item?.CampaignKey?.key === filters.campaignid
      );
      const { SiteVisitors = [] } = campaign;
      if (!SiteVisitors.length) {
        setLoading(false);
        return;
      }
      const customid = SiteVisitors[0]?.siteVisitorId;
      const filterObj = {
        domain: selectedDomain,
        customid,
        startdateyyyymmdd: startDate,
        enddateyyyymmdd: endDate,
      };
      const res: any = await getInfluenceMetricsForCustomIdDomain(filterObj);
      let data = res?.data?.map((item: any) => {
        return {
          pageurl: item.pageurl,
          pagevisits: item.pagevisits,
          influencedpagevisits: item.influencedpagevisits,
          uniqueusers: item.uniqueusers,
          influenceduniqueusers: item.influenceduniqueusers,
        };
      });
      setTableData(data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal
      centered={true}
      title="Influenced Metrics"
      visible={isModalVisible}
      onOk={handleCancel}
      onCancel={handleCancel}
      maskClosable={false}
      width={1024}
    >
      <Table
        columns={antColumns}
        dataSource={tableData}
        loading={loading}
        pagination={{ pageSize: 5 }}
      />
    </Modal>
  );
};

export default InfluencedMetricsModal;
