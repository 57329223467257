import React from "react";
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Form,
  FormGroup,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import { doAdminLogin } from "../../redux/user/actions";
import { setAuthHeader } from "../../api/axios-configured";

class AdminLogin extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      errorMessage: "",
      error: "",
    };
  }

  /**
   *
   *
   * @private
   * @memberof Login
   */
  private handleEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ email: event.target.value });
  private handlePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ password: event.target.value });

  /**
   *
   *
   * @memberof Login
   **/

  componentDidMount = () => {
    if (this.props.isLoggedIn) {
      this.props.updateLoginStatus();
    }
  };

  handleSubmit = async (event: React.SyntheticEvent) => {
    const { email, password } = this.state;
    event.preventDefault();
    if (email && password) {
      try {
        this.setState({ loading: true, errorMessage: "" });
        this.props.dispatch(
          doAdminLogin(
            {
              email,
              password,
            },
            (res: any) => {
              setAuthHeader(res.data.token);
              this.setState({ loading: false });
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(res.data.user));
            },
            () => {
              this.setState({
                loading: false,
                errorMessage: "Invalid login details",
              });
            }
          )
        );
      } catch (error) {
        alert("Error in login");
        if (error.response && error.response.data.message) {
          this.setState({ loading: false, error: error.response.data.message });
        } else {
          this.setState({
            error: "Something went terribly wrong!",
            loading: false,
          });
        }
      }
    } else {
      this.setState({
        errorMessage: "Fields should be filled",
        loading: false,
      });
    }
  };

  render(): JSX.Element {
    return (
      <Container
        className="bgImgLoginAdmin"
        style={{ minHeight: "100vh" }}
        fluid
      >
        <Row>
          <Col>
            <div
              className="justify-content-center d-flex align-items-center "
              style={{ minHeight: "100vh" }}
            >
              <Card className="card-signin w-25  login-card">
                <CardBody>
                  <h5 className="card-title  text-center mb-5 loginTitleColor">
                    Admin Sign In
                  </h5>
                  <Form className="form-signin">
                    <div className="form-label-group">
                      <input
                        type="email"
                        className="form-control
                                                 rounded-0
                                                shadow-none bg-transparent 
                                                border-bottom border-top-0 
                                                border-left-0 border-right-0 
                                                "
                        placeholder="Email Address"
                        onChange={this.handleEmail}
                      />
                    </div>

                    <div className="form-label-group mt-3">
                      <input
                        type="password"
                        className="form-control 
                                                    shadow-none 
                                                    bg-transparent border-bottom 
                                                    border-top-0 border-left-0 
                                                    border-right-0 rounded-0"
                        placeholder="Password"
                        onChange={this.handlePassword}
                      ></input>
                      <div className="text-danger font-weight-bold mt-2">
                        {this.state.errorMessage}
                        {this.state.error}
                      </div>
                    </div>
                    <FormGroup check inline className="mt-3"></FormGroup>
                    <div className="justify-content-center d-flex align-item-center">
                      {this.state.loading ? (
                        <button
                          className="loginBtn"
                          type="submit"
                          disabled
                        >
                          <Spinner size="sm" color="light" />
                        </button>
                      ) : (
                        <button
                          className="loginBtn"
                          type="submit"
                          onClick={this.handleSubmit}
                        >
                          Sign In
                        </button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminLogin);
