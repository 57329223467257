import { SET_USER_FOR_EDIT, CLEAR_USER } from "./types";
let initialState = {};

const editUser = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_FOR_EDIT:
      return {
        ...action.payload,
      };
    case CLEAR_USER:
      return {};
    default:
      return {
        ...state,
      };
  }
};

export default editUser;
