import React from "react";
import "./styles/SecondaryStatsItem.css";
import { Typography, Spin } from "antd";
import { useHistory } from "react-router";

const SecondaryStatsItem = ({
  title,
  amount,
  height = "120px",
  loading,
  type,
}: any) => {
  const history = useHistory();
  return (
    <div
      className="container cp"
      style={{ height }}
      onClick={() => history.push(`/abm-details?type=${type}`)}
    >
      <Typography className="title">{title}</Typography>
      <Typography className="amount">
        {/* {showCurrency && currency} */}
        {!loading ? amount : <Spin />}
      </Typography>
      {/* {showSubSection && (
        <div className="subItem">
          <Typography>
            {showSubCurrency && currency}
            {subAmount}
          </Typography>
          <Typography>{subTitle}</Typography>
        </div>
      )} */}
    </div>
  );
};

export default SecondaryStatsItem;
