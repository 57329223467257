import React from "react";
import { MDBDataTable } from "mdbreact";
import { Button } from "reactstrap";
import RenderToast from "../../commons/RenderToast";
import {
  getAllCompanyFiles,
  deleteCompanyFile,
} from "../../api/target-account";
import TableLoader from "../../commons/loaders/TableLoader";
import { UploadOutlined } from "@ant-design/icons";

import deleteIcon from "../../assest/images/delete.svg";
import SweetAlert from "react-bootstrap-sweetalert";
// import {  } from "react-toastify";
import { message } from "antd";

import CsvImportModal from "./CsvImportModal";
import { connect } from "react-redux";

interface State {
  files: Array<any>;
  fileIdForDownload: Number;
  loading: Boolean;
  fileKey: String | any;
  fileType: String;
  data: Object;
  csvModal: Boolean;
  show: boolean;
  deleteFileId: number;
}

const columns = [
  {
    label: "File Name",
    field: "fileName",
    sort: "asc",
  },
  {
    label: "Total Records",
    field: "TotalRecords",
    sort: "asc",
  },
  {
    label: "Processed Records",
    field: "totalProcessedRecords",
    sort: "asc",
  },
  {
    label: "Created Date",
    field: "CreatedDate",
    sort: "asc",
  },
  {
    label: "Type",
    field: "fileType",
    sort: "disabled",
  },
  {
    label: "Action",
    field: "action",
    sort: "disabled",
  },
];

class TargetAccountFiles extends React.Component<any, State> {
  state: State = {
    files: [],
    fileIdForDownload: NaN,
    loading: false,
    fileKey: "",
    fileType: "",
    data: { columns },
    csvModal: false,
    show: false,
    deleteFileId: NaN,
  };

  csvToggle = () => this.setState({ csvModal: !this.state.csvModal });
  downloadCsv = () => {};

  onViewRecords = (id: any, type: any) => {
    this.props.history.push(`/audience-manger/${id}/view-${type}`);
  };

  /* Prepare date object for the datatable */
  createTableData = () => {
    let { files } = this.state;
    let rows = files.map((file: any) => ({
      id: file.id,
      fileName: file.fileName,
      TotalRecords: file.totalRecords?.toLocaleString(),
      totalProcessedRecords: file.totalProcessedRecords?.toLocaleString(),
      fileType: (
        <label style={{ textTransform: "capitalize" }}>{file.fileType}</label>
      ),
      CreatedDate: new Date(file.createdAt).toLocaleDateString(),
      action: (
        <>
          <Button
            size="sm"
            color={!file.hasError ? "success" : "danger"}
            onClick={() => {
              this.onViewRecords(file.id, file.fileType);
            }}
            disabled={!file.isCompleted || file.hasError ? true : false}
          >
            View
          </Button>
          {this.props.auth.Role.$permissions.targetAccounts &&
            this.props.auth.Role.$permissions.targetAccounts.global ===
              "CRUD" && (
              <Button
                color="transparent"
                className="border-0 shadow-none p-2"
                onClick={() => {
                  this.setState({
                    show: true,
                    deleteFileId: file.id,
                  });
                }}
              >
                <img src={deleteIcon} width="22px" className="mb-1" alt="" />
              </Button>
            )}
        </>
      ),
    }));
    this.setState({ data: { columns, rows } });
  };

  getCompanyFileList = async () => {
    try {
      this.setState({ loading: true });
      let response = await getAllCompanyFiles("all");
      this.setState({ files: response.data.rows, loading: false });
    } catch (error) {
      console.log(error);
      message.error("Oops,something went wrong!");
      this.setState({ loading: false });
    }
  };

  deleteFile = async (id: number) => {
    try {
      await deleteCompanyFile(id);
      await this.getCompanyFileList();
      this.createTableData();
      this.setState({ show: false });
      message.info("successfully deleted!");
    } catch (error) {}
  };

  componentDidMount = async () => {
    try {
      await this.getCompanyFileList();
      this.createTableData();
    } catch (error) {}
  };

  render(): JSX.Element {
    const { data }: { data: any } = this.state;
    // const {
    //   auth: {
    //     Role: { $permissions }
    //   }
    // } = this.props;

    return (
      <>
        <RenderToast />
        {this.props.auth.Role.$permissions.targetAccounts &&
          this.props.auth.Role.$permissions.targetAccounts.global ===
            "CRUD" && (
            <>
              <button
                className="btn btn-success btn-sm float-right"
                onClick={this.csvToggle}
              >
                <UploadOutlined />
                IMPORT CSV
              </button>
            </>
          )}
        {this.state.loading ? (
          <TableLoader columns={columns} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            responsive
            noBottomColumns
            data={data}
            small={true}
            className="mt-3"
          />
        )}
        {this.state.csvModal ? (
          <CsvImportModal
            csvModal={this.state.csvModal}
            csvToggle={this.csvToggle}
            downloadCsv={this.downloadCsv}
            getCompanyFileList={this.getCompanyFileList}
            createTableData={this.createTableData}
          />
        ) : null}
        <SweetAlert
          warning
          customButtons={
            <>
              <Button
                color="info"
                size="md"
                onClick={() => {
                  this.setState({ show: false });
                }}
              >
                Dismiss
              </Button>
              <Button
                color="danger"
                size="md"
                onClick={() => {
                  this.deleteFile(this.state.deleteFileId);
                }}
              >
                Delete
              </Button>
            </>
          }
          show={this.state.show}
          title="Are you sure?"
          onConfirm={() => {
            this.deleteFile(this.state.deleteFileId);
          }}
          onCancel={() => {
            this.setState({ show: false });
          }}
          focusCancelBtn
        >
          You will not be able to recover this record!
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(TargetAccountFiles);
