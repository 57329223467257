import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyUrlIcon from "../assest/images/copy.svg";
import { Tooltip } from "reactstrap";

class CreateProfileURL extends React.Component<any, any> {
  state = {
    value: "",
    copied: false,
    tooltipOpen: false,
    bgTextColor: ""
  };
  toggle = () =>
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
      bgTextColor: "lightBlue"
    });

  render() {
    const { profileURl, reteiveLoading: loading } = this.props;
    return (
      <div className="input-group">
        {loading ? (
          <span>Loading...</span>
        ) : (
          <>
            {profileURl ? (
              <>
                <input
                  value={profileURl}
                  type="text"
                  className="form-control shadow-none mt-2"
                  style={{ backgroundColor: this.state.bgTextColor }}
                  readOnly
                />

                <CopyToClipboard
                  text={profileURl}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <div
                    className="input-group-append bg-white"
                    style={{ marginTop: "0.5rem", height: "2.4rem" }}
                  >
                    <span
                      className="input-group-text"
                      style={{ cursor: "pointer" }}
                      id="DisabledAutoHideExample"
                    >
                      <Tooltip
                        placement="right"
                        isOpen={this.state.copied}
                        autohide={true}
                        target="DisabledAutoHideExample"
                        toggle={this.toggle}
                        style={{ zIndex: 100 }}
                      >
                        Copied!
                      </Tooltip>
                      <img src={CopyUrlIcon} width="15px" alt="" />
                    </span>
                  </div>
                </CopyToClipboard>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    );
  }
}

export default CreateProfileURL;
