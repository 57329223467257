import React from "react";
import { Button } from "reactstrap";
import editIcon from "../../assest/images/edit.svg";
import deleteIcon from "../../assest/images/delete.svg";
import { Link } from "react-router-dom";

const RenderAction = ({
  user,
  permission,
  handleUserProfileLink,
  handleUserDelete,
  handleLoginAs,
  handleEditUser,
  handleSendEmail,
}: {
  user: any;
  permission: String;
  handleUserProfileLink: Function | any;
  handleUserDelete: Function | any;
  handleLoginAs: Function | any;
  handleEditUser?: Function | any;
  handleSendEmail?: Function | any;
}) => {
  return (
    <>
      {permission !== "R" && (
        <>
          <Button
            color="primary"
            disabled={user.onBoarding ? false : true}
            onClick={async () => {
              await handleLoginAs(user.id);
            }}
            className="mx-1"
            size="sm"
          >
            Login as
          </Button>

          <Button
            color="primary"
            size="sm"
            disabled={user.onBoarding ? true : false}
            onClick={async () => await handleSendEmail(user)}
          >
            Send Email
          </Button>

          <Button
            color="primary"
            size="sm"
            disabled={user.onBoarding ? true : false}
            onClick={async () => await handleUserProfileLink(user)}
          >
            {user.onBoarding ? "Updated" : "Get Link"}
          </Button>
        </>
      )}
      {permission !== "R" && (
        <Link to="/setup/create/user">
          <Button
            color="bg-transparent"
            onClick={() => {
              handleEditUser(user);
            }}
            className="border-0 shadow-none p-0 mx-1"
            size="sm"
          >
            <img src={editIcon} width="22" alt="" />
          </Button>
        </Link>
      )}
      {permission === "CRUD" && (
        <Button
          color="bg-transparent"
          className="border-0 shadow-none p-0"
          onClick={() => handleUserDelete(user)}
        >
          <img src={deleteIcon} width="22" alt="" />
        </Button>
      )}
    </>
  );
};

export default RenderAction;
