import axios from "./axios-configured";

export const createAudience = async (payload: any) => {
  return axios.post("/audience", payload);
};

export const createGeneralRetargetAudience = async (payload: any) => {
  return axios.post("/audience/general-retarget-audience", payload);
};
export const updateGeneralRetargetAudience = async (
  id: number,
  payload: any
) => {
  return axios.put(`/audience/general-retarget-audience/${id}`, payload);
};
export const runGeneralRetargetAudience = async (id: any) => {
  return axios.patch(`/audience/general-retarget-audience/${id}`);
};
export const getGeneralRetargetAudienceById = async (id: number) => {
  return axios.post(`/audience/general-retarget-audience/${id}`);
};

export const createEmailAudience = async (payload: any) => {
  return axios.post("/audience/email-audience", payload);
};

export const editAudience = async ({ id, payload }: any) => {
  return axios.patch(`/audience/${id}`, payload);
};

export const modifyAudienceDbData = async (
  id: any,
  payload: any = { segment_status: "pending" }
) => {
  return axios.patch(`/audience/modify/${id}`, payload);
};

export const refreshAudienceData = async (id: any) => {
  return axios.patch(`/audience/${id}/refresh`);
};

export const editEmailAudience = async (
  id: any,
  payload: any = { status: "pending" }
) => {
  return axios.patch(`/audience/email-audience/${id}`, payload);
};

export const getInProcessRecordsStatus = async (ids: any) => {
  return axios.post("/audience/in-process-status", { ids });
};

export const getAudienceList = async () => {
  return axios.get("/audience");
};

export const getGeneralRetargetAudienceList = async () => {
  return axios.get("/audience/general-retarget-audience");
};

export const getEmailAudienceList = async () => {
  return axios.get("/audience/email-audience");
};
