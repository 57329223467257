import { call, put, takeEvery } from "redux-saga/effects";
import * as Api from "../../api/company";
import { FETCH_COMPANIES, FETCH_COMPANIES_SUCCESS } from "./types";

function* fetchCompanies(action: any) {
  try {
    const response: any = yield call(Api.getCompanies, action.payload);
    yield put({ type: FETCH_COMPANIES_SUCCESS, payload: { ...response } });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess, response);
    }
  } catch (e) {
    if (action.meta.onFailure) {
      yield call(action.meta.onFailure, e);
    }
  }
}

export default [takeEvery(FETCH_COMPANIES, fetchCompanies)];
