import React from "react";
import ContentLoader from "react-content-loader";

const Line = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={21}
    viewBox="0 0 365 21"
    primaryColor="#d9d9d9"
    secondaryColor="#ecebeb"
  >
    <rect x="20" y="5" rx="0" ry="0" width="335" height="10" />
  </ContentLoader>
);

export default Line;
