import axios from "./axios-configured";

export const getTopDomainByClicks = async (payload: any) => {
  return axios.post("/abm-stats/getTopDomainByClicks", payload);
};
export const getTopDomainByImpression = async (payload: any) => {
  return axios.post("/abm-stats/getTopDomainByImpression", payload);
};
export const getTopDomainByUniqVisitor = async (payload: any) => {
  return axios.post("/abm-stats/getTopDomainByUniqVisitor", payload);
};
export const getTopDomainByUniqVisitorWithImpression = async (payload: any) => {
  return axios.post(
    "/abm-stats/getTopDomainByUniqVisitorWithImpression",
    payload
  );
};
export const getCampaignStats = async (payload: any) => {
  return axios.post("/abm-stats/getCampaignStats", payload);
};
export const getAdStats = async (payload: any) => {
  return axios.post("/abm-stats/getAdStats", payload);
};

export const getSiteVisitorMetric = async (payload: any) => {
  return axios.post("/abm-stats/getSiteVisitorMetric", payload);
};
export const getAdMetric = async (payload: any) => {
  return axios.post("/abm-stats/getAdMetric", payload);
};

export const getCombinedMetrics = async (payload: any) => {
  return axios.post("/abm-stats/getCombinedMetrics", payload);
};

export const getHybridDomainsByPageViews = async (payload: any) => {
  return axios.post("/abm-stats/getHybridDomainsByPageViews", payload);
};

export const getHybridDomainsByEngagedVisitors = async (payload: any) => {
  return axios.post("/abm-stats/getHybridDomainsByEngagedVisitors", payload);
};

export const getHybridDomainsBySiteVisitor = async (payload: any) => {
  return axios.post("/abm-stats/getHybridDomainsBySiteVisitor", payload);
};

export const scheduleReport = async ({ filters, campaignId }: any) => {
  return axios.get(
    `/abm-stats/schedule-report/${campaignId}?$where=${JSON.stringify(filters)}`
  );
};

export const getReports = async () => {
  return axios.get("/abm-stats/reports");
};

export const getDownloadLinkUrl = async ({ id }: any) => {
  return axios.get(`/abm-stats/reports/url/${id}`);
};

export const getInfluencedMetrics = async (payload: any) => {
  return axios.post("/abm-stats/getInfluencedMetrics", payload);
};
export const getInfluenceMetricsForCustomIdDomain = async (payload: any) => {
  return axios.post("/abm-stats/getInfluenceMetricsForCustomIdDomain", payload);
};
