/*  USER AUTH TYPES */
export const SET_USER_AUTH: string = "SET_USER_AUTH";
export const LOGIN_SUCCESS: string = "LOGIN_SUCCESS";
export const LOGIN_FAILURE: string = "LOGIN_FAILURE";
export const DO_USER_LOGIN: string = "DO_USER_LOGIN";
export const DO_ADMIN_LOGIN: string = "DO_ADMIN_LOGIN";
export const LOGOUT_USER: string = "LOGOUT_USER";
export const SET_PREVIOUSE_LOGIN_STATE: string = "SET_PREVIOUSE_LOGIN_STATE";
export const LOGIN_BACK_TO_PREVIOUS: string = "LOGIN_BACK_TO_PREVIOUS";

/* LOADING TYPES  */
export const ACTIVE_LOADING: string = "ACTIVE_LOADING";
export const INACTIVE_LOADING: string = "INACTIVE_LOADING";
