import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const TabItem = ({
  title,
  active,
  href,
  role,
  permissions,
}: {
  title: string;
  active: boolean;
  href: string;
  role: string;
  permissions: any;
}) => {
  // ${href === "/campaigns" && !permissions.campaignFiles && "d-none"}

  return (
    <Link
      to={href}
      className={`btn py-3 text-primary ${
        href === "/target-account-files" && role === "admin" && "d-none"
      } 
       ${
         href === "/account-discovery" &&
         !permissions.accountDiscoveries &&
         "d-none"
       } decuration-none m-0 bg-transparent rounded-0 shadow-none target ${
        active ? "active-tab" : ""
      }`}
    >
      {title}
    </Link>
  );
};

const mapStateToProps = (state: any) => ({
  role: state.auth.Role.name,
  permissions: state.auth.Role.$permissions,
});

export default connect(mapStateToProps)(TabItem);
