import React, { Component } from "react";
import { compelteProfileOrUpdate } from "../../api/user";
import { getTokenResponse } from "../../api/user";
import errorIcon from "../../assest/images/404-error.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { validateInputFields } from "../../utility/validations";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardTitle,
  Spinner,
} from "reactstrap";
import { setAuthHeader } from "../../api/axios-configured";
import { updateSubAdmin } from "../../api/sub-admin";

class CompleteProfile extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      Username: "",
      id: "",
      password: "",
      confirmPassword: "",
      loading: false,
      errorPage: false,
      errorMsg: "",
      btnLoader: false,
      isSubAdmin: null,
    };
  }

  $completeProfile = async () => {
    try {
      const { email, Username, password, id, confirmPassword } = this.state;
      const validFields = validateInputFields(
        email,
        password,
        confirmPassword,
        Username,
        this.state.isSubAdmin
      );
      if (validFields.status) {
        this.setState({ btnLoader: true });
        let res = !this.state.isSubAdmin
          ? await compelteProfileOrUpdate({
              Username,
              email,
              password,
              id,
            })
          : await updateSubAdmin({
              email,
              password,
              id,
            });
        debugger;
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        if (this.state.isSubAdmin) localStorage.setItem("Role", "admin");
        setAuthHeader(res.data.token);
        this.props.dispatch({
          type: "LOGIN_SUCCESS",
          payload: { ...res.data },
        });
        await this.props.history.push("/");
        this.setState({ btnLoader: false });
      } else {
        this.setState({ errorMsg: validFields.message });
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          btnLoader: false,
          errorMsg: error.response.data.message,
        });
      } else {
        this.setState({ loading: false, btnLoader: false });
      }
    }
  };

  componentDidMount = async () => {
    try {
      debugger;
      this.setState({ loading: true });
      const params = new URLSearchParams(this.props.location.search);
      var token = params.get("token");
      let tokenRes = await getTokenResponse({ token });
      console.log({ tokenRes });
      const { email, Username, id, scope } = tokenRes.data;
      await this.setState({
        email,
        Username,
        id,
        loading: false,
        isSubAdmin: scope === "admin" ? true : false,
      });
    } catch (error) {
      this.setState({ errorPage: true });
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          errorMsg: error.response.data.message,
        });
      } else {
        this.setState({ loading: false });
        // toast.error("Something went terribly wrong!")
      }
    }
  };
  handleChange = (event: any) =>
    this.setState({ [event.target.name]: event.target.value });

  render() {
    const { email, loading, errorPage, Username, errorMsg, btnLoader } =
      this.state;
    return (
      <>
        {!errorPage ? (
          <div>
            {loading ? (
              <div
                className="w-100 justify-content-center d-flex align-items-center bg-dark"
                style={{ minHeight: "100vh" }}
              >
                <div
                  className="spinner-border text-primary"
                  style={{ width: "5rem", height: "5rem" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="bg-light justify-content-center align-items-center d-flex"
                style={{ minHeight: "100vh" }}
              >
                <Card className="w-50">
                  <CardBody>
                    <CardTitle>
                      <h2> Complete Your Profile </h2>
                    </CardTitle>
                    <Form className="mt-3" autoComplete="off">
                      <FormGroup>
                        <Label for="emailAddress" className="text-muted">
                          Email Address
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          value={email ? email : ""}
                          id="emailAddress"
                          placeholder="Enter the Email Address"
                          // autoComplete="off"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      {!this.state.isSubAdmin && (
                        <FormGroup>
                          <Label for="name" className="text-muted">
                            Name
                          </Label>
                          <Input
                            id="name"
                            type="text"
                            value={Username ? Username : ""}
                            name="Username"
                            placeholder="Enter the Name "
                            autoComplete="off"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      )}
                      <FormGroup>
                        <Label for="examplePassword" className="text-muted">
                          Password
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="examplePassword"
                          placeholder="*********"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleConPassword" className="text-muted">
                          Confirm Password
                        </Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="exampleConPassword"
                          placeholder="**********"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      {errorMsg && (
                        <div className="alert alert-danger" role="alert">
                          {errorMsg}
                        </div>
                      )}
                    </Form>

                    <div className="w-100 d-flex justify-content-end">
                      {btnLoader ? (
                        <button className="btn btn-primary rounded-pill px-5">
                          <Spinner color="light" size="sm" />
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary rounded-pill px-5 btn-sm"
                          onClick={this.$completeProfile}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        ) : (
          <div
            className="w-100 justify-content-center d-flex align-items-center bg-dark"
            style={{ minHeight: "100vh" }}
          >
            <div>
              <img src={errorIcon} alt="" />
              <h5 className="text-danger mt-4">
                THIS PAGE CURRENTY NOT AVAILABLE
              </h5>
              <p className="text-white text-center">
                Click here for login <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CompleteProfile);
