import React from "react";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { message } from "antd";

import GetLinkModal from "./GetLinkModal";
import RenderToast from "../../commons/RenderToast";
import SweetAlert from "react-bootstrap-sweetalert";
import CreatableSelect from "react-select";
import RenderAction from "./RenderActionCol";
import columns from "./ColumnList";
import { loginAs } from "../../api/login-api";
import {
  retriveCompleteProfileURL,
  userDelete,
  fatchAllUserListOfCompany,
  sendUserEmail,
} from "../../api/user";
import { withRouter } from "react-router";
import { Button, Row } from "reactstrap";
import TableLoader from "../../commons/loaders/TableLoader";

interface Props {
  auth: any;
  tabs: any;
  userList: Array<any>;
  companies: Array<any>;
  dispatch: Function | any;
  history: any;
}

interface State {
  data: any;
  currentPage?: number;
  pageLimit?: number;
  companyId?: any;
  modal: Boolean;
  profileURL: String;
  reteiveLoading: Boolean;
  loading: Boolean;
  show: boolean;
  deleteUserId: any;
  usersList: Array<any>;
}

class Users extends React.Component<any, State> {
  state: State = {
    currentPage: NaN,
    pageLimit: NaN,
    companyId: "",
    data: { columns },
    modal: false,
    reteiveLoading: false,
    profileURL: "",
    loading: false,
    show: false,
    deleteUserId: "",
    usersList: [],
  };

  componentDidMount = async () => {
    this.fatchUserList();
  };

  handleUserProfileLink = async (user: any) => {
    await this.getCompleteProfileURL(user.id);
    this.toggle();
  };

  handleUserDelete = (user: any) =>
    this.setState({
      show: true,
      deleteUserId: user.id,
    });

  toggle = () => this.setState({ modal: !this.state.modal });

  /* get user update profile link */
  getCompleteProfileURL = async (id: any) => {
    try {
      this.setState({ reteiveLoading: true });
      var resURL = await retriveCompleteProfileURL(id);
      await this.setState({
        profileURL: resURL.data.CompletePrfileUser,
        reteiveLoading: false,
      });
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({ reteiveLoading: false });
        toast.error(error.response.data.message);
      } else {
        this.setState({ reteiveLoading: false });
        toast.error("Something went terribly wronk!");
      }
    }
  };

  /* delete user function */
  deteleUser = async (userId: any) => {
    try {
      let deleteRef = await userDelete(userId);
      if (deleteRef.data.message && deleteRef.data.deleted) {
        toast.success(deleteRef.data.message);
      }
      this.setState({ show: false });
      this.fatchUserList();
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
        });
        toast.error(error.response.data.message);
      } else {
        this.setState({ loading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };

  /* delete user function */
  sendEmail = async (user: any) => {
    try {
      let res = await sendUserEmail({ userId: user.id, email: user.email });
      toast.success(res.data.message);
      this.fatchUserList();
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
        });
        toast.error(error.response.data.message);
      } else {
        this.setState({ loading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };

  setUserAuth = async (response: any) => {
    this.props.dispatch({
      type: "SET_USER_AUTH",
      payload: { ...response.data },
    });
    this.props.dispatch({
      type: "SET_USERS",
      payload: [],
    });
    this.props.dispatch({
      type: "SET_COMPANIES",
      payload: [],
    });
    // changeTabs();
    // this.props.history.push("/target-account");
  };

  setPreviouseLoginState = async (previousLoginState: any) => {
    this.props.dispatch({
      type: "SET_PREVIOUSE_LOGIN_STATE",
      payload: { ...previousLoginState },
    });
  };

  handleEditUser = (user: any) => {
    this.props.dispatch({
      type: "SET_USER_FOR_EDIT",
      payload: { ...user },
    });
  };

  handleLoginAs = async (userId: any) => {
    try {
      let response = await loginAs({ userId });
      if (!this.props.auth.previousLoginState) {
        let previousLoginState = this.props.auth;
        localStorage.setItem(
          "previousLoginState",
          JSON.stringify(previousLoginState)
        );
        this.setPreviouseLoginState(previousLoginState);
      }
      await this.setUserAuth(response);
      message.success("Account switched!");
      this.props.history.push("/");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  /* Prepare date object for the datatable */
  createTableData = () => {
    this.setState({ loading: true });
    let { usersList } = this.state;
    let rows = usersList.map((user: any) => ({
      id: user.id,
      email: user.email,
      Username: user.Username,
      companyName: user.company.name,
      createdAt: new Date(user.createdAt).toLocaleString(),
      action: (
        <RenderAction
          user={user}
          handleUserProfileLink={this.handleUserProfileLink}
          handleUserDelete={this.handleUserDelete}
          permission={
            this.props.auth.Role.$permissions.subAccounts.global || "R"
          }
          handleEditUser={this.handleEditUser}
          handleLoginAs={this.handleLoginAs}
          handleSendEmail={this.sendEmail}
        />
      ),
    }));
    this.setState({ data: { columns, rows }, loading: false });
  };

  handleCompanySelect = async (value: any) => {
    if (value) {
      await this.setState({ companyId: value.id });
    } else {
      await this.setState({ companyId: "" });
    }
    this.fatchUserList();
  };

  // fatch user with specific comapny
  fatchUserList = async () => {
    try {
      this.setState({ loading: true });
      let userlist = await fatchAllUserListOfCompany({
        text: "",
        companyId: this.state.companyId,
      });
      this.setState({ usersList: userlist.data.rows });
      this.createTableData();
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
        });
        toast.error(error.response.data.message);
      } else {
        this.setState({ loading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };

  render(): JSX.Element {
    const { data }: { data: any } = this.state;
    return (
      <>
        <RenderToast />
        <Row className="d-flex justify-content-center w-100  position-absolute">
          {this.props.auth.Role.name === "admin" && (
            <CreatableSelect
              className="w-25 mr-3 text-dark text-8-rem zIndex-100"
              isClearable
              placeholder="Search by company"
              options={(() => {
                if (this.props.companies) {
                  return this.props.companies.map((company: any) => {
                    return { value: company, label: company.name };
                  });
                }
              })()}
              onChange={(event: any) => {
                if (event) {
                  if (event.value.id) {
                    this.handleCompanySelect(event.value);
                  } else {
                    this.handleCompanySelect({ event: event.value });
                  }
                } else {
                  this.handleCompanySelect("");
                }
              }}
            />
          )}
        </Row>
        {this.state.loading ? (
          <TableLoader columns={columns} length={10} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            noBottomColumns
            responsive
            data={data}
            small={true}
            className="mt-3"
          />
        )}
        <GetLinkModal
          profileURL={this.state.profileURL}
          reteiveLoading={this.state.reteiveLoading}
          modal={this.state.modal}
          toggle={this.toggle}
        />

        <SweetAlert
          warning
          customButtons={
            <>
              <Button
                color="info"
                size="md"
                onClick={() => {
                  this.setState({ show: false });
                }}
              >
                Dismiss
              </Button>
              <Button
                color="danger"
                size="md"
                onClick={() => {
                  this.deteleUser(this.state.deleteUserId);
                }}
              >
                Delete
              </Button>
            </>
          }
          show={this.state.show}
          title="Are you sure?"
          onConfirm={() => {
            this.deteleUser(this.state.deleteUserId);
          }}
        >
          You will not be able to recover this record!
        </SweetAlert>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
  userList: state.users.userList,
  companies: state.companies.list,
});

export default connect(mapStateToProps)(withRouter(Users));
