import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, CustomInput } from "reactstrap";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Spin, Empty, message, Pagination, Switch } from "antd";

/* Custom Components | Functions */
import headers from "./PublisherViewHeader";
import TrendingKeywordsTd from "../TargetAccount/TablePartials.tsx/TrendingKeywordsTd";
import TopLocations from "../TargetAccount/TablePartials.tsx/TopLocations";
import AccountTd from "../TargetAccount/TablePartials.tsx/AccountTd";
import PublisherSidebar from "../../commons/Sidebar/PublisherSidebar";
import downloadIcon from "../../assest/images/download.svg";
import { TAHelper } from "../TargetAccount/TargetAccountHelper";
import {
  getCampaignSetupData,
  fetchCampaignStaticValues,
  getCampaignSetupDataForAll,
  fetchCounts,
  fetchCountsForAllPublishers,
} from "../../api/target-account";

/* Styles */
import "./PublisherView.css";
import "../TargetAccount/TargetAccount.css";

class PublisherView extends Component<any, any> {
  state: any = {
    campaignSetupId: null,
    data: [],
    pageCount: 0,
    currentPage: 1,
    loading: false,
    originalData: [],
    key: "",
    updatedAt: "",
    waiting: false,
    query: "",
    count: 100,
    totalCount: 0,
    showAll: false,
    sortQuery: ""
  };
  downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/campaign-setup/${this.props.match.params.id}/download/file?token=${this.props.auth.token}&type=publisher&`
  downloadUrlForAll = `${process.env.REACT_APP_API_BASE_URL}/campaign-setup/download/file/allPublisher?token=${this.props.auth.token}&`


  getCampaignStaticValues = async () => {
    try {
      let id = this.props.match.params.id;
      if (!id) return
      await this.setState({ waiting: true });
      let response: any = await fetchCampaignStaticValues({ id });
      if (response.data) {
        this.setState({
          key: response.data.key,
          keyName: response.data.keyName,
          updatedAt: response.data.CampaignSetups[0].completedAt
        });
      }

      await this.setState({ waiting: false });
    } catch (error) {
      console.log(error)
      this.setState({ waiting: false });
      message.error("Error while loading static values!");
    }
  };

  getCounts = async () => {
    try {
      let id = this.props.match.params.id;
      let { query, currentPage } = this.state;
      if (!query || !Object.keys(query).length) {
        query = `{"rootWhere":{"tier":["Tier 4", "Tier 2", "Tier 1", "Tier 3"]}}`;
      }
      let response: any = "";
      if (this.state.showAll) {
        response = await fetchCountsForAllPublishers(
          query,
          currentPage,
          "publisher"
        );
      } else {
        response = await fetchCounts(id, query, currentPage, "publisher");
      }
      this.setState({ totalCount: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  fetchCampaignData = async () => {
    try {
      this.setState({ loading: true });
      let id = this.props.match.params.id;
      let { query, currentPage } = this.state;
      await this.setState({ campaignSetupId: id });
      let resp: any = "";
      if (this.state.showAll) {
        resp = await getCampaignSetupDataForAll(
          id,
          query,
          currentPage,
          "publisher"
        );
      } else {
        resp = await getCampaignSetupData(id, query, currentPage, "publisher");
      }

      this.setState({
        loading: false,
        originalData: resp.data.rows,
        count: resp.data.count,
      });

    } catch (error) {
      await this.setState({ originalData: [], loading: false });
      // message.error("Error while loading campaign data!");
    }
  };

  UNSAFE_componentWillMount = async () => {
    if (!this.props.match.params.id) {
      await this.setState({ showAll: true });
    }
  };

  componentDidMount = async () => {
    this.getCampaignStaticValues();
    this.fetchCampaignData();
    this.getCounts();
  };
  setQuery = async (query: any, sortable: boolean = false) => {
    if (sortable) {
      await this.setState({
        sortQuery: query,
        query: {
          ...this.state.query,
          ...query,

        }
      });

    }
    else {
      await this.setState({
        query: {
          ...query,
          ...this.state.sortQuery,
        },
      });
    }

    console.log(this.state.query);
  }
  applyFilters = async ({ query }: any) => {
    try {
      await this.setQuery(query)
      await this.setState({ loading: true, currentPage:1 });
      let id = this.props.match.params.id;
      let { currentPage } = this.state;
      let resp: any = "";
      if (this.state.showAll) {
        resp = await getCampaignSetupDataForAll(
          id,
          this.state.query,
          currentPage,
          "publisher"
        );
      } else {
        resp = await getCampaignSetupData(id, this.state.query, currentPage, "publisher");
      }
      this.setState({
        loading: false,
        originalData: resp.data.rows,
        count: resp.data.count,
      });
      this.getCounts();
    } catch (error) {
      await this.setState({
        loading: false,
        originalData: [],
      });
      message.error("Error while loading campaign data!");
    }
  };

  handlePageChange = async (value: any) => {
    await this.setState({ currentPage: value });
    this.fetchCampaignData();
  };

  clearFilters = async () => {
    await this.setState({ query: "" });
    this.fetchCampaignData();
    this.getCounts();
  };

  handleShowAll = (showAll: Boolean) => {
    this.setState({ showAll });
    this.fetchCampaignData();
  };

  render() {
    return (
      <div>
        <>
          <Row style={{ background: "#F4F5F6" }}>
            <Col md="2" lg={"2"} xl="2">
              <PublisherSidebar
                showAll={this.state.showAll}
                applyFilters={this.applyFilters}
                clearFilters={this.clearFilters}
                type="publisher"
              />
            </Col>

            <Col md="10" lg={"10"} xl="10" style={{paddingLeft:"23px"}}>
              <div className="ml-0">
                <div className="d-block d-md-flex justify-content-between ml-0 pt-3 mb-3">
                  <div>
                    <h2 style={{ fontWeight: 600 }} className="primary-dark">
                      Publisher
                      {!this.state.showAll && (
                        <>
                          {" "} - {this.state.waiting ? "Loading..." : this.state.keyName}
                        </>
                      )}
                    </h2>
                    <small style={{ fontWeight: 400, fontSize: "100%" }}>
                      {!this.state.showAll ?
                        <>
                          <b style={{ fontWeight: 500 }}>Last Updated: </b>
                          {this.state.waiting
                            ? "loading..."
                            : new Date(this.state.updatedAt).toLocaleDateString()
                          }
                          {" | "}

                        </>
                        : ""}

                      <b style={{ fontWeight: 500 }}>Count: </b>
                      {this.state.totalCount}
                    </small>
                  </div>
                  <div className="mt-3">

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${this.state.showAll ?
                        this.downloadUrlForAll :
                        this.downloadUrl}$where=${this.state.query ? JSON.stringify(this.state.query) : this.state.query}`}
                      className="btn bg-white btn-sm rounded-pill px-4"
                    >
                      <img
                        src={downloadIcon}
                        width="10px"
                        className="mb-1"
                        alt=""
                      />{" "}
                      DOWNLOAD
                    </a>

                  </div>
                  <Switch
                    checkedChildren="Show Previous"
                    unCheckedChildren="Show All"
                    className="float-right d-none"
                    checked={this.state.showAll}
                    onChange={this.handleShowAll}
                  />
                </div>
                <Table responsive className="bg-white main-tbl">
                  <thead className="border-bottom-0">
                    <tr>
                      <>
                        {headers(this.state.showAll).map(
                          (head: any, index: number) => {
                            return (
                              <th
                                key={`headers_publisher_view_${index}`}
                                className="font-weight-bold border-bottom-0 primary-dark"
                                style={{ fontSize: "0.95rem" }}
                              >
                                <span className="d-inline" onClick={() => { }}>
                                  {head.sortable && (
                                    <>
                                      <span className="caret-up-cont">
                                        <CaretUpOutlined
                                          onClick={async () => {
                                            let sortQuery = await TAHelper.handleSort(
                                              head.name,
                                              "ASC",
                                              this
                                            )

                                            await this.setQuery(sortQuery, true)
                                            await this.fetchCampaignData();
                                          }
                                          }
                                        />
                                      </span>
                                      <br />
                                      <span className="caret-down-cont">
                                        <CaretDownOutlined
                                          onClick={async () => {
                                            let sortQuery = await TAHelper.handleSort(
                                              head.name,
                                              "DESC",
                                              this
                                            )
                                            await this.setQuery(sortQuery, true)
                                            await this.fetchCampaignData();
                                          }
                                          }
                                        />
                                      </span>
                                    </>
                                  )}
                                </span>
                                <span className="d-inline">{head.name}</span>
                              </th>
                            );
                          }
                        )}
                      </>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {this.state.loading ? (
                      <tr>
                        <td colSpan={13}>
                          <div className="justify-content-center align-items-center d-flex">
                            <Spin size="large" />
                          </div>
                        </td>
                      </tr>
                    ) : (
                        this.state.originalData.map((item: any, index: any) => {
                          let leadAppendDetailKeywordJson = JSON.parse(
                            item.leadAppendDetailKeywordJson
                          );
                          let leadAppendDetailLocationJson = JSON.parse(
                            item.leadAppendDetailLocationJson
                          );
                          return (
                            <React.Fragment key={`publisher_data_${index}`}>
                              <tr className="data-row">
                               
                                {this.state.showAll && (
                                  <td>{item.campaignKey}</td>
                                )}
                                {/* Account <td> */}
                                <AccountTd data={item} />
                                {/* Tier <td> */}
                                <td className="text-center text-capitalize  text-muted">
                                  <small>
                                    {item.tier}
                                  </small>
                                </td>

                                {/* Trending Keywords <td> */}
                                <TrendingKeywordsTd
                                  data={leadAppendDetailKeywordJson}
                                />

                                {/* Top Locations */}
                                <TopLocations
                                  data={leadAppendDetailLocationJson}
                                />
                              </tr>
                            </React.Fragment>
                          );
                        })
                      )}
                    {!this.state.originalData.length && !this.state.loading && (
                      <tr>
                        <td colSpan={13}>
                          <div className="justify-content-center align-items-center d-flex">
                            <Empty />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <br />
                <div className="text-center">
                  <Pagination
                    defaultCurrent={this.state.currentPage}
                    total={this.state.count}
                    current={this.state.currentPage}
                    onChange={(page) => {
                      this.handlePageChange(page);
                    }}
                  />
                </div>
                <br />
              </div>
            </Col>
          </Row>
        </>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PublisherView);
