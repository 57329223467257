import React from "react";
import { Typography } from "antd";
import { Spin } from "antd";

const BwMainStats = ({ classes, report, loading, isAdmin }: any) => {
  return (
    <div className={classes.statsContainer}>
      {isAdmin ? (
        <div className={classes.stats}>
          <Typography>Spend</Typography>
          <Typography.Title level={4}>
            {loading ? (
              <Spin />
            ) : report?.Spend ? (
              `$${report?.Spend?.toFixed(2)}`
            ) : (
              "00"
            )}
          </Typography.Title>
        </div>
      ) : null}
      <div className={classes.stats}>
        <Typography>{isAdmin ? "Revenue" : "Spend"}</Typography>
        <Typography.Title level={4}>
          {loading ? (
            <Spin />
          ) : report?.Revenue ? (
            `$${report?.Revenue?.toFixed(2)}`
          ) : (
            "00"
          )}
        </Typography.Title>
      </div>
      <div className={classes.stats}>
        <Typography>Impressions</Typography>
        <Typography.Title level={4}>
          {loading ? <Spin /> : report?.Impressions ?? "00"}
        </Typography.Title>
      </div>
      <div className={classes.stats}>
        <Typography>CTR</Typography>
        <Typography.Title level={4}>
          {loading ? (
            <Spin />
          ) : (
            `${
              report?.Clicks && report?.Impressions
                ? parseFloat(
                    ((report?.Clicks / report?.Impressions) * 100).toFixed(2)
                  )
                : `0`
            }%`
          )}
        </Typography.Title>
      </div>
      {isAdmin ? (
        <div className={classes.stats}>
          <Typography>CPM</Typography>
          <Typography.Title level={4}>
            {loading ? <Spin /> : report?.CPM?.toFixed(3) ?? "00"}
          </Typography.Title>
        </div>
      ) : null}
      <div className={classes.stats}>
        <Typography>Clicks</Typography>
        <Typography.Title level={4}>
          {loading ? <Spin /> : report?.Clicks ?? "00"}
        </Typography.Title>
      </div>
      {isAdmin ? (
        <div className={classes.stats}>
          <Typography>Conversions</Typography>
          <Typography.Title level={4}>
            {loading ? <Spin /> : report?.Conversions ?? "00"}
          </Typography.Title>
        </div>
      ) : null}
    </div>
  );
};

export default BwMainStats;
