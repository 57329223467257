import React, { Component } from "react";
import { connect } from "react-redux";
import { userUpdateProfile, userPasswordReset } from "../../api/user";
import { toast } from "react-toastify";
import {
  userNameOrEmailValidation,
  userPasswrodUpdate,
} from "../../utility/validations";
import { setAuthHeader } from "../../api/axios-configured";
import compact from "../../utility/compact";
import {
  Form,
  FormGroup,
  Label,
  // Input,
  Card,
  CardBody,
  Button,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { Input, Checkbox } from "antd";
import RenderToast from "../../commons/RenderToast";

export class UpdateProfile extends Component<any, any> {
  state = {
    email: this.props.auth.email ? this.props.auth.email : "",
    userName: this.props.auth.username,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    errorMsg: "",
    loading: false,
    passWordBtnLoading: false,
    passErrorMsg: "",
  };

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  compact = (obj: any) => {
    let compactObj = {};
    for (let key in obj) {
      if (obj[key]) {
        Object.assign(compactObj, {
          [key]: obj[key],
        });
      }
    }
    return compactObj;
  };

  updateUserProfile = async () => {
    try {
      const userId = this.props.auth.id;
      const { email, userName } = this.state;
      let userInfo = { email, userName, userId };
      let payload = compact(userInfo);
      var validDataFields = userNameOrEmailValidation(email, userName);
      if (validDataFields.status) {
        this.setState({ loading: true, errorMsg: "" });
        let res = await userUpdateProfile(payload);
        this.setState({ loading: false });
        toast.success("Profile Updated Successfully!");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setAuthHeader(res.data.token);
        this.props.dispatch({
          type: "LOGIN_SUCCESS",
          payload: { ...res.data },
        });
      } else {
        this.setState({ errorMsg: validDataFields.message });
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          errorMsg: error.response.data.message,
        });
      } else {
        this.setState({ loading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };

  updateUserPassword = async () => {
    const userId = this.props.auth.id;
    const { newPassword, oldPassword, confirmPassword } = this.state;
    let payload = { newPassword, oldPassword, userId };
    var passwordValidDataFields = userPasswrodUpdate(
      oldPassword,
      newPassword,
      confirmPassword
    );
    try {
      if (passwordValidDataFields.status) {
        this.setState({ passWordBtnLoading: true, passErrorMsg: "" });
        await userPasswordReset(payload);
        this.setState({
          passWordBtnLoading: false,
          newPassword: "",
          oldPassword: "",
          confirmPassword: "",
        });
        toast.success("Password Successfully Update");
      } else {
        this.setState({ passErrorMsg: passwordValidDataFields.message });
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          passWordBtnLoading: false,
          passErrorMsg: error.response.data.message,
        });
      } else {
        this.setState({ passWordBtnLoading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };
  render() {
    return (
      <div>
        <RenderToast />
        <Row>
          <Col md="6">
            <Card className="mt-5 border-0 shadow h-100">
              <CardBody className="border-0">
                <Form className="w-75">
                  <FormGroup>
                    <Label for="userEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="userEmail"
                      placeholder="Email here..."
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="userName">Name</Label>
                    <Input
                      type="text"
                      name="userName"
                      id="userName"
                      placeholder="Name here..."
                      onChange={this.handleChange}
                      value={this.state.userName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox onChange={() => {}}>
                      Enable email notification for "Campaign" Refreshes.
                    </Checkbox>
                    {/* <Label for="userName">Name</Label>
                    <Input
                      type="text"
                      name="userName"
                      id="userName"
                      placeholder="Name here..."
                      onChange={this.handleChange}
                      value={this.state.userName}
                    /> */}
                  </FormGroup>
                  {this.state.errorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.errorMsg}
                    </div>
                  )}
                </Form>
                <div className="w-75">
                  <Button
                    color="primary"
                    size="sm"
                    className="px-5 rounded mt-3"
                    onClick={this.updateUserProfile}
                    disabled={this.state.loading ? true : false}
                  >
                    {this.state.loading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="mt-5 border-0 shadow  h-100 ">
              <CardBody>
                <Form className="w-75">
                  <FormGroup>
                    <Label for="oldPassword">Old Password</Label>
                    <Input
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      placeholder="******"
                      value={this.state.oldPassword}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="newPassword">New Password</Label>
                    <Input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      placeholder="******"
                      value={this.state.newPassword}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="******"
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {this.state.passErrorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.passErrorMsg}
                    </div>
                  )}
                </Form>
                <div className="w-75">
                  <Button
                    color="primary"
                    size="sm"
                    className="px-5 rounded mt-3"
                    onClick={this.updateUserPassword}
                    disabled={this.state.passWordBtnLoading ? true : false}
                  >
                    {this.state.passWordBtnLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(UpdateProfile);
