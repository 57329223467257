import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import columns from "../Campaigns/CampaignSettingsColList";
import { message, Popconfirm } from "antd";
import deleteIcon from "../../assest/images/delete.svg";
import TableLoader from "../../commons/loaders/TableLoader";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import {
  getAllCampaignSettings,
  deleteCampaignSettingsEntry,
  downloadFile,
} from "../../api/campaignFiles";

class AllPublishers extends Component<any, any> {
  state = {
    loading: false,
    data: {
      columns,
      rows: [],
    },
    settings: [],
  };
  downloadFile = async (item: any) => {
    let url: any = await downloadFile({ id: item.id, type: "publisher" });
    window.open(url.data.pop(), "Download");
  };

  createTableData = () => {
    this.setState({ loading: true });
    let { settings } = this.state;
    let rows = settings.map((setting: any) => {
      return {
        name: setting.CampaignKey
          ? setting.CampaignKey.keyName ||
            `Publisher-${setting.CampaignKey.key}`
          : "",

        id: setting.id,
        key: setting.CampaignKey.key,
        status: (
          <>
            {setting.status !== "completed" &&
              setting.status !== "error" &&
              setting.status !== "pending" && (
                <span style={{ color: "#33b5e5" }}> Processing...</span>
              )}
            {setting.status === "error" && (
              <span style={{ color: "#d9534f" }}> Error</span>
            )}
            {setting.status === "pending" && (
              <span style={{ color: "#055697" }}> Pending</span>
            )}
            {setting.status === "completed" && (
              <span style={{ color: "#00c851" }}> Completed</span>
            )}
          </>
        ),
        totalRecords: setting.totalRecords,
        createdAt: new Date(setting.createdAt).toLocaleString(),
        completedAt: setting.completedAt
          ? new Date(setting.completedAt).toLocaleString()
          : "",
        action: (
          <>
            <Button
              disabled={setting.isCompleted && !setting.hasError ? false : true}
              color="info"
              size="sm"
              onClick={() => {
                this.downloadFile(setting);
              }}
              className="btn"
            >
              Download
            </Button>
            <Button
              className="btn btn-sm mx-2"
              color="success"
              disabled={setting.isCompleted && !setting.hasError ? false : true}
              onClick={() => {
                this.props.history.push(`/publisher/view/${setting.id}`);
              }}
            >
              View
            </Button>
            {this.props.role.campaigns &&
              this.props.role.campaigns.global === "CRUD" && (
                <Popconfirm
                  title="Are you sure delete this task?"
                  onConfirm={() => {
                    this.handleDelete(setting.id);
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    color="bg-transparent"
                    size="sm"
                    className="border-0 shadow-none"
                  >
                    <img src={deleteIcon} width="18" alt="" />
                  </Button>
                </Popconfirm>
              )}
          </>
        ),
      };
    });
    this.setState({ data: { columns, rows }, loading: false });
  };

  getCampaignSettings = async () => {
    try {
      await this.setState({ loading: true });
      let response = await getAllCampaignSettings({ type: "publisher" });
      await this.setState({ settings: response.data, loading: false });
      this.createTableData();
    } catch (error) {
      message.error("Oops,something went wrong!");
      this.setState({ loading: false });
    }
  };

  handleDelete = async (id: any) => {
    try {
      await deleteCampaignSettingsEntry(id);
      message.success("Record Deleted Successfully");
      this.getCampaignSettings();
    } catch (error) {
      message.error("Oops,something went wrong!");
    }
  };

  componentDidMount = async () => {
    try {
      await this.getCampaignSettings();
    } catch (error) {
      console.log("Error while laoding campaings", error);
    }
  };

  render() {
    let { data, loading } = this.state;
    return (
      <>
        {this.props.role.publishers &&
          this.props.role.publishers.global === "CRUD" && (
            <Link
              to="/campaigns/create"
              className="btn btn-success btn-sm float-right"
            >
              CREATE
            </Link>
          )}{" "}
        <Link
          to="/publisher/view/all"
          className="btn btn-success btn-sm float-right"
        >
          Show All
        </Link>
        {loading ? (
          <TableLoader columns={columns} length={7} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            responsive
            noBottomColumns
            data={data}
            small={true}
            className="mt-3"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    role: state.auth.Role.$permissions,
  };
};

export default connect(mapStateToProps)(AllPublishers);
