import React, { Component } from "react";
import CustomLabel from "./CustomLabel";
import { Select } from "antd";

interface Props {
  label: string;
  name: string;
  handleChange: Function;
  data: any[];
}

const customStyle = { height: "12%", borderRadius: "5px", width: "100%" };

class CustomMultiSelect extends Component<Props> {
  render(): JSX.Element {
    const { label, handleChange, name, data } = this.props;
    return (
      <div style={{ marginBottom: "5px" }}>
        <CustomLabel label={label} />
        <Select
          mode="multiple"
          style={customStyle}
          bordered={false}
          className="bg-white py-1"
          placeholder="Please select"
          onChange={(values: any) => {
            handleChange(name, values);
          }}
        >
          {data &&
            data.length &&
            data.map((item: any, index: any) => {
              return (
                <Select.Option
                  className="text-capitalize"
                  value={item}
                  key={`${name}_${index}`}
                >
                  {item}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    );
  }
}

export default CustomMultiSelect;
