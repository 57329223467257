import React from "react";
import { Modal, Typography, Checkbox } from "antd";
import classes from "./styles/BwStatsModel.module.css";

const BwStatsModel = ({ isModalVisible, handleOk, handleCancel }: any) => {
  const onChange = (e: any) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <Modal
      title="Customize KPIs"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className={classes.filterContainer}>
        {filters.map((filter) => (
          <div className={classes.filter}>
            <Typography className={classes.heading}>{filter.title}</Typography>
            <div className={classes.optionContainer}>
              {filter.options.map((option) => (
                <Checkbox onChange={onChange} className={classes.option}>
                  {option}
                </Checkbox>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

const filters = [
  {
    title: "Performance",
    options: ["Spend", "Impressions", "Clicks", "CTR", "CPM", "CPC"],
  },
  {
    title: "Video",
    options: [
      "Video Completion Rate",
      "Video Views to 25%",
      "Video Views 50%",
      "Video Views to 75%",
      "Video views to 100%",
    ],
  },
  {
    title: "Conversion",
    options: [
      "Conversions",
      "CPA",
      "CTC",
      "CTC Rate",
      "Click-Through Conversion (CTC)",
    ],
  },
];

export default BwStatsModel;
