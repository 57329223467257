import React, { Component } from "react";
import { Table } from "reactstrap";
import Expression from "./Expression";

class KeywordsSubTable extends Component<any, any> {
  render() {
    this.props.data.forEach((item: any) => {
      if (!item.currentCount) {
        item.currentCount = 0;
      }
      if (!item.previousCount) {
        item.previousCount = 0;
      }
    });

    return (
      <div>
        <Table className="table-sm table-striped table-hover table-borderless">
          <thead>
            <tr>
              <th className="font-weight-bold border-bottom-0 ">
                Keyword Name
              </th>
              <th
                className="font-weight-bold border-bottom-0"
                style={{ width: "30%" }}
              >
                Current Week Count
              </th>
              <th
                className="font-weight-bold border-bottom-0"
                style={{ width: "30%" }}
              >
                Previous Week Count
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data
              .filter((v: any) => v.type === "week")
              .map((item: any, index: number) => {
                return (
                  <>
                    <tr key={`keywords_subtable_${index}`}>
                      <td className="text-capitalize">{item.keyword}</td>
                      <td>
                        <Expression
                          value={item.currentCount}
                          oldValue={item.previousCount}
                        />
                      </td>
                      <td>{item.previousCount}</td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </Table>

        <hr />

        <Table className="table-sm table-striped table-hover table-borderless">
          <thead>
            <tr>
              <th className="font-weight-bold border-bottom-0 ">
                Keyword Name
              </th>
              <th
                className="font-weight-bold border-bottom-0"
                style={{ width: "30%" }}
              >
                Current Month Count
              </th>
              <th
                className="font-weight-bold border-bottom-0"
                style={{ width: "30%" }}
              >
                Previous Month Count
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data
              .filter((v: any) => v.type === "month")
              .map((item: any, index:number) => {
                return (
                  <tr key={`keywors_subtable_month_${index}`}>
                    <td className="text-capitalize">{item.keyword}</td>
                    <td>
                      <Expression
                        value={item.currentCount}
                        oldValue={item.previousCount}
                      />
                    </td>
                    <td>{item.previousCount}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default KeywordsSubTable;
