import React from "react";
import { forgotPassword } from "../../api/user";
import { notification as Notification } from "antd";
import RenderToast from "../../commons/RenderToast";
import {
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Spinner
} from "reactstrap";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";

interface Props {}

class ForgotPassword extends React.Component<Props, any> {
  state: any = {
    email: "",
    loading: false
  };

  errorMessage = (msg: any): any => {
    Notification.warning({
      message: "Note:",
      description: msg
    });
  };

  handleSubmit = async (event: React.SyntheticEvent) => {
    try {
      event.preventDefault();
      this.setState({ loading: true });
      await forgotPassword({ email: this.state.email });
      this.setState({ loading: false });
      this.errorMessage(
        "Check your inbox for the next steps. If you don't receive an email, and it's not in your spam folder this could mean you signed up with a different address."
      );
    } catch (error) {
      this.setState({ loading: false });
      this.errorMessage(
        "Check your inbox for the next steps. If you don't receive an email, and it's not in your spam folder this could mean you signed up with a different address."
      );
    }
  };

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render(): JSX.Element {
    return (
      <div className="bgImgLogin" style={{ minHeight: "100vh" }}>
        <RenderToast />
        <div
          className="justify-content-center d-flex align-items-center "
          style={{ minHeight: "100vh" }}
        >
          <Card className="card-signin w-25  login-card">
            <CardBody className="my-3">
              <h4 className="card-title  text-center mb-5 loginTitleColor">
                Forget Password
              </h4>
              <Form>
                <Label className="text-white" htmlFor="email">
                  {" "}
                  Email{" "}
                </Label>
                <br />
                <Input
                  className="bg-transparent border-top-0 mb-2 border-left-0 border-right-0 shadow-none rounded-0"
                  placeholder="Enter you email"
                  id="email"
                  type="text"
                  name="email"
                  onChange={this.handleChange}
                />
                <small className="text-white float-right">
                  go back to{" "}
                  <Link
                    to="/login"
                    className="mt-1 text-primary text-underline"
                  >
                    <u>login</u>
                  </Link>
                </small>
                
                <button
                  disabled={
                    this.state.email && !this.state.loading ? false : true
                  }
                  className="loginBtn mt-3"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  {this.state.loading ? (
                    <Spinner color="primary-success" size="sm" />
                  ) : (
                    "submit"
                  )}
                </button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
