import React, { Component } from "react";
import { Table } from "reactstrap";
import Expression from "./Expression";

class TopicsSubTable extends Component<any, any> {
  render() {
    return (
      <div>
        <Table className="table-sm table-striped table-hover table-borderless">
          <thead>
            <tr>
              <th className="font-weight-bold border-bottom-0">Name</th>
              <th className="font-weight-bold border-bottom-0">
                Current (Week)
              </th>
              <th className="font-weight-bold border-bottom-0">
                Previous (Week)
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.laading ? (
              <>
                <tr>
                  <td>Loading...</td>
                </tr>
              </>
            ) : (
                <>
                  {this.props.data &&
                    this.props.data.map((item: any, index: number) => {
                      return (
                        <tr key={`topics_sub_table_${index}`}>
                          <td className="text-capitalize">{item.topic}</td>
                          <td>
                            {(item.previousWeekCount || item.previousWeeKCount) ? (
                              <Expression

                                value={item.currentWeekCount || 10}
                                oldValue={item.previousWeekCount || item.previousWeeKCount}

                              />
                            ) : (
                                <> {item.currentWeekCount} (New)</>
                              )}
                            {/* <Expression

                        value={item.currentWeekCount || 10}
                        //@missing
                        percentAgeValue={item.previousWeekCount?`${item.score}%`:'New'}
                      /> */}
                          </td>
                          <td className="text-capitalize">
                            {item.previousWeeKCount || "n/a"}
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default TopicsSubTable;
