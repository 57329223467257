import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "./AppBar";
import TabBar from "./TabBar";
import { Container } from "reactstrap";
import Submenu from "./Submenu";
import { fetchUserList } from "../redux/users/actions";
import { fetchCompanies } from "../redux/companies/actions";

class PrivateRoute extends React.Component<any, any> {
  fetchCompanySuccess = async (res: any) => {};
  fetchCompanyFailure = async (error: any) => {};
  onUserFatchSuccess = (res: any) => {};
  onUserFatchFailur = () => {};
  componentDidMount() {
    this.props.dispatch(
      fetchCompanies(
        { text: "" },
        (res: any) => this.fetchCompanySuccess(res),
        (error: any) => this.fetchCompanyFailure(error)
      )
    );

    this.props.dispatch(
      fetchUserList(
        {
          text: "",
          companyId: "",
        },
        (res: any) => this.onUserFatchSuccess(res),
        () => this.onUserFatchFailur()
      )
    );
  }
  render() {
    let { component: Component, auth, tabs, ...rest } = this.props;
    let loginRole = window.localStorage.getItem("Role");
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              {auth.token ? (
                <>
                  <AppBar />
                  <Container fluid id="nav-bar">
                    <TabBar />
                  </Container>
                  {/setup/i.test(props.location.pathname) &&
                    !props.location.pathname.includes("/campaign-setup") && (
                      <Submenu />
                    )}
                  <Container fluid id="nav-bar">
                    <Component {...props} />
                  </Container>
                </>
              ) : loginRole === "admin" ? (
                <Redirect to="/admin/login" />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  tabs: state.tabs,
});

export default connect(mapStateToProps)(PrivateRoute);
