const MANAGEMENT_LEVEL = [
  {
    children: [],
    title: "Non-Manager",
    key: "Non-Manager",
  },
  {
    children: [],
    title: "Manager",
    key: "Manager",
  },
  {
    children: [],
    title: "Director",
    key: "Director",
  },
  {
    children: [],
    title: "VP-Level",
    key: "VP-Level",
  },
  {
    children: [],
    title: "C-Level",
    key: "C-Level",
  },
  {
    children: [],
    title: "Staff",
    key: "Staff",
  },
  {
    children: [],
    title: "CxO",
    key: "CxO",
  },
];
export { MANAGEMENT_LEVEL };
