import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Table } from "reactstrap";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

/* Custom Components */
import SideBar from "../../commons/Sidebar/SideBar";
import {
  getCampaignSetupData,
  fetchCampaignStaticValues,
  fetchCounts,
} from "../../api/target-account";
import headers from "./data/TargetAccountTableHeaders";
import SurginTopicsTd from "./TablePartials.tsx/SurginTopicsTd";
import SiteVistitorTd from "./TablePartials.tsx/SiteVistitorTd";
import AccountTd from "./TablePartials.tsx/AccountTd";
import TopLocations from "./TablePartials.tsx/TopLocations";
import { Tabs, Spin, Empty, message, Pagination, Tooltip, Modal } from "antd";
import { TAHelper } from "./TargetAccountHelper";
import downloadIcon from "../../assest/images/download.svg";
import "./TargetAccount.css";
import DeltaExpression from "./DelatExpression";
import DomainDetail from "../../commons/DomainDetail";
import DigitalStatsTd from "./TablePartials.tsx/DigitalStatsTd";
class TargetAccount extends Component<any, any> {
  state: any = {
    tableIsOpen: false,
    campaignSetupId: null,
    data: [],
    pageCount: 0,
    currentPage: 1,
    loading: false,
    originalData: [],
    key: "",
    keyName: "",
    updatedAt: "",
    waiting: false,
    query: "",
    sortQuery: "",
    count: 100,
    totalCount: 0,
    visible: false,
    selectedItem: {},
  };
  downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/campaign-setup/${this.props.match.params.id}/download/file?token=${this.props.auth.token}&type=campaign&`;

  getCampaignStaticValues = async () => {
    try {
      let id = this.props.match.params.id;
      await this.setState({ waiting: true });
      let response = await fetchCampaignStaticValues({ id });
      this.setState({
        key: response.data.key,
        keyName: response.data.keyName,
        updatedAt: response.data.CampaignSetups[0].completedAt,
      });
      await this.setState({ waiting: false });
    } catch (error) {
      this.setState({ waiting: false });
      message.error("Error while loading static values!");
    }
  };

  fetchCampaignData = async () => {
    try {
      this.setState({ loading: true });
      let id = this.props.match.params.id;
      let { query, currentPage } = this.state;
      await this.setState({ campaignSetupId: id });
      let resp: any = await getCampaignSetupData(
        id,
        query,
        currentPage,
        "campaign"
      );
      this.setState({
        loading: false,
        originalData: resp.data.rows,
        count: resp.data.count,
        totalCount:resp.data.count

      });
    } catch (error) {
      await this.setState({ originalData: [], loading: false });
    }
  };

  getCounts = async () => {
    try {
      let id = this.props.match.params.id;
      let { query, currentPage } = this.state;
      if (!query || !Object.keys(query).length) {
        query = `{"rootWhere":{"tier":["Tier 4", "Tier 2", "Tier 1", "Tier 3"]}}`;
      }
      let response = await fetchCounts(id, query, currentPage, "campaign");
      await this.setState({ totalCount: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount = async () => {
    this.getCampaignStaticValues();
    this.fetchCampaignData();
   // this.getCounts();
  };
  setQuery = async (query: any, sortable: boolean = false) => {
    if (sortable) {
      await this.setState({
        sortQuery: query,
        query: {
          ...this.state.query,
          ...query,
        },
      });
    } else {
      await this.setState({
        query: {
          ...query,
          ...this.state.sortQuery,
        },
      });
    }
  };

  applyFilters = async ({ query }: any) => {
    try {
      await this.setQuery(query);
      await this.setState({ loading: true, currentPage: 1 });
      let id = this.props.match.params.id;
      let resp: any = await getCampaignSetupData(
        id,
        this.state.query,
        this.state.currentPage
      );
      this.setState({
        loading: false,
        originalData: resp.data.rows,
        count: resp.data.count,
        totalCount: resp.data.count,
        
      });
    //  this.getCounts();
    } catch (error) {
      await this.setState({
        loading: false,
        originalData: [],
      });
      message.error("Error while loading campaign data!");
    }
  };

  handlePageChange = async (value: any) => {
    await this.setState({ currentPage: value });
    this.fetchCampaignData();
  };

  clearFilters = async () => {
    await this.setState({ query: "" });
    this.fetchCampaignData();
   // this.getCounts();
  };
  openDomainDetailBox = async (item: any) => {
    this.setState({ ...this.state, selectedItem: { ...item }, visible: true });
  };

  render() {
    return (
      <>
        <DomainDetail
          item={this.state.selectedItem}
          isVisible={this.state.visible}
          visiblEvent={() => {
            this.setState({ ...this.state, visible: false });
          }}
          campaignKey={this.state.key}
        />
        <Row style={{ background: "#F4F5F6" }}>
          <Col md="2" lg={"2"} xl="2">
            <SideBar
              applyFilters={this.applyFilters}
              clearFilters={this.clearFilters}
              type="campaign"
              campaignSetupId={this.state.campaignSetupId}
            />
          </Col>

          <Col
            md="10"
            lg={"10"}
            xl="10"
            className=""
            style={{ paddingRight: "5px", paddingLeft: "23px" }}
          >
            <div className="ml-0">
              <div className="d-block d-md-flex justify-content-between ml-0 pt-3 mb-3">
                <div>
                  <div>
                    <h2 style={{ fontWeight: 600 }} className="primary-dark">
                      {this.state.waiting ? "Loading..." : this.state.keyName}
                    </h2>
                  </div>
                  <small style={{ fontWeight: 400, fontSize: "100%" }}>
                    <b style={{ fontWeight: 500 }}>Last Updated: </b>
                    {this.state.waiting
                      ? "loading..."
                      : new Date(this.state.updatedAt).toLocaleDateString()}
                    {" | "}
                    <b style={{ fontWeight: 500 }}>Count: </b>
                    {this.state.totalCount}
                  </small>
                  <br />
                </div>

                <div className="mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${this.downloadUrl}$where=${
                      this.state.query
                        ? JSON.stringify(this.state.query)
                        : this.state.query
                    }`}
                    className="btn bg-white btn-sm rounded-pill px-4"
                  >
                    <img
                      src={downloadIcon}
                      width="10px"
                      className="mb-1"
                      alt=""
                    />{" "}
                    DOWNLOAD
                  </a>
                </div>
              </div>
              <div className="table-responsive">
                <Table responsive className="bg-white main-tbl target-table">
                  <thead className="border-bottom-0">
                    <tr>
                      <>
                        {headers.map((head: any, index) => {
                          return (
                            <th
                              key={`headers_${index}`}
                              className="font-weight-bold border-bottom-0 primary-dark"
                              style={{
                                fontSize: "0.94rem",
                              }}
                            >
                              <span className="d-inline" onClick={() => {}}>
                                {head.sortable && (
                                  <>
                                    <span className="caret-up-cont">
                                      <CaretUpOutlined
                                        onClick={async () => {
                                          let sortQuery =
                                            await TAHelper.handleSort(
                                              head.key,
                                              "ASC",
                                              this
                                            );
                                          await this.setQuery(sortQuery, true);
                                          await this.fetchCampaignData();
                                        }}
                                      />
                                    </span>
                                    <br />
                                    <span className="caret-down-cont">
                                      <CaretDownOutlined
                                        onClick={async () => {
                                          let sortQuery =
                                            await TAHelper.handleSort(
                                              head.key,
                                              "DESC",
                                              this
                                            );
                                          await this.setQuery(sortQuery, true);
                                          await this.fetchCampaignData();
                                        }}
                                      />
                                    </span>
                                  </>
                                )}
                              </span>

                              <span className="d-inline">{head.name}</span>

                              {head.description && (
                                <>
                                  <span className="ml-1">
                                    <Tooltip
                                      title={head.description}
                                      key={head.name}
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </span>
                                </>
                              )}
                            </th>
                          );
                        })}
                      </>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {this.state.loading ? (
                      <tr>
                        <td colSpan={13}>
                          <div className="justify-content-center align-items-center d-flex">
                            <Spin size="large" />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      this.state.originalData.map((item: any, index: any) => {
                        let leadAppendDetailLocationJson = JSON.parse(
                          item.leadAppendDetailLocationJson || "[]"
                        );
                        let leadAppendDetailTopicJson = JSON.parse(
                          item.leadAppendDetailTopicJson || "[]"
                        );
                        let leadAppendDetailDeltum = item.LeadAppendDetailDeltum
                          ? item.LeadAppendDetailDeltum
                          : {};
                        return (
                          <React.Fragment key={`target_Account_${index}`}>
                            <tr className="data-row">
                              {/* Account <td> */}
                              <AccountTd
                                data={item}
                                onClickEvent={() => {
                                  this.openDomainDetailBox(item);
                                }}
                              />

                              {/* <td className="text-center text-capitalize  text-muted">
                                <Tooltip
                                  key={`topic_${index}`}
                                  title={item.fractionScore || 0}
                                >
                                  <small className="d-inline-block text-truncate text-muted">
                                    {
                                      <DeltaExpression
                                        oldValue={
                                          leadAppendDetailDeltum.previousActiveSource ||
                                          0
                                        }
                                        value={
                                          leadAppendDetailDeltum.currentActiveSource ||
                                          item.fractionScoreNumerator ||
                                          0
                                        }
                                        showPercentage={false}
                                        deltaValue={
                                          leadAppendDetailDeltum.diffActiveSource ||
                                          0
                                        }
                                      />
                                    }
                                  </small>
                                </Tooltip>
                              </td> */}

                              {/* Score <td> */}
                              {/* <td className="text-center text-capitalize  text-muted">
                                <small className="d-inline-block text-truncate text-muted">
                                  {
                                    <DeltaExpression
                                      oldValue={
                                        leadAppendDetailDeltum.previousAggregateScore ||
                                        0
                                      }
                                      value={item.unifiedScore || 0}
                                      showPercentage={false}
                                      deltaValue={
                                        leadAppendDetailDeltum.diffAggregateScore ||
                                        0
                                      }
                                    />
                                  }
                                </small>
                              </td> */}

                              {/* Surgin Topics <td> */}
                              <SurginTopicsTd
                                data={leadAppendDetailTopicJson}
                              />

                              {/* Digital Stats <td> */}
                              <DigitalStatsTd
                                data={item}
                              />

                              {/* Site Visitor <td> */}
                              <SiteVistitorTd data={item} />

                              {/* TopLocation <td> */}
                              <TopLocations
                                data={leadAppendDetailLocationJson}
                              />

                              {/* Icon for subtable */}
                              <td
                                id={`submenu${index}`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.openDomainDetailBox(item);
                                }}
                              >
                                <EyeOutlined
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "x-large",
                                  }}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    )}
                    {!this.state.originalData.length && !this.state.loading && (
                      <tr>
                        <td colSpan={13}>
                          <div className="justify-content-center align-items-center d-flex">
                            <Empty />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <br />
              <div className="text-center">
                <Pagination
                  disabled={this.state.loading}
                  defaultCurrent={this.state.currentPage}
                  current={this.state.currentPage}
                  total={this.state.count}
                  onChange={(page) => {
                    this.handlePageChange(page);
                  }}
                />
              </div>
              <br />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(TargetAccount);
