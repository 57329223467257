import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Input } from "antd";

import styled from "styled-components";
const StyledCard = styled(Card)``;

const PersonalFilter = ({
  hanleInputChange,
  fullName,
  jobTitle
}: {
  hanleInputChange: any | Function;
  fullName: string;
  jobTitle: string;
}): JSX.Element => {
  return (
    <div>
      <StyledCard className="my-2">
        <CardHeader>
          <p className="font-weight-500 mb-0" > Person Filters</p>
        </CardHeader>
        <CardBody>
          <Input
            type="text"
            value={fullName}
            name="fullName"
            onChange={hanleInputChange}
            className="mb-2 pb-2"
            placeholder="Full Name"
          />
          <Input
            type="text"
            value={jobTitle}
            onChange={hanleInputChange}
            name="jobTitle"
            className="mb-2 pb-2"
            placeholder="Job Title"
          />
        </CardBody>
      </StyledCard>
    </div>
  );
};

export default PersonalFilter;
