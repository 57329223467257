let initialState = {
  userList: []
};

const userList = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        userList: [...action.payload]
      };

    default:
      return {
        ...state
      };
  }
};

export default userList;
