import React, { Component } from "react";
import CustomLabel from "./CustomLabel";
import { Slider } from "antd";

interface Props {
  label: string;
  name: string;
  defaultValues: any;
  handleChange: Function;
  filtersData: any;
}

class CustomRange extends Component<Props> {
  render(): JSX.Element {
    const {
      filtersData,
      label,
      handleChange,
      name,
      defaultValues,
    } = this.props;

    return (

      <div style={{ marginBottom: "5px" }}>
        <CustomLabel label={label} />
        <Slider
          range
          min={defaultValues[name].min || 0}
          max={defaultValues[name].max || 10}
          value={[
            filtersData[name].min || defaultValues[name].min,
            filtersData[name].max || defaultValues[name].max || 10,
          ]}
          onChange={async (values: any) => {
            await handleChange(name, values);
          }}
        />
      </div>
    );
  }
}

export default CustomRange;
