export const passwordandConfirmpass = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return { status: false, message: "Password do not match" };
  } else {
    return { status: true, message: "" };
  }
};

export const validateInputFields = (
  email,
  password,
  confirmPassword,
  Username,
  isAdmin
) => {
  let emailRegex =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  let regEx = new RegExp(emailRegex);
  let isEmailRegExPassed = regEx.test(email);
  if (!email) {
    return { status: false, message: "Email must  Required" };
  } else if (!isEmailRegExPassed) {
    return { status: false, message: "Email Not Valid" };
  } else if (!Username && !isAdmin) {
    return { status: false, message: "Name must Required" };
  } else if (!password) {
    return { status: false, message: "Password must Required" };
  } else if (!confirmPassword) {
    return { status: false, message: "Confirm Password must Required" };
  } else if (password !== confirmPassword) {
    return { status: false, message: "Password do not match" };
  } else {
    return { status: true, message: "" };
  }
};

export const subAdminValidations = ({ email, password, confirmPassword }) => {
  let emailRegex =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  let regEx = new RegExp(emailRegex);
  let isEmailRegExPassed = regEx.test(email);
  if (!email) {
    return { status: false, message: "Email must  Required" };
  } else if (!isEmailRegExPassed) {
    return { status: false, message: "Email Not Valid" };
  } else if (!password) {
    return { status: false, message: "Password must Required" };
  } else if (!confirmPassword) {
    return { status: false, message: "Confirm Password must Required" };
  } else if (password !== confirmPassword) {
    return { status: false, message: "Password do not match" };
  } else {
    return { status: true, message: "" };
  }
};

export const userNameOrEmailValidation = (email, username) => {
  let emailRegex =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  let regEx = new RegExp(emailRegex);
  let isEmailRegExPassed = regEx.test(email);
  if (!email) {
    return { status: false, message: "Email must  Required" };
  } else if (!isEmailRegExPassed) {
    return { status: false, message: "Email Not Valid" };
  } else if (!username) {
    return { status: false, message: "Name must Required" };
  } else {
    return { status: true, message: "" };
  }
};

export const userPasswrodUpdate = (
  oldPassword,
  newPassword,
  confirmPassword
) => {
  if (!oldPassword) {
    return { status: false, message: "Old Password must  Required" };
  } else if (!newPassword) {
    return { status: false, message: "New Password must  Required" };
  } else if (newPassword !== confirmPassword) {
    return { status: false, message: "Password do not match" };
  } else {
    return { status: true, message: "" };
  }
};
