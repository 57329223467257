import React from "react";
import classes from "./styles/TopDomainCard.module.css";
import { Typography, Spin } from "antd";

const TopDomainCard = ({ domains, title, loading }: any) => {
  return (
    <div className={classes.container}>
      <div className={classes.mainBox}>
        <Typography className={classes.title}>{title}</Typography>
        {/* <Typography className={classes.number}>{number}</Typography> */}
      </div>
      <div className={classes.secondaryBox}>
        <Typography className={classes.domainTitle}>Domains:</Typography>
        {!loading ? (
          domains?.length ? (
            domains?.map((domain: any) => (
              <div className={classes.domainBox}>
                <Typography className={`${classes.domain} primaryColor`}>
                  {domain.domain}
                </Typography>
              </div>
            ))
          ) : null
        ) : (
          <div className="spinMainStatsItem">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopDomainCard;
