import React, { SFC, useState } from "react";
import userIcon from "../assest/images/user.svg";
import returnIcon from "../assest/images/return.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import contanuityLogo from "../assest/images/contanuity-lite.png";

import {
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  Button,
} from "reactstrap";
import RenderToast from "./RenderToast";
import { fetchCompanies } from "../redux/companies/actions";
import { message } from "antd";

const AppBar: SFC<any> = (props): JSX.Element => {
  const [navBar, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!navBar);

  const logout = () => {
    window.localStorage.setItem("Role", props.auth.Role.name);
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("previousLoginState");
    props.dispatch({ type: "LOGOUT_USER" });
  };

  const switchBack = () => {
    props.dispatch({
      type: "LOGIN_BACK_TO_PREVIOUS",
    });
    props.dispatch(
      fetchCompanies(
        { text: "" },
        (res: any) => fetchCompanySuccess(res),
        (error: any) => fetchCompanyFailure(error)
      )
    );
    message.success("Account Switched");

    props.history.push("/");
    window.location.reload();
  };

  const fetchCompanySuccess = async (res: any) => {};
  const fetchCompanyFailure = async (error: any) => {};
  return (
    <>
      <RenderToast />
      <nav
        className={`navbar navbar-expand-lg ${
          props.auth.Role.name === "admin" ? "navBgColorAdmin" : "navBgColor"
        }`}
      >
        <Link className={`navbar-brand text-light`} to="/">
          <img src={contanuityLogo} alt="logo" style={{ height: "36px" }} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggle}
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <Collapse
          isOpen={navBar}
          className="navbar-collapse justify-content-end"
        >
          <Nav className=" justify-content-end" navbar>
            {props.auth.previousLoginState && (
              <NavItem>
                <Button
                  size="sm"
                  color="light"
                  onClick={switchBack}
                  className="d-flex justify-content-center"
                >
                  <img
                    src={returnIcon}
                    alt="return"
                    className="mr-2"
                    width={15}
                  />
                  Switch Back
                </Button>
              </NavItem>
            )}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="appBarDropDownText">
                <img src={userIcon} alt="" />
                <label className="navBarText">
                  {props.auth.email ? props.auth.email : props.auth.username}
                </label>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </nav>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, null)(withRouter(AppBar));
