import React from "react";
import classes from "./styles/BwStatsFooter.module.css";
import { Select, Tooltip } from "antd";

const { Option } = Select;

const BwStatsFooter = ({
  setChartFieldsHandler,
  chartFields,
  setChartLabelsHandler,
  paramOptions,
}: any) => {
  const handleParamChange = ({ index, key }: any) => {
    setChartFieldsHandler(key, paramOptions[index].value);
    setChartLabelsHandler(key, paramOptions[index].title);
  };

  const handleDurationChange = (key: any, value: any) => {
    setChartFieldsHandler(key, value);
  };

  return (
    <div className={classes.container}>
      <div>
        <Select
          className={classes.select}
          defaultValue={0}
          onChange={(value) =>
            handleParamChange({
              key: "firstParam",
              index: value,
            })
          }
        >
          {paramOptions.map((option: any, index: any) => (
            <>
              {chartFields.secondParam !== option.value && (
                <Option value={index} key={index}>
                  <Tooltip title={option.title}>
                    <span>{option.title}</span>
                  </Tooltip>
                </Option>
              )}
            </>
          ))}
        </Select>
        <Select
          className={classes.select}
          defaultValue={1}
          onChange={(value) =>
            handleParamChange({
              key: "secondParam",
              index: value,
            })
          }
        >
          {paramOptions.map((option: any, index: any) => (
            <>
              {chartFields.firstParam !== option.value && (
                <Option value={index} key={index}>
                  <Tooltip title={option.title}>
                    <span>{option.title}</span>
                  </Tooltip>
                </Option>
              )}
            </>
          ))}
        </Select>
      </div>
      <div className={classes.durationContainer}>
        <Select
          className={classes.select}
          defaultValue={durationOptions[0].value}
          onChange={(value) => handleDurationChange("dateParam", value)}
        >
          {durationOptions.map((option, index) => (
            <Option value={option.value} key={index}>
              <Tooltip title={option.title}>
                <span>{option.title}</span>
              </Tooltip>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

const durationOptions = [
  {
    title: "Days",
    value: "Day",
  },
  {
    title: "Weeks",
    value: "Week",
  },
  {
    title: "Months",
    value: "Month",
  },
  // {
  //   title: "Years",
  //   value: "Year",
  // },
];

export default BwStatsFooter;
