import { FETCH_COMPANIES_SUCCESS, SET_COMPANIES } from "./types";

let initialState = {
  list: []
};

const copmaniesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        list: [...action.payload.data]
      };
    case SET_COMPANIES:
      return {
        ...state,
        list: [...action.payload]
      };
    default:
      return state;
  }
};

export default copmaniesReducer;
