import React from 'react';

const CustomLabel = ({ label }: { label: string }) => {
  return (
    <div className="justify-content-between d-flex mb-2">
      <small>{label}</small>
    </div>
  );
};

export default CustomLabel;
