const TAHelper = {
  handleSort: async (value: string, order: string, ctx: any) => {

    //let { query } = ctx.state;
    let query: any = {};

    if (value === "Score") {
      query.order = ["unifiedScore", order];
    }
    else if (value === "HVC") {
      query.order = ["l1TrendingTopicsCountCurrentWeek", order];
    }
    else if (value === "Rank") {
      query.order = ["rank", order];
    }
    else if (value === "Surging Topics") {
      query.order = ["totalSurgingTopics", order];
    }
    else if (value === "Tier") {
      query.order = ["tier", order];
    }
    else if (value === "fractionScore") {
      query.order = ["fractionScoreNumerator", order];
    }
    else {
      query.order = [value, order];
    }
    // where = {
    //   order: ["id", "DESC"],
    // };
    // if (query) {
    //   await ctx.setState({ query });
    //   ctx.fetchCampaignData();
    //   // API call for the sorting
    // }
    return query;
  },
};
export { TAHelper };
