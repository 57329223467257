import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const Setup = ({ role }: { role: string }) => {
  return (
    <>
      {role === "admin" ? (
        <Redirect to="/setup/create/user" />
      ) : (
        <Redirect to="/setup/update/profile" />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  role: state.auth.Role.name
});

export default connect(mapStateToProps)(Setup);
