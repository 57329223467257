import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Table,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { uploadFile } from "../../api/target-account";
import { connect } from "react-redux";
import { Select } from "antd";

const { Option } = Select;

interface Props {
  csvModal: Boolean | any;
  csvToggle: Function | any;
  downloadCsv: Function | any;
  getCompanyFileList: Function | any;
  createTableData: Function | any;
}

interface State {
  fileDetail: Boolean;
  fileNameOrSuccess: String;
  selectedFile: any;
  loading: Boolean;
  errorMsg: String;
  fileType: any;
}
class CsvImportModal extends Component<Props, State> {
  state: State = {
    fileDetail: false,
    fileNameOrSuccess: "",
    selectedFile: null,
    loading: false,
    errorMsg: "",
    fileType: undefined,
  };

  $uploadFile = async () => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const data = new FormData();
      data.append("file", this.state.selectedFile);
      data.append("fileType", this.state.fileType);
      let res = await uploadFile(data);
      this.setState({
        fileNameOrSuccess: res.data.msg,
        loading: false,
        selectedFile: null,
      });
      await this.props.getCompanyFileList();
      await this.props.createTableData();
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          errorMsg: error.response.data.message,
        });
      } else {
        this.setState({
          loading: false,
          errorMsg: "Something went terribly wrong!",
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.setState({ fileType: undefined });
  };

  render() {
    let { csvModal, csvToggle, downloadCsv } = this.props;
    return (
      <div>
        {/* change permission modal */}
        <Modal
          isOpen={csvModal}
          toggle={csvToggle}
          className="modal-dialog-centered"
        >
          <ModalBody>
            <div className="pb-4">
              <span className="font-weight-bold">
                Upload Target Account Csv File
              </span>
              {this.state.fileType ? (
                <Button
                  color="white"
                  className="float-right shadow-none mt-1 p-0 dropdown-toggle"
                  size="sm"
                  onClick={() => {
                    this.setState({ fileDetail: !this.state.fileDetail });
                  }}
                >
                  Show file format
                </Button>
              ) : null}
            </div>
            <Select
              value={this.state.fileType}
              onChange={(value: any) => this.setState({ fileType: value })}
              placeholder="Please select file type!"
            >
              <Option value="domains">Domains</Option>
              <Option value="emails">Emails</Option>
            </Select>
            {this.state.fileType ? (
              <label className="btn btn-light py-1 px-3">
                <input
                  type="file"
                  className="filtTypeInput"
                  accept=".csv"
                  onChange={(event: any) => {
                    this.setState({
                      fileNameOrSuccess: event.target.files[0].name,
                      selectedFile: event.target.files[0],
                    });
                  }}
                />
                Add csv
              </label>
            ) : null}
            <div className={this.state.fileDetail ? "d-block" : "d-none"}>
              <ul className="mt-3">
                <li>The File must be CSV format, limit 2000/5 Million rows</li>
                <li>Firt row of the document must contain column header</li>
                <li>
                  Column 1:{" "}
                  {this.state.fileType === "emails" ? "Email" : "Domain"}
                </li>
                <li>
                  Column 2:{" "}
                  {this.state.fileType === "emails" ? "Domain" : "Zip Code"}
                </li>
              </ul>
              <div className="text-danger">
                *Note : Any one of them should be provided.
              </div>
              <span>e.g</span>
              {this.state.fileType === "domains" ? (
                <Table striped={true} bordered={true}>
                  <tbody>
                    <tr>
                      <td>google.com</td>
                      <td>1234</td>
                    </tr>
                    <tr>
                      <td>google.com</td>
                      <td>12</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <Table striped={true} bordered={true}>
                  <tbody>
                    <tr>
                      <td>abc@gmail.com</td>
                    </tr>
                    <tr>
                      <td>abc@yahoo.com</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
            {this.state.fileNameOrSuccess && (
              <div className="my-2 rounded p-2 alert-success">
                <span className="font-weight-bold">
                  {this.state.fileNameOrSuccess}
                </span>
              </div>
            )}
            {this.state.errorMsg && (
              <div className="my-2 rounded p-2 alert-danger">
                <span className="">{this.state.errorMsg}</span>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="sm"
              className="float-right mt-3 d-none"
              onClick={downloadCsv}
            >
              Download File
            </Button>
            <Button
              color="primary"
              size="sm"
              className="float-right mt-3"
              disabled={!this.state.selectedFile ? true : false}
              onClick={this.$uploadFile}
            >
              {this.state.loading ? (
                <Spinner color="light" size="sm" />
              ) : (
                "Upload"
              )}
            </Button>
            <Button
              color="light"
              size="sm"
              className=" mt-3"
              onClick={() => {
                csvToggle();
                this.setState({
                  selectedFile: null,
                  fileNameOrSuccess: "",
                  errorMsg: "",
                  fileDetail: false,
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CsvImportModal);
