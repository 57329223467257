import React from "react";

const FiltersHeader = ({
  label,
  handleClear,
}: {
  label: string;
  handleClear: Function;
}) => {
  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <div className="font-weight-bold primary-dark">{label}</div>
        <span
          className="text-decoration-underline"
          onClick={() => {
            handleClear();
          }}
        >
          <small>
            <u className='cursor-pointer'>Clear all</u>
          </small>
        </span>
      </div>
      <hr className="bg-light my-1" />
    </>
  );
};

export default FiltersHeader;
