import React from "react";
import { getAdMetric } from "../../../api/abm-stats";
import Expression from "../../TargetAccount/Expression";
import moment from "moment";
import CustomAntTable from "../../../commons/CustomAntTable";
import _ from "lodash";

const antColumns = [
  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
  },
  {
    title: "Impressions Current",
    dataIndex: "impcur",
    key: "impcur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Impressions Previous",
    dataIndex: "impprev",
    key: "impprev",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Impressions Delta",
    dataIndex: "impdelta",
    key: "impdelta",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Clicks",
    dataIndex: "clickscur",
    key: "clickscur",
    render: (item: any) => (
      <Expression
        value={item.clickscur}
        oldValue={item.clicksprev}
        percentAgeValue={item.clicksdelta.toString()}
      />
    ),
    sorter: true,
    sortOrder: false,
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    key: "ctr",
  },
];

const AdMetrics = ({
  tableData,
  setTableData,
  changeLoadingHandler,
  campaigns,
  loading,
  filters,
  filterChangeHandler,
}: any) => {
  const [columns, setColumns] = React.useState(antColumns);

  const fetchData = async () => {
    try {
      setTableData([]);
      changeLoadingHandler("tableData", true);
      // const inputData = formatSiteVisitorMetrics();
      let startDate = "";
      let endDate = "";
      if (filters.range) {
        const range = filters.range.split(",");
        startDate = range[0];
        endDate = range[1];
      } else {
        startDate = moment()
          .subtract(filters.dayspan, "day")
          .format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }
      const filterObj = {
        ...filters,
        campaignid: filters.campaignid
          ? filters.campaignid
          : campaigns?.[0]?.CampaignKey?.key
          ? campaigns?.[0]?.CampaignKey?.key
          : "-1",
        startdateyyyymmdd: startDate,
        enddateyyyymmdd: endDate,
      };
      const res: any = await getAdMetric(filterObj);
      const row: any = res?.data?.[0]?.result?.Row;
      let data = row.map((item: any) => {
        return {
          domain: item.domain,
          impcur: item.impcur,
          impprev: item.impprev,
          impdelta: item.impdelta,
          clickscur: {
            clickscur: item.clickscur,
            clicksprev: item.clicksprev,
            clicksdelta: item.clicksdelta,
          },
          ctr:
            parseFloat(((item.clickscur / item.impcur) * 100).toFixed(2)) + "%",
        };
      });
      setTableData(data ?? []);
      changeLoadingHandler("tableData", false);
    } catch (error) {
      changeLoadingHandler("tableData", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchData();
    }
  }, [campaigns, filters]);

  return (
    <CustomAntTable
      columns={columns}
      setColumns={setColumns}
      tableData={tableData}
      loading={loading}
      filterChangeHandler={filterChangeHandler}
      filters={filters}
    />
  );
};

export default AdMetrics;
