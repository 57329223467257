import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  CardHeader,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import {
  Radio,
  Checkbox,
  message,
  Table as AntTable,
  Input,
  Button as AntButton,
} from "antd";

import styled from "styled-components";
import {
  uploadFile,
  getLatestFileDetail,
  saveCampignKeys,
  submitCampignKeys,
} from "../../api/campaign-setup";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const StyledCard = styled(Card)``;

const columns = [
  {
    label: <div className="font-weight-bold">Bulk</div>,
    field: "bulk",
    sort: false,
  },
  {
    label: (
      <div style={{ textAlign: "right" }} className="font-weight-bold">
        Delta
      </div>
    ),
    field: "delta",
    sort: false,
  },
];

interface State {
  fileDetail: boolean;
  fileNameOrSuccess: String;
  selectedFile: any;
  loading: boolean;
  errorMsg: String;
  fileType: String;
  editAble: boolean;
  data: any;
  latestFile: any;
  selectedCampaignKey: any;
  searchText: any;
  searchedColumn: any;
  submitBtnLoading: boolean;
  saveBtnLoading: boolean;
}
class KeywordsDomain extends Component {
  state: State = {
    fileType: "keyword",
    fileDetail: false,
    fileNameOrSuccess: "",
    selectedFile: null,
    loading: true,
    errorMsg: "",
    editAble: false,
    data: { columns },
    latestFile: {},
    selectedCampaignKey: {
      bulk: {},
      delta: {},
    },
    searchText: "",
    searchedColumn: "",
    submitBtnLoading: false,
    saveBtnLoading: false,
  };
  searchInput: any;

  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />

          <AntButton
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
        </AntButton>
          <AntButton
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
        </AntButton>
        </div>
      ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: any) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });
  antcolumns: Array<any> = [
    {
      title: "Bulk",
      dataIndex: "campaignKey",
      key: "campaignKey",
      ...this.getColumnSearchProps("campaignKey"),
      render: (campaignKey: any) => (
        <div>
          <Checkbox
            className=""
            disabled={!this.state.editAble}
            onChange={(e) =>
              this.onCheckBoxChange(e.target.checked, campaignKey, "bulk")
            }
            checked={!!this.state.selectedCampaignKey["bulk"][campaignKey]}
          />
          &nbsp;&nbsp; {campaignKey}
        </div>
      ),
      width: "33%"
    },
    {
      title: "Delta",
      dataIndex: "campaignKey",
      key: "campaignKey",
      ...this.getColumnSearchProps("campaignKey"),
      render: (campaignKey: any) => (
        <div style={{ textAlign: "right" }}>
          {campaignKey}
          &nbsp;&nbsp;
          <Checkbox
            className=""
            disabled={!this.state.editAble}
            onChange={(e) =>
              this.onCheckBoxChange(e.target.checked, campaignKey, "delta")
            }
            checked={!!this.state.selectedCampaignKey["delta"][campaignKey]}
          />
        </div>
      ),
      width: "33%"
    },
    {
      title: "Updated On",
      dataIndex: "submittedAt",
      key: "submittedAt",
      render: (submittedAt: any) => (
        <div>
          {submittedAt ? new Date(submittedAt).toLocaleString() : ""}
        </div>
      ),
      sorter: {
        compare: (a: any, b: any) => {
          const fisrt: any = new Date(a.submittedAt);
          const second: any = new Date(b.submittedAt)
          return fisrt - second
        },
        multiple: 1,
      },
      width: "33%"

    }
  ];
  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleRadioChange = (event: any) => {
    this.setState({ fileType: event.target.value });
  };
  onCheckBoxChange = (status: boolean, value: string, type: string) => {
    let preData: any = { ...this.state.selectedCampaignKey };
    if (status) {
      preData[type] = { ...preData[type], [value]: 1 };
    } else {
      delete preData[type][value];
    }
    this.setState({
      selectedCampaignKey: preData,
    });
  };
  $uploadFile = async () => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const data = new FormData();
      data.append("file", this.state.selectedFile);
      let res = await uploadFile(data, this.state.fileType);

      this.setState({
        fileNameOrSuccess: res.data.msg,
        loading: false,
        selectedFile: null,
      });
      await this.getLatestFile();
      this.setState({
        loading: false,
      });
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          errorMsg: error.response.data.message,
        });
      } else {
        this.setState({
          loading: false,
          errorMsg: "Something went terribly wrong!",
        });
      }
    }
  };
  saveSelectedKeys = async () => {
    try {
      this.setState({ saveBtnLoading: true });
      let payload: any = {};
      Object.keys(this.state.selectedCampaignKey).forEach((k) => {
        payload[k] = Object.keys(this.state.selectedCampaignKey[k]);
      });
      await saveCampignKeys(payload, this.state.latestFile.file.id);
      this.setState({ editAble: false });
      message.success("saved!");
    } catch (error) {
      message.error("");
    }
    this.setState({ saveBtnLoading: false });
  };
  submitSelectedKeys = async () => {
    try {
      this.setState({ submitBtnLoading: true });
      let payload: any = {};
      Object.keys(this.state.selectedCampaignKey).forEach((k) => {
        payload[k] = Object.keys(this.state.selectedCampaignKey[k]);
      });
      await saveCampignKeys(payload, this.state.latestFile.file.id);
      await submitCampignKeys({}, this.state.latestFile.file.id);

      this.setState({ editAble: false });
      message.success("saved!");
    } catch (error) {
      message.error("");
    }
    this.setState({ submitBtnLoading: false });
  };
  componentDidMount = async () => {
    try {
      await this.getLatestFile();
    } catch (error) {
      console.log(error);
    }
    this.setState({
      loading: false,
    });
  };
  getLatestFile = async () => {
    let data = await getLatestFileDetail();
    let selectedCampaignKey = data.file
      ? JSON.parse(data.file.selectedKeys || "{}")
      : {};
    for (let type in selectedCampaignKey) {
      let obj: any = {};
      selectedCampaignKey[type].forEach(
        (campignkey: any) => (obj[campignkey] = 1)
      );
      selectedCampaignKey[type] = obj;
    }

    this.setState({
      latestFile: data,
      selectedCampaignKey: {
        ...this.state.selectedCampaignKey,
        ...selectedCampaignKey,
      },
    });
  };
  render() {
    return (
      <div>
        <Row>
          <Col md="6">
            <StyledCard className="my-2">
              <CardHeader>
                <Radio.Group
                  onChange={this.handleRadioChange}
                  value={this.state.fileType}
                >
                  <Radio value={"keyword"}>Keywords</Radio>
                  <Radio value={"domain"}>Domain</Radio>
                </Radio.Group>
              </CardHeader>
              <CardBody>
                <div className="pb-4">
                  <span className="font-weight-bold">
                    {this.state.fileType === "keyword"
                      ? "Keywords Bulk Upload"
                      : "Domains Bulk Upload"}
                  </span>
                  <Button
                    color="white"
                    className="float-right shadow-none mt-1 p-0 dropdown-toggle"
                    size="sm"
                    onClick={() => {
                      this.setState({ fileDetail: !this.state.fileDetail });
                    }}
                  >
                    Show file format
                  </Button>
                </div>
                <label className="btn btn-light py-1 px-3">
                  <input
                    type="file"
                    className="filtTypeInput"
                    accept=".csv"
                    onChange={(event: any) => {
                      this.setState({
                        fileNameOrSuccess: event.target.files[0].name,
                        selectedFile: event.target.files[0],
                      });
                    }}
                  />
                  Add csv
                </label>
                <div className={this.state.fileDetail ? "d-block" : "d-none"}>
                  <ul className="mt-3">
                    <li>The File must be CSV format, limit 300,000 Rows</li>
                    <li>Firt row of the document must contain column header</li>
                    <li>Column 1: Campaign ID</li>
                    <li>
                      Column 2:{" "}
                      {this.state.fileType === "keyword" ? "Keyword" : "Domain"}
                    </li>
                    <li
                      className={
                        this.state.fileType === "domain" ? "d-none" : ""
                      }
                    >
                      {" "}
                      Column 3: existence
                    </li>
                  </ul>
                  <div className="text-info">
                    Then a table with sample data as follow:
                  </div>
                  <span>e.g</span>
                  <Table striped={true} bordered={true}>
                    <tbody>
                      <tr>
                        <td>Campaign Id</td>
                        <td>123123</td>
                        <td>112333</td>
                      </tr>
                      {this.state.fileType === "keyword" ? (
                        <tr>
                          <td>Keyword</td>
                          <td>data</td>
                          <td>marketing</td>
                        </tr>
                      ) : (
                          <tr>
                            <td>Domain</td>
                            <td>abc.com</td>
                            <td>google.com</td>
                          </tr>
                        )}

                      <tr
                        className={
                          this.state.fileType === "domain" ? "d-none" : ""
                        }
                      >
                        <td>existance</td>
                        <td>sample</td>
                        <td>sample</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {this.state.fileNameOrSuccess && (
                  <div className="my-2 rounded p-2 alert-success">
                    <span className="font-weight-bold">
                      {this.state.fileNameOrSuccess}
                    </span>
                  </div>
                )}
                {this.state.errorMsg && (
                  <div className="my-2 rounded p-2 alert-danger">
                    <span className="">{this.state.errorMsg}</span>
                  </div>
                )}

                <div className="mt-3">
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right mt-3"
                    disabled={!this.state.selectedFile ? true : false}
                    onClick={this.$uploadFile}
                  >
                    {this.state.loading ? (
                      <Spinner color="light" size="sm" />
                    ) : (
                        "Upload"
                      )}
                  </Button>
                  <Button
                    color="light"
                    size="sm"
                    className=" mt-3"
                    onClick={() => {
                      // csvToggle();
                      this.setState({
                        selectedFile: null,
                        fileNameOrSuccess: "",
                        errorMsg: "",
                        fileDetail: false,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </StyledCard>
            <h5 className="mb-2 mt-4">
              Updated On :{" "}
              <strong>
                {this.state.latestFile.latestSubmitFile
                  ? new Date(
                    this.state.latestFile.latestSubmitFile.createdAt
                  ).toLocaleString()
                  : ""}
              </strong>{" "}
            </h5>
          </Col>

          <Col md="6" className="mb-3">
            <AntTable
              style={{ maxHeight: '48vh', overflow: "auto" }}
              pagination={{ pageSize: 50 }}
              loading={this.state.loading}
              dataSource={this.state.latestFile.detail}
              columns={this.antcolumns}
              className="shadow-sm"
            />
            <div style={{ textAlign: "end" }} className="mt-2">
              <Button
                onClick={async () => {
                  await this.setState({ editAble: !this.state.editAble });
                }}
                color="secondary"
                size="sm"
              >
                Edit
              </Button>
              <Button color="primary" size="sm" onClick={this.saveSelectedKeys}>
                {this.state.saveBtnLoading ? (
                  <Spinner color="light" size="sm" />
                ) : (
                    "Save"
                  )}
              </Button>

              <Button
                color="success"
                size="sm"
                onClick={this.submitSelectedKeys}
              >
                {this.state.submitBtnLoading ? (
                  <Spinner color="light" size="sm" />
                ) : (
                    "Submit"
                  )}
              </Button>
            </div>
          </Col>
        </Row>

        {/* <Row className="justify-content-end mt-4">
          <Button color="primary" size="sm">
            Save
          </Button>
        </Row> */}
      </div>
    );
  }
}

export default KeywordsDomain;
