import axios from "./axios-configured";

export const createSubAdmin = async (payload: any) => {
  return axios.post("/sub-admin", payload);
};

export const updateSubAdmin = async (payload: any) => {
  const data = {
    email: payload.email,
    password: payload.password,
  };
  return axios.put(`/sub-admin/${payload.id}`, data);
};
