import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCampaignKeys } from "../../api/target-account";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  Spinner,
} from "reactstrap";
import { message, Tree, TreeSelect } from "antd";
import Select from "react-select";
import {
  campaignSetup,
  updateTargetAccount,
  fetchKeywordFiles,
} from "../../api/campaignFiles";
import { jobFuntions } from "../../data/job-functions";
import { withRouter } from "react-router-dom";
import { getSetupCampaignEdit } from "../../api/campaignFiles";
import styled from "styled-components";
// import Select from "react-select";

const { SHOW_PARENT } = TreeSelect;
const StyledCard = styled(Card)``;
class CampaignSetup extends Component<any, any> {
  state: any = {
    jobFunctions: [],
    campaignId: "",
    idForEidtt: NaN,
    keys: [],
    isProcessing: false,
  };

  onCheck = (key: any, checkedKeys: any) =>
    this.setState({ [key]: checkedKeys });

  handleSelection = (key: any, value: any) => {
    if (value) {
      this.setState({ [key]: `${value.value}` });
    }
  };

  handleSubmit = async () => {
    try {
      await this.setState({ isProcessing: true });
      let payload: any = {
        campaignId: this.state.campaignId,
        jobFunctions: this.state.jobFunctions,
      };
      await campaignSetup(payload);
      await this.setState({ isProcessing: false });
      this.props.history.push("/campaigns");
      message.success("Successfully created!");
    } catch (error) {
      await this.setState({ isProcessing: false });
      console.log(error);
    }
  };

  $fetchCampaignKeys = async () => {
    try {
      let res = await fetchCampaignKeys("campaign");
      await this.setState({ keys: res.data });
    } catch (error) {
      console.log("Oops, error while fetching target files!");
      message.error("Error while fetching campaignKeys");
    }
  };

  updated = async () => {
    try {
      await this.setState({ isProcessing: true });
      let payload = {
        jobFunctions: this.state.jobFunctions,
        campaignId: this.state.campaignId,
      };
      await updateTargetAccount({
        payload,
        id: this.state.idForEidt,
      });
      await this.setState({ isProcessing: false });
      this.props.history.push("/campaigns");
      message.success("Successfully updated!");
    } catch (error) {
      await this.setState({ isProcessing: false });
      message.success("Oops, error while updating!");
    }
  };

  /* @Suleman below function is not in use, please remove this if its needed any more*/
  $fetchKeywordFiles = async () => {
    let res = await fetchKeywordFiles();
    let previouseKeywordsFiles = res.data.map((value: any) => {
      return { label: value.fileName, value: value.id };
    });
    this.setState({ previouseKeywordsFiles });
  };

  getEditDetails = async () => {
    try {
      let res = await getSetupCampaignEdit(this.state.idForEidt);

      let resp = JSON.parse(res.data[0].setupDetail);
      if (res.data.length) {
        let options = { ...resp.campaignId };
        this.setState({ campaignId: options, jobFunctions: resp.jobFunctions });
      } else {
        this.setState({ campaignId: [], jobFunctions: [] });
      }
    } catch (error) {}
  };

  componentDidMount = async () => {
    let id = this.props.match.params.setupId;
    if (id) {
      await this.setState({ idForEidt: parseInt(id) });
      await this.getEditDetails();
    }
    await this.$fetchCampaignKeys();
  };

  render() {
    return (
      <>
        <Row>
          <Col md="12">
            <h6 className="mt-3 font-weight-bold">Campaign Form</h6>
          </Col>
        </Row>
        <Row>
          {/* <Col md="6">
            <StyledCard className="my-2">
              <CardHeader>
                <p className="font-weight-500 mb-0"> Job Function</p>
              </CardHeader>
              <CardBody>
                <Tree
                  style={{
                    minHeight: "250px",
                    maxHeight: "250px",
                    overflow: "auto",
                  }}
                  checkedKeys={this.state.jobFunctions}
                  checkable
                  onCheck={(checkedKeys: any) => {
                    this.onCheck("jobFunctions", checkedKeys);
                  }}
                  treeData={jobFuntions}
                />
                <br />
                <TreeSelect
                  {...{
                    treeData: jobFuntions,
                    allowClear: true,
                    value: this.state.jobFunctions,
                    onChange: (checkedKeys: any) => {
                      this.onCheck("jobFunctions", checkedKeys);
                    },
                    treeCheckable: true,
                    showCheckedStrategy: SHOW_PARENT,
                    placeholder: "Please select",
                    style: {
                      width: "100%",
                    },
                  }}
                />
              </CardBody>
            </StyledCard>
          </Col> */}
          <Col md="6">
            <StyledCard className="my-2">
              <CardBody>
                <Label>Campaign Id</Label>
                {/* <AntSelect
                  showSearch
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    this.handleSelection("campaignId", value);
                  }}
                value={this.state.campaignId}
                >
                  {this.state.keys.map((item: any, index: number) => {
                    return (
                      <AntSelect.Option
                        key={`campaign_key_${index}`}
                        value={`${item.id}`}
                      >
                        {parseInt(item.key)}
                      </AntSelect.Option>
                    );
                  })}
                </AntSelect>
                 */}
                <Select
                  isMulti={false}
                  isClearable={true}
                  menuPlacement="auto"
                  onChange={(value: any) => {
                    this.handleSelection("campaignId", value);
                  }}
                  options={this.state.keys.map((item: any, index: number) => {
                    return { label: item.key, value: item.id };
                  })}
                />
              </CardBody>
            </StyledCard>
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Button
            className="mx-3"
            size="sm"
            color="primary"
            disabled={this.state.isProcessing || !this.state.campaignId}
            onClick={() => {
              if (this.state.idForEidt) {
                this.updated();
              } else {
                this.handleSubmit();
              }
            }}
          >
            {this.state.isProcessing ? (
              <Spinner color="light" size="sm" />
            ) : this.state.idForEidt ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(CampaignSetup));
