import axios from "./axios-configured";

interface CompanyPayload {
  name: string;
}

/* Need to remove */
export const addCompany = async (payload: CompanyPayload) => {
  return axios.post("/add/company", payload);
};

export const fetchCompany = async (payload: any) => {
  return axios.get(`/company/${payload.companyId}`);
};

export const editComapnyName = async (payload: any) => {
  return axios.put(`/update/company/${payload.data.id}`, {
    name: payload.data.companyName,
    companyFileKeys: payload.data.companyFileKeys,
    advertiserIds: payload.data.advertiserIds,
    accountId: payload.data.accountId,
  });
};

export const getCompanies = async (query: any) => {
  return axios.get(`/companies/list?text=${query.text}`);
};

export const getCompanyKeys = async () => {
  return axios.get(`/campaignKeys`);
};

export const deleteComany = async (id: any) => {
  return axios.delete(`/company/${id}`);
};

export const loginAscompany = async ({
  companyId,
}: {
  companyId: number | string;
}) => {
  return axios.get(`/retrieve/superuser/token/${companyId}`);
};
