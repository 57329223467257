import React from "react";
import { Tooltip, Popover } from "antd";
import { TAConstants } from "../targetAccountConstants";

const remainingItmes = (data: any) => (
  <ul
    style={{
      overflowY: "auto",
      maxHeight: "150px",
      listStyleType: "none",
      padding: 0,
    }}
  >
    {data &&
      data.map((item: any, index: number) => {
        return (
          <li className="text-capitalize" key={`remaining_${index}`}>
            {item.loc} ({item.count})
          </li>
        );
      })}
  </ul>
);

const TopLocations = ({ data }: { data: any }) => {
  data && data.sort((a: any, b: any) => {
    return b.count - a.count
  })
  return (
    <td>
      {data &&
        data
          .slice(0, TAConstants.MAX_TD_ROWS)
          .map((item: any, index: number) => (
            <Tooltip
              key={index}
              title={<span className="text-capitalize">{item.loc} ({item.count})</span>}
            >
              <div className="d-flex">
                <small
                  className="text-muted text-capitalize text-truncate"
                  style={{ width: "90%" }}
                >
                  {item.loc} ({item.count})
                </small>
              </div>
            </Tooltip>
          ))}

      {data && data.length > TAConstants.MAX_TD_ROWS && (
        <Popover content={remainingItmes(data)}>
          <small
            className="d-inline-block text-truncate"
            style={{ maxWidth: "120px", color: "#007bff", cursor: "cursor" }}
          >
            View All {data.length}
          </small>
        </Popover>
      )}
    </td>
  );
};

export default TopLocations;
