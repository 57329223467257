import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Table,
  ModalFooter,
  Spinner
} from "reactstrap";
import { campaignNameUploadFile } from "../../api/campaign-setup";
import { connect } from "react-redux";

interface Props {
  csvModal: Boolean | any;
  csvToggle: Function | any;
}

interface State {
  fileDetail: Boolean;
  fileNameOrSuccess: String;
  selectedFile: any;
  loading: Boolean;
  errorMsg: String;
}
class CampaignNameModal extends Component<Props, State> {
  state: State = {
    fileDetail: false,
    fileNameOrSuccess: "",
    selectedFile: null,
    loading: false,
    errorMsg: ""
  };

  $uploadFile = async () => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const data = new FormData();
      data.append("file", this.state.selectedFile);
      let res = await campaignNameUploadFile(data);
      this.setState({
        fileNameOrSuccess: res.data.msg,
        loading: false,
        selectedFile: null
      });
     
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          errorMsg: error.response.data.message
        });
      } else {
        this.setState({
          loading: false,
          errorMsg: "Something went terribly wrong!"
        });
      }
    }
  };
  render() {
    let { csvModal, csvToggle } = this.props;
    return (
      <div>
        {/* change permission modal */}
        <Modal
          isOpen={csvModal}
          toggle={csvToggle}
          className="modal-dialog-centered"
        >
          <ModalBody>
            <div className="pb-4">
              <span className="font-weight-bold">
                Upload Campaign Name Csv File
              </span>
              <Button
                color="white"
                className="float-right shadow-none mt-1 p-0 dropdown-toggle"
                size="sm"
                onClick={() => {
                  this.setState({ fileDetail: !this.state.fileDetail });
                }}
              >
                Show file format
              </Button>
            </div>
            <label className="btn btn-light py-1 px-3">
              <input
                type="file"
                className="filtTypeInput"
                accept=".csv"
                onChange={(event: any) => {
                  this.setState({
                    fileNameOrSuccess: event.target.files[0].name,
                    selectedFile: event.target.files[0]
                  });
                }}
              />
              Add csv
            </label>
            <div className={this.state.fileDetail ? "d-block" : "d-none"}>
              <ul className="mt-3">
                <li>The File must be CSV format, limit 2000/5 Million rows</li>
                <li>First row of the document must contain column header</li>
                <li>Column 1: campaign Key</li>
                <li>Column 2: Name</li>
              </ul>
              <span>e.g</span>
              <Table striped={true} bordered={true}>
                <tbody>
                  <tr>
                    <td>1731</td>
                    <td>Pre-Sale: Commvault - Partner Pull</td>
                  </tr>
                  <tr>
                    <td>1730</td>
                    <td>Oracle - Apps HCM - Incremental</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {this.state.fileNameOrSuccess && (
              <div className="my-2 rounded p-2 alert-success">
                <span className="font-weight-bold">
                  {this.state.fileNameOrSuccess}
                </span>
              </div>
            )}
            {this.state.errorMsg && (
              <div className="my-2 rounded p-2 alert-danger">
                <span className="">{this.state.errorMsg}</span>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
           
            <Button
              color="primary"
              size="sm"
              className="float-right mt-3"
              disabled={!this.state.selectedFile ? true : false}
              onClick={this.$uploadFile}
            >
              {this.state.loading ? (
                <Spinner color="light" size="sm" />
              ) : (
                "Upload"
              )}
            </Button>
            <Button
              color="light"
              size="sm"
              className=" mt-3"
              onClick={() => {
                csvToggle();
                this.setState({
                  selectedFile: null,
                  fileNameOrSuccess: "",
                  errorMsg: "",
                  fileDetail: false
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CampaignNameModal);
