import React, { Component } from "react";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";
import { connect } from "react-redux";

class Dashboard extends Component<any, any> {
  render() {
    try {
      if (
        this.props.auth.Role.name === "user" &&
        this.props.auth.Role.$permissions.publishers
      ) {
        return <Redirect to="/publishers"></Redirect>;
      } else if (this.props.auth.Role.$permissions.campaigns) {
        return <Redirect to="/campaigns"></Redirect>;
      } else {
        return <Redirect to="/setup/update/profile"></Redirect>;
      }
    } catch (err) {}

    return (
      <>
        {/* <Result
          icon={<SmileOutlined />}
          title="Dashboard is comming soon!"
          extra={""}
        /> */}
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Dashboard);
