import React from "react";
import { ToastContainer } from "react-toastify";

const RenderToast = () => (
  <>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
  </>
);

export default RenderToast;
