import React from "react";
import Login from "./views/Login/LoginForm";
import store from "./redux/Store";
import Setup from "./views/Setup/Setup";
import Users from "./views/Users/AllUsers";
import AdminLogin from "./views/Login/AdminLogin";
import Companies from "./views/Companies/Companies";
import PublicRoute from "./commons/PublicRoute";
import PrivateRoute from "./commons/PrivateRoute";
import UpdateProfile from "./views/Profile/UpdateProfile";
import CreateUser from "./views/Setup/CreateUser";
import CompleteProfile from "./views/Profile/CompleteProfile";
import MainCampainSetup from "./views/Setup/MainCampainSetup";
import NewAccountFile from "./views/AccountDiscovery/NewAccountFile";
import TargetAccountFiles from "./views/Target-account-files/TargetAccountFiles";
import EditAccountDiscoveryFile from "./views/AccountDiscovery/EditAccountDiscoveryFile";
import { Provider } from "react-redux";
import Campaigns from "./views/Campaigns/Campaigns";
import ViewFile from "./views/Campaigns/ViewFile";
import CheckOffline from "./commons/CheckOffline";
import TargetAccount from "./views/TargetAccount/TargetAccount";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import SetNewPassword from "./views/ForgotPassword/NewPassword";
import ViewTargetAccountFile from "./views/Target-account-files/ViewTargetAccountFile";
import AccountDiscoveryFiles from "./views/AccountDiscovery/AccountDiscoveryFiles";
import AllCampaignSettings from "./views/Campaigns/AllCampaignSettings";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./views/NotFound";
import ABM from "./views/ABM/ABM";
import BwCampaignStats from "./views/BwCampaignStats/BwCampaignStats";

/* Styles */
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "mdbreact/dist/css/mdb.css";
import "antd/dist/antd.css";
import "./theme.css";
import AccountDiscoveryViewTable from "./views/AccountDiscovery/AccountDiscoveryViewTable";
import CampaignSetupViewList from "./views/NewCampaignSetup/CampaignSetupViewList";
import CreateCampainSetup from "./views/NewCampaignSetup/CreateCampainSetup";
import AllPublishers from "./views/Publishers/AllPublisher";
import Dashboard from "./views/Dashboard";
import PublisherView from "./views/Publishers/PublisherView";
import LeadAppendDashboard from "./views/LeadAppendDashboard";
import BeeswaxFiles from "./views/Beeswax/Beeswax";
import ABMDetails from "./views/ABM/ABMDetails/ABMDetails";
import EngagementMetrics from "./views/ABM/ABMDetails/EngagementMetrics";
import Audience from "./views/Audience";
import BwCampaignStatsAdmin from "./views/BwCampaignStats/BwCampaignStatsAdmin";
import SubAdmin from "./views/SubAdmin/SubAdmin";
import ABMReports from "./views/ABM/Reports/ABMReports";
import ViewEmailAudienceFile from "./views/Target-account-files/ViewEmailAudienceFile";

class App extends React.Component<any, any> {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/admin/login" component={AdminLogin} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute
              exact
              path="/publisher/view/all"
              key={`publisher/view/all`}
              component={PublisherView}
            />
            {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
            {/* <PrivateRoute exact path="/campaigns" component={Campaigns} /> */}
            <PrivateRoute exact path="/setup" component={Setup} />
            <PrivateRoute exact path="/setup/companies" component={Companies} />
            <PrivateRoute exact path="/setup/users" component={Users} />
            <PrivateRoute
              exact
              path="/setup/create/sub-admin"
              component={SubAdmin}
            />
            <PublicRoute
              exact
              path="/forget/password"
              component={ForgotPassword}
            />
            <PublicRoute
              exact
              path="/update/password"
              component={SetNewPassword}
            />
            <PublicRoute
              exact
              path="/complete/profile"
              component={CompleteProfile}
            />
            <PrivateRoute
              exact
              path="/setup/create/user"
              component={CreateUser}
            />
            <PrivateRoute
              exact
              path="/audience-manger"
              component={TargetAccountFiles}
            />
            <PrivateRoute
              exact
              path="/audience-manger/:id/view-domains"
              component={ViewTargetAccountFile}
            />
            <PrivateRoute
              exact
              path="/audience-manger/:id/view-emails"
              component={ViewEmailAudienceFile}
            />
            {/* <PrivateRoute
              exact
              path="/campaigns/file/:id"
              component={ViewFile}
            /> */}
            <PrivateRoute exact path="/audience" component={Audience} />

            {/* <PrivateRoute
              exact
              path="/account-discovery"
              component={AccountDiscoveryFiles}
            />
            <PrivateRoute
              exact
              path="/account-discovery/setting"
              component={NewAccountFile}
            />
            <PrivateRoute
              exact
              path="/account-discovery/:id/edit"
              component={EditAccountDiscoveryFile}
            /> */}
            <PrivateRoute
              exact
              path="/setup/update/profile"
              component={UpdateProfile}
            />
            {/* <PrivateRoute
              exact
              path="/campaigns/create"
              component={MainCampainSetup}
            /> */}
            <PrivateRoute
              exact
              path="/campaigns/:id"
              component={TargetAccount}
            />
            <PrivateRoute
              exact
              key={`/publisher/view/:id`}
              path="/publisher/view/:id"
              component={PublisherView}
            />
            <PrivateRoute
              exact
              path="/complete/profile"
              component={CompleteProfile}
            />

            <PrivateRoute
              exact
              path="/campaigns/edit/:setupId"
              component={MainCampainSetup}
            />
            <PrivateRoute
              exact
              path="/campaigns"
              component={AllCampaignSettings}
            />
            <PrivateRoute exact path="/publishers" component={AllPublishers} />
            {/* <PrivateRoute
              exact
              path="/account-descovery/details/:id"
              component={AccountDiscoveryViewTable}
            /> */}
            <PrivateRoute
              exact
              path="/campaign-setup"
              component={CampaignSetupViewList}
            />
            {/* <PrivateRoute
              exact
              path="/dashboard"
              component={LeadAppendDashboard}
            /> */}
            <PrivateRoute
              exact
              path="/campaign-setup/create"
              component={CreateCampainSetup}
            />
            <PrivateRoute
              exact
              path="/campaign-setup/edit/:id"
              component={CreateCampainSetup}
            />
            <PrivateRoute exact path="/abm" component={ABM} />
            <PrivateRoute exact path="/abm-details" component={ABMDetails} />
            <PrivateRoute exact path="/abm/reports" component={ABMReports} />
            <PrivateRoute
              exact
              path="/campaign-stats"
              component={BwCampaignStats}
            />
            <PrivateRoute
              exact
              path="/full-campaign-stats"
              component={BwCampaignStatsAdmin}
            />
            <Route component={NotFound} />
          </Switch>
        </Router>
        <CheckOffline />
      </Provider>
    );
  }
}

export default App;
