import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_USER,
  SET_USER_AUTH,
  SET_PREVIOUSE_LOGIN_STATE,
  LOGIN_BACK_TO_PREVIOUS
} from "./types";
import { setAuthHeader } from "../../api/axios-configured";

let initialState = {
  id: NaN,
  token: window.localStorage.token,
  username: "",
  gender: "",
  companyId: NaN,
  email: "",
  Role: { $permissions: {} },
  previousLoginState: null
};

if (window.localStorage.user && window.localStorage.token) {
  let user = JSON.parse(window.localStorage.user);
  if (user) {
    initialState = { ...user };
    initialState.token = window.localStorage.token;
  }
}

if (window.localStorage.previousLoginState) {
  let previousLoginState = JSON.parse(window.localStorage.previousLoginState);
  if (previousLoginState) {
    initialState = { ...initialState, previousLoginState };
  }
}

const userAuth = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setAuthHeader(action.payload.token);
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        ...action.payload.user,
        token: action.payload.token
      };
    case SET_USER_AUTH:
      setAuthHeader(action.payload.token);
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        ...action.payload.user,
        token: action.payload.token
      };
    case SET_PREVIOUSE_LOGIN_STATE:
      localStorage.setItem(
        "previousLoginState",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        previousLoginState: { ...action.payload }
      };
    case LOGIN_BACK_TO_PREVIOUS:
      let previouseLogin = JSON.parse(localStorage.previousLoginState);
      let previousLoginState = JSON.parse(localStorage.previousLoginState);
      localStorage.setItem("token", previouseLogin.token);
      setAuthHeader(previouseLogin.token);
      delete previouseLogin.token;
      localStorage.setItem("user", JSON.stringify(previouseLogin));
      localStorage.removeItem("previousLoginState");
      return {
        ...state,
        ...previousLoginState,
        token: previousLoginState.token,
        previousLoginState: null
      };
    case LOGOUT_USER:
      return {
        id: NaN,
        token: "",
        username: "",
        gender: "",
        companyId: NaN,
        email: "",
        Role: { $permissions: {} },
        previousLoginState: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default userAuth;
