import React, { Component } from "react";
import { Nav, NavItem } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Submenu extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      subTabs: [],
    };
  }

  compare(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  //  Will convert in didMount hook after testing
  UNSAFE_componentWillMount = async () => {
    let {
      auth: {
        Role: { name: role, $permissions, isSubAdmin },
      },
    } = this.props;
    let hasCreateUserOption = role === "admin" ? true : false;
    // if (role === "admin") {
    //   this.props.history.push("/setup/create/user");
    // } else {
    //   this.props.history.push("/setup/update/profile");
    // }
    let tabs = [];
    if (role !== "admin") {
      tabs.push({ href: "/setup/update/profile", order: 4, title: "Profile" });
    }
    if (role === "admin" && !isSubAdmin)
      tabs.push({
        href: "/setup/create/sub-admin",
        order: 5,
        title: "Associate Admin",
      });
    for (let permissionType in $permissions) {
      if (permissionType === "subAccounts") {
        tabs.push({ href: "/setup/users", order: 2, title: "Users" });
      }
      if (
        (permissionType === "subAccounts" || permissionType === "company") &&
        $permissions[permissionType].global === "CRUD"
      ) {
        if (!hasCreateUserOption) hasCreateUserOption = true;
      }
      if (permissionType === "company") {
        tabs.push({ href: "/setup/companies", order: 3, title: "Companies" });
      }
    }
    if (hasCreateUserOption) {
      tabs.push({
        href: "/setup/create/user",
        order: 1,
        title: "Create User",
      });
    }
    tabs = tabs.sort(this.compare);
    await this.setState({ subTabs: [...tabs] });
    const redirectUrl = hasCreateUserOption
      ? "/setup/create/user"
      : "/setup/update/profile";
    this.props.history.push(redirectUrl);
  };

  render() {
    let {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div>
        <Nav pills className="p-0 bg-gray">
          {this.state.subTabs.map((subTab: any, index: any) => (
            <Link
              key={`submenu_${index}`}
              to={subTab.href}
              className={`text-decoration-none text-dark border-0 shadow-none ${
                pathname === subTab.href && "active-subtab"
              }`}
            >
              <NavItem className={`py-3 px-4`}>{subTab.title}</NavItem>
            </Link>
          ))}
        </Nav>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Submenu));
