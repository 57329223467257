const staticDefaultValues = {
  campaignKeys: [],
  unifiedScore: { min: 0, max: 0 },
  rank: { min: 0, max: 0 },
  fractionScoreNumerator: { min: 0, max: 0 },
  tier: [],
  surgingTopicListCurrentWeek: [],
  surgingLocations: [],
  totalSurgingTopics: { min: 0, max: 0 },
  l1TrendingTopics: [],
  topKeywordsCurrentMonthCount: { min: 0, max: 0 },
  topKeywordsCurrentWeekCount: { min: 0, max: 0 },
  weeklySiteVisitorUniqueCurrent: { min: 0, max: 0 },
  monthlySiteVisitorUniqueCurrent: { min: 0, max: 0 },
  topKeywordsCurrentWeek: [],
  topKeywordsTwoMonthCount: {
    min: 0,
    max: 0,
  },
  biMonthlySiteVisitorUnique: {
    min: 0,
    max: 0,
  },
  l1TrendingTopicsCountCurrentWeek: { min: 0, max: 0 },
  bomboraTopics: [],
  domains: [],
  weeklyClicksCurrent: {
    min: 0,
    max: 0,
  },
  monthlyClicksCurrent: {
    min: 0,
    max: 0,
  },
  bimonthlyClicksCurrent: {
    min: 0,
    max: 0,
  },
  weeklyImpressionsCurrent: {
    min: 0,
    max: 0,
  },
  monthlyImpressionsCurrent: {
    min: 0,
    max: 0,
  },
  bimonthlyImpressionsCurrent: {
    min: 0,
    max: 0,
  },
  weeklyLeadsCurrent: {
    min: 0,
    max: 0,
  },
  monthlyLeadsCurrent: {
    min: 0,
    max: 0,
  },
  bimonthlyLeadsCurrent: {
    min: 0,
    max: 0,
  },
};

export default staticDefaultValues;
