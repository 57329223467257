import React from "react";
import { getSiteVisitorMetric } from "../../../api/abm-stats";
import moment from "moment";
import CustomAntTable from "../../../commons/CustomAntTable";
import InfluencedMetricsModal from "./InfluencedMetricsModal";

const antColumns = [
  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
  },
  {
    title: "Unique Visitors Current",
    dataIndex: "visitorscur",
    key: "visitorscur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Unique Visitors Previous",
    dataIndex: "visitorsprev",
    key: "visitorsprev",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Unique Visitors Delta",
    dataIndex: "visitorsdelta",
    key: "visitorsdelta",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Page Views Current",
    dataIndex: "pgviewcur",
    key: "pgviewcur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Page Views Previous",
    dataIndex: "pgviewprev",
    key: "pgviewprev",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Page Views Delta",
    dataIndex: "pgviewdelta",
    key: "pgviewdelta",
    sorter: true,
    sortOrder: false,
  },
];

const EngagementMetrics = ({
  tableData,
  setTableData,
  changeLoadingHandler,
  campaigns,
  loading,
  filters,
  filterChangeHandler,
}: any) => {
  const [columns, setColumns] = React.useState(antColumns);
  const [selectedDomain, setSelectedDomain] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    selectedDomain ? setOpen(true) : setOpen(false);
  }, [selectedDomain]);

  const fetchData = async () => {
    try {
      changeLoadingHandler("tableData", true);
      // const inputData = formatSiteVisitorMetrics();
      let startDate = "";
      let endDate = "";
      if (filters.range) {
        const range = filters.range.split(",");
        startDate = range[0];
        endDate = range[1];
      } else {
        startDate = moment()
          .subtract(filters.dayspan, "day")
          .format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }
      const filterObj = {
        ...filters,
        campaignid: filters.campaignid
          ? filters.campaignid
          : campaigns?.[0]?.CampaignKey?.key
          ? campaigns?.[0]?.CampaignKey?.key
          : "-1",
        startdateyyyymmdd: startDate,
        enddateyyyymmdd: endDate,
      };
      const res: any = await getSiteVisitorMetric(filterObj);
      const row: any = res?.data?.[0]?.result?.Row;
      const data = row.map((item: any) => {
        return {
          domain: item.domain,
          visitorscur: item.visitorscur,
          visitorsprev: item.visitorsprev,
          visitorsdelta: item.visitorsdelta,
          infpgviewcur: item.infpgviewcur,
          infpgviewdelta: item.infpgviewdelta,
          infpgviewprev: item.infpgviewprev,
          infvisitorscur: item.infvisitorscur,
          infvisitorsdelta: item.infvisitorsdelta,
          infvisitorsprev: item.infvisitorsprev,
          pgviewcur: item.pgviewcur,
          pgviewprev: item.pgviewprev,
          pgviewdelta: item.pgviewdelta,
        };
      });
      setTableData(data ?? []);
      changeLoadingHandler("tableData", false);
    } catch (error) {
      changeLoadingHandler("tableData", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchData();
    }
  }, [campaigns, filters]);

  return (
    <>
      <CustomAntTable
        columns={columns}
        setColumns={setColumns}
        tableData={tableData}
        loading={loading}
        filterChangeHandler={filterChangeHandler}
        filters={filters}
      />
      {open ? (
        <InfluencedMetricsModal
          isModalVisible={open}
          handleCancel={() => setSelectedDomain(null)}
          campaigns={campaigns}
          filters={filters}
          selectedDomain={selectedDomain}
        />
      ) : null}
    </>
  );
};

export default EngagementMetrics;
