import * as types from "./types";
export const fetchUserList = (
  payload: any,
  onSuccess: Function,
  onFailure: Function
) => {
  return {
    type: types.FACTCH_USERS,
    payload: payload,
    meta: {
      onSuccess,
      onFailure
    }
  };
};
