import React from "react";
import DeleteImg from "../../assest/images/x.png";
import classes from "./styles/GeneralAudienceModal.module.css";

const PageTrackUrl = ({ urlItem, filter, urlRemoveHandler }: any) => {
  return (
    <div className={classes.urlContainer}>
      {urlItem.url}
      <div
        className={classes.urlImgContainer}
        onClick={() => urlRemoveHandler({ ...urlItem, filter })}
      >
        <img src={DeleteImg} alt="delete" className={classes.urlImg} />
      </div>
    </div>
  );
};

export default PageTrackUrl;
