import { message, Table, Spin, Input } from "antd";
import React from "react";
import CreateAudienceModal from "./CreateAudienceModal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import classes from "./styles/Audience.module.css";
import {
  getAudienceList,
  modifyAudienceDbData,
  getInProcessRecordsStatus,
  refreshAudienceData,
} from "../../api/audience";
import { Popconfirm } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import moment from "moment";
const { confirm } = Modal;
const antColumns = [
  {
    title: "Segment",
    dataIndex: "segmentName",
    key: "segmentName",
  },
  {
    title: "Total Domains",
    dataIndex: "inputdomainscount",
    key: "inputdomainscount",
  },
  {
    title: "Domains with Coverage",
    dataIndex: "domainswitheithercookiesorip",
    key: "domainswitheithercookiesorip",
  },
  {
    title: "Coverage (%)",
    dataIndex: "coveragePercentage",
    key: "coveragePercentage",
  },
  {
    title: "Users Count",
    dataIndex: "cookiesCount",
    key: "cookiesCount",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];

const Audience = () => {
  const [createAudienceModal, setCreateAudienceModal] = React.useState(false);
  const [originalData, setOriginalData] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState({
    beeswaxFiles: false,
    createAudience: false,
    fetchAudience: false,
    execAudience: false,
    refreshAudience: false,
  });
  const [refetch, setRefetch] = React.useState(false);
  const [editObj, setEditObj] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [activeId, setActiveId] = React.useState(null);

  const refetchHandler = () => {
    setRefetch(!refetch);
  };

  const audienceToggle = (e: any) => {
    e.preventDefault();
    setCreateAudienceModal(!createAudienceModal);
  };

  const loadingHandler = (key: any, val: any) => {
    setLoading({ ...loading, [key]: val });
  };

  const handleCancel = () => {
    setEditObj({});
    setCreateAudienceModal(false);
  };

  const handleExecAudience = async () => {
    try {
      loadingHandler("fetchAudience", true);

      await modifyAudienceDbData(activeId);
      loadingHandler("fetchAudience", false);
      setActiveId(null);
      setVisible(false);
      refetchHandler();
    } catch (error) {
      loadingHandler("fetchAudience", false);

      console.log({ error });
      message.error("Something went wrong");
    }
  };

  const getStatus = (status: any) => {
    if (status !== "completed" && status !== "pending" && status !== "error")
      return "processing";
    return status;
  };

  const getColor = (status: any) => {
    if (status === "completed") return "#00c851";
    else if (status === "error") return "#d9534f";
    else if (status === "pending") return "#055697";
    else return "#33b5e5";
  };

  React.useEffect(() => {
    setTableData(originalData);
  }, [originalData]);

  React.useEffect(() => {
    if (activeId) setVisible(true);
  }, [activeId]);
  const handleConfirmRefresh = async (audienceId: any) => {
    confirm({
      title: "Refresh Audience",
      icon: <ExclamationCircleOutlined />,
      content: "Do you Want to refresh this audience?",
      async onOk() {
        await refreshAudienceHandler(audienceId);
      },
    });
  };
  const refreshAudienceHandler = async (audienceId: any) => {
    try {
      loadingHandler("refreshAudience", true);
      await refreshAudienceData(audienceId);
      loadingHandler("refreshAudience", false);
      refetchHandler();
    } catch (error) {
      loadingHandler("refreshAudience", false);
      console.log({ error });
    }
  };

  const fetchAudience = async () => {
    try {
      loadingHandler("fetchAudience", true);
      const res: any = await getAudienceList();
      const today = moment();
      const data: any = res?.data?.map((item: any) => {
        const dateofvisit = moment(moment(item?.updatedAt), "DD-MM-YYYY");
        if (item.id === 117) {
          console.log({ dateofvisit });
          console.log({ today });
        }
        const dateDiff = 90 - today.diff(dateofvisit, "days");
        return {
          id: item.id,
          name: item.segmentName,
          ipSegmentId: item.IPSegmentId,
          bidSegmentId: item.bidSegmentId,
          statStatus: item.statStatus,
          createdAt: item.createdAt,
          segmentName: (
            <div>
              <div>{item.segmentName}</div>
              <div>
                Created At: {new Date(item.createdAt).toLocaleString() || ""}
              </div>
              <div>
                Updated At: {new Date(item.updatedAt).toLocaleString() || ""}
              </div>
              {item?.segment_status ? (
                <>
                  <div>
                    Status:{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        color: `${getColor(item.segment_status)}`,
                      }}
                    >
                      {getStatus(item.segment_status)}
                    </span>
                  </div>
                  <div>
                    {dateDiff <= 0
                      ? "Expired"
                      : `To Expire: ${dateDiff} ${
                          dateDiff === 1 ? "day" : "days"
                        }`}
                  </div>
                </>
              ) : null}
            </div>
          ),
          inputdomainscount:
            item?.output?.inputdomainscount?.toLocaleString() ?? <Spin />,
          domainswitheithercookiesorip:
            item?.output?.domainswitheithercookiesorip?.toLocaleString() ?? (
              <Spin />
            ),
          coveragePercentage:
            item?.output?.domainswitheithercookiesorip &&
            item?.output?.inputdomainscount ? (
              parseFloat(
                (
                  (item?.output?.domainswitheithercookiesorip /
                    item?.output?.inputdomainscount) *
                  100
                ).toFixed(2)
              )
            ) : item.statStatus === "completed" ? (
              0
            ) : (
              <Spin />
            ),
          cookiesCount: item?.usersCount?.toLocaleString() ?? <Spin />,
          actions: (
            <div>
              {/* <button className="btn btn-success btn-sm ">View</button> */}

              <button
                className="btn btn-success btn-sm "
                disabled={item?.segment_status ? true : false}
                onClick={() => setActiveId(item.id)}
              >
                Run
              </button>

              <button
                className="btn btn-success btn-sm "
                onClick={() => setEditObj(item)}
              >
                {item?.segment_status ? "View" : "Edit"}
              </button>
              <button
                className="btn btn-success btn-sm "
                disabled={item?.segment_status === "completed" ? false : true}
                onClick={() => handleConfirmRefresh(item.id)}
              >
                Refresh
              </button>
            </div>
          ),
        };
      });
      setOriginalData(data ?? []);
      await fetchProcessingRecords(data ?? []);
      loadingHandler("fetchAudience", false);
    } catch (error) {
      loadingHandler("fetchAudience", false);
      console.log({ error });
      message.error("Oops,something went wrong!");
    }
  };

  const getInProcessIds = (data: any) => {
    const ids = [];
    for (const item of data) {
      if (item.statStatus === "pending") ids.push(item.id);
    }
    return ids;
  };

  const updateProcessingRecords = (obj: any, tempData: any) => {
    let isUpdatable = false;
    for (const key of Object.keys(obj)) {
      if (!isUpdatable) isUpdatable = true;
      if (obj[key]) {
        const index = tempData.findIndex(
          (item: any) => item.id === parseInt(key)
        );
        if (index === -1) continue;
        tempData[index] = {
          ...tempData[index],
          statStatus: obj[key]?.statStatus,
          inputdomainscount:
            obj[key]?.output?.inputdomainscount?.toLocaleString() ?? 0,
          domainswitheithercookiesorip:
            obj[key]?.output?.domainswitheithercookiesorip?.toLocaleString() ??
            0,
          coveragePercentage:
            obj[key]?.output?.domainswitheithercookiesorip &&
            obj[key]?.output?.inputdomainscount
              ? parseFloat(
                  (
                    (obj[key]?.output?.domainswitheithercookiesorip /
                      obj[key]?.output?.inputdomainscount) *
                    100
                  ).toFixed(2)
                )
              : 0,
          cookiesCount: obj[key]?.usersCount?.toLocaleString() ?? 0,
        };
      }
    }
    if (isUpdatable) setOriginalData([...tempData]);
    return tempData;
  };

  const fetchProcessingRecords = async (data: any) => {
    try {
      if (!data.length) return;
      const ids: any = getInProcessIds(data);
      if (!ids.length) return;
      const res = await getInProcessRecordsStatus(ids);
      const newData = updateProcessingRecords(res.data, data);
      setInterval(async () => {
        await fetchProcessingRecords(newData);
      }, 10000);
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    fetchAudience();
  }, [refetch]);

  React.useEffect(() => {
    if (Object.keys(editObj).length) setCreateAudienceModal(true);
  }, [editObj]);

  const setFilteredProductsHandler = (value: any) => {
    if (!value) {
      setTableData(originalData);
      return;
    }
    if (isNaN(value)) {
      const products = originalData.filter((item: any) =>
        item.name.trim().toLowerCase().includes(value.trim().toLowerCase())
      );
      setTableData(products);
      return;
    }
    const number = parseInt(value);
    if (isNaN(number)) setTableData([]);
    const products = originalData.filter(
      (item: any) => item.bidSegmentId === number || item.ipSegmentId === number
    );
    setTableData(products);
  };

  return (
    <>
      <div className={classes.headContainer}>
        <Input
          style={{ width: "200px", marginRight: "10px" }}
          placeholder="Search"
          onChange={({ target: { value } }) =>
            setFilteredProductsHandler(value)
          }
        />
        <button
          className="btn btn-success btn-sm float-right"
          onClick={audienceToggle}
        >
          Create
        </button>
      </div>
      <Table
        columns={antColumns}
        dataSource={tableData}
        loading={loading.fetchAudience}
      />
      {createAudienceModal && (
        <CreateAudienceModal
          handleCancel={handleCancel}
          isModalVisible={createAudienceModal}
          setCreateAudienceModal={setCreateAudienceModal}
          refetchHandler={refetchHandler}
          editObj={editObj}
          setEditObj={setEditObj}
        />
      )}
      <SweetAlert
        warning
        customButtons={
          <>
            <Button
              color="info"
              size="md"
              onClick={() => {
                setActiveId(null);
                setVisible(false);
              }}
            >
              Dismiss
            </Button>
            <Button color="info" size="md" onClick={() => handleExecAudience()}>
              Create
            </Button>
          </>
        }
        show={visible}
        title="Are you sure you want to create segment?"
        onConfirm={() => {}}
        onCancel={() => {
          setActiveId(null);
          setVisible(false);
        }}
        focusCancelBtn
      />
    </>
  );
};

export default Audience;
