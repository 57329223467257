export default [
  
  {
    label: "Name",
    field: "Username",
    sort: "asc"
  },
  {
    label: "Email",
    field: "email",
    sort: "asc"
  },
  {
    label: "Company Name",
    field: "companyName",
    sort: "asc"
  },
  {
    label: "Created Date",
    field: "createdAt",
    sort: "asc"
  },
  {
    label: "Action",
    field: "action",
    sort: "asc"
  }
];
