import React, {  useEffect, useState } from "react";
import TopicsSubTable from "../views/TargetAccount/TopicsSubTable";
import { Tabs, Modal } from "antd";
import KeywordsSubTable from "../views/TargetAccount/KeywordsSubTable";
import SiteVisitorTable from "../views/TargetAccount/SiteVisitor";
import TopLocationSubTable from "../views/TargetAccount/TopLocationsSubTable";
import AberdeenSubTable from "../views/TargetAccount/AberdeenSubTable";
import TechTargetSubTable from "../views/TargetAccount/TechTargetSubTable";
import DigitalSubTable from "../views/TargetAccount/DigitalSubTable";
import BomboraSubTable from "../views/TargetAccount/BomboraSubTable";
import LeadSiftSubTable from "../views/TargetAccount/LeadSiftSubTable";
import L1SubTable from "../views/TargetAccount/L1SubTable";
import "../views/TargetAccount/TargetAccount.css";
import {
  isSiteVisitorTabShow,
  isDigitalTabShow,
  isTopLocationTabShow,
} from "../utility/campaign-view";

export default function DomainDetail({
  item,
  isVisible,
  campaignKey,
  visiblEvent,
}: {
  item: any;
  isVisible: boolean;
  campaignKey: string;
  visiblEvent:any
}) {
  const defaultItem: any = {};
  const [selectedItem, setSelectedItem] = useState(defaultItem);

  useEffect(() => {
    const selectedItem = {
      leadAppendDetailKeywordJson: JSON.parse(
        item.leadAppendDetailKeywordJson || "[]"
      ),
      leadAppendDetailLocationJson: JSON.parse(
        item.leadAppendDetailLocationJson || "[]"
      ),
      leadAppendDetailTopicJson: JSON.parse(
        item.leadAppendDetailTopicJson || "[]"
      ),
      leadAppendDetailBomboraTopics: JSON.parse(
        item.leadAppendDetailBomboraTopicJson || "[]"
      ),
      LeadAppendDetailL1TrendingTopics: JSON.parse(
        item.leadAppendDetailL1TrendingTopicJson || "[]"
      ),
      leadAppendDetailAberdeenKeywords: JSON.parse(
        item.leadAppendDetailAberdeenJson || "[]"
      ),
      leadAppendDetailTechTargetKeywords: JSON.parse(
        item.leadAppendDetailTechTargetJson || "[]"
      ),
      leadAppendDetailDeltum: item.LeadAppendDetailDeltum
        ? item.LeadAppendDetailDeltum
        : {},
    };
    setSelectedItem({ ...item, ...selectedItem });
  }, [item]);
  
  return (
    <Modal
      title={selectedItem.domain}
      centered
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={() => visiblEvent(false)}
      visible={isVisible}
      width={1000}
    >
      <Tabs defaultActiveKey={"1"} onChange={() => {}}>
        {selectedItem.leadAppendDetailTopicJson &&
        selectedItem.leadAppendDetailTopicJson.length ? (
          <Tabs.TabPane tab={"Topics"} key={"1"}>
            <TopicsSubTable data={selectedItem.leadAppendDetailTopicJson} />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {selectedItem.leadAppendDetailKeywordJson &&
        selectedItem.leadAppendDetailKeywordJson.length ? (
          <Tabs.TabPane tab={"Keywords"} key={"2"}>
            <KeywordsSubTable data={selectedItem.leadAppendDetailKeywordJson} />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {selectedItem.LeadAppendDetailL1TrendingTopics &&
        selectedItem.LeadAppendDetailL1TrendingTopics.length ? (
          <Tabs.TabPane tab={"HVC (L1 Topics)"} key={"3"}>
            <L1SubTable
              data={{
                LeadAppendDetailL1TrendingTopics:
                  selectedItem.LeadAppendDetailL1TrendingTopics,
                l1TrendingTopicsCountPreviousWeek:
                  selectedItem.l1TrendingTopicsCountPreviousWeek,
                l1TrendingTopicsCountCurrentWeek:
                  selectedItem.l1TrendingTopicsCountCurrentWeek,
              }}
            />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {isSiteVisitorTabShow(selectedItem) ? (
          <Tabs.TabPane tab={"Site Visitor"} key={"4"}>
            <SiteVisitorTable data={selectedItem} />
          </Tabs.TabPane>
        ) : (
          ""
        )}

        {selectedItem.leadsifttriggerkeyword ? (
          <Tabs.TabPane tab={"Lead Sift"} key={"5"}>
            <LeadSiftSubTable data={selectedItem} />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {selectedItem.leadAppendDetailBomboraTopics &&
        selectedItem.leadAppendDetailBomboraTopics.length ? (
          <Tabs.TabPane tab={"Bombora"} key={"6"}>
            <BomboraSubTable
              data={{
                leadAppendDetailBomboraTopics:
                  selectedItem.leadAppendDetailBomboraTopics,
              }}
            />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {selectedItem.leadAppendDetailAberdeenKeywords &&
        selectedItem.leadAppendDetailAberdeenKeywords.length ? (
          <Tabs.TabPane tab={"Aberdeen"} key={"7"}>
            <AberdeenSubTable
              data={{
                leadAppendDetailAberdeenKeywords:
                  selectedItem.leadAppendDetailAberdeenKeywords,
              }}
            />
          </Tabs.TabPane>
        ) : (
          ""
        )}

        {selectedItem.leadAppendDetailTechTargetKeywords &&
        selectedItem.leadAppendDetailTechTargetKeywords.length ? (
          <Tabs.TabPane tab={"Tech Target"} key={"8"}>
            <TechTargetSubTable
              data={{
                leadAppendDetailTechTargetKeywords:
                  selectedItem.leadAppendDetailTechTargetKeywords,
              }}
            />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {isDigitalTabShow(selectedItem) ? (
          <Tabs.TabPane tab={"Digital"} key={"9"}>
            <DigitalSubTable data={selectedItem} />
          </Tabs.TabPane>
        ) : (
          ""
        )}
        {selectedItem.leadAppendDetailLocationJson &&
        isTopLocationTabShow(selectedItem.leadAppendDetailLocationJson) ? (
          <Tabs.TabPane tab={"Top Location"} key={"10"}>
            <TopLocationSubTable
              domain={selectedItem.domain}
              l1SubTableData={{
                LeadAppendDetailL1TrendingTopics:
                  selectedItem.LeadAppendDetailL1TrendingTopics,
                l1TrendingTopicsCountPreviousWeek:
                  selectedItem.l1TrendingTopicsCountPreviousWeek,
                l1TrendingTopicsCountCurrentWeek:
                  selectedItem.l1TrendingTopicsCountCurrentWeek,
              }}
              leadAppendDetailTopicJson={selectedItem.leadAppendDetailTopicJson}
              leadAppendDetailKeywordJson={
                selectedItem.leadAppendDetailKeywordJson
              }
              data={selectedItem.leadAppendDetailLocationJson}
              campaignKey={campaignKey}
            />
          </Tabs.TabPane>
        ) : (
          ""
        )}
      </Tabs>
    </Modal>
  );
}
