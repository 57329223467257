import * as types from "./types";

export const fetchCompanies = (
  payload: any,
  onSuccess: Function,
  onFailure: Function
) => {
  return {
    type: types.FETCH_COMPANIES,
    payload: payload,
    meta: {
      onSuccess,
      onFailure
    }
  };
};
