import React, { useState } from "react";
import { Tabs } from "antd";
import Audience from "./Audience";
import GeneralRetargetAudience from "./GeneralRetargetAudience";
import EmailAudience from "./EmailAudience";

const { TabPane } = Tabs;

const Index = () => {
  const [activeKey, setActiveKey] = useState("1");
  const onTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <Tabs defaultActiveKey={activeKey} onChange={onTabChange}>
      <TabPane tab="Audience" key="1">
        {activeKey === "1" ? <Audience /> : null}
      </TabPane>
      <TabPane tab="Site Visitor Audience" key="2">
        {activeKey === "2" ? <GeneralRetargetAudience /> : null}
      </TabPane>
      <TabPane tab="Email Audience" key="3">
        {activeKey === "3" ? <EmailAudience /> : null}
      </TabPane>
    </Tabs>
  );
};

export default Index;
