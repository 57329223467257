import { call, put, takeEvery } from "redux-saga/effects";
import * as Api from "../../api/login-api";
import { DO_USER_LOGIN, DO_ADMIN_LOGIN, LOGIN_SUCCESS } from "./types";

function* doUserLogin(action: any) {
  try {
    const response: any = yield call(Api.loginUser, action.payload);
    yield put({ type: LOGIN_SUCCESS, payload: { ...response.data } });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess, response);
    }
  } catch (e) {
    if (action.meta.onFailure) {
      yield call(action.meta.onFailure, e);
    }
  }
}

function* doAdminLogin(action: any) {
  try {
    const response: any = yield call(Api.loginAdmin, action.payload);
    yield put({ type: LOGIN_SUCCESS, payload: { ...response.data } });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess, response);
    }
  } catch (e) {
    if (action.meta.onFailure) {
      yield call(action.meta.onFailure, e);
    }
  }
}
export default [
  takeEvery(DO_USER_LOGIN, doUserLogin),
  takeEvery(DO_ADMIN_LOGIN, doAdminLogin)
];
