import React from "react";
import "./styles/MainStatsItem.css";
import { Progress, Typography, Spin } from "antd";
import { useHistory } from "react-router";

const TopStatsItem = ({
  title,
  value,
  percentage,
  loading,
  type = "",
}: any) => {
  const history = useHistory();
  return (
    <div
      className={`root ${type && "cp"}`}
      onClick={() => {
        if (type) history.push(`/abm-details?type=${type}`);
      }}
    >
      <Typography className="titleContainer">{title}</Typography>
      {!loading ? (
        <Progress
          type="circle"
          percent={percentage}
          format={() => `${value}`}
        />
      ) : (
        <div className="spinMainStatsItem">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default TopStatsItem;
