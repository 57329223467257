import React from "react";
import { MDBDataTable } from "mdbreact";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import EditModal from "./EditModal";
import compact from "../../utility/compact";
import { toast } from "react-toastify";
import {
  getCompanyKeys,
  deleteComany,
  editComapnyName,
  getCompanies,
  loginAscompany,
} from "../../api/company";
import { CompaniesAlert } from "../../commons/companiesAlert";
import SweetAlert from "react-bootstrap-sweetalert";
import deleteIcon from "../../assest/images/delete.svg";
import editIcon from "../../assest/images/edit.svg";
import RenderToast from "../../commons/RenderToast";
import TableLoader from "../../commons/loaders/TableLoader";
import { withRouter } from "react-router";
import { message } from "antd";
import { fetchCompanies } from "../../redux/companies/actions";
import { getAdvertisersList, getAccounts } from "../../api/beeswax";

/* Datatable columns */
const columns = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
  },
  {
    label: "Created Date",
    field: "createdAt",
    sort: "asc",
  },
  // {
  //   label: "Company Files",
  //   field: "companyFiles",
  //   sort: "asc",
  // },
  {
    label: "DSP Accounts",
    field: "dspIds",
    sort: "asc",
  },
  {
    label: "Action",
    field: "action",
    sort: "disabled",
  },
];

interface Props {
  auth: any;
  companies: any;
  dispatch?: Function | any;
}

interface State {
  data: any;
  search?: string;
  currentPage?: number;
  pageLimit?: number;
  companyId?: number;
  modal: boolean;
  companyName?: string;
  companyFileKeys: Array<any>;
  loading: boolean;
  errorMessage?: string;
  visible: boolean;
  show: boolean;
  deleteComapanyId: any;
  companyFileList: Array<any>;
  companiesList: Array<any>;
  fetchCompanyKeysLoading: boolean;
  fetchBwDataLoading: boolean;
  fetchCompanyAdvertiserLoading: boolean;
  advertiserIds: Array<any> | any;
  advertisers: Array<any> | any;
  accounts: Array<any> | any;
  selectedAdvertiserIds: Array<any> | any;
  accountId: string | number;
  isAdmin: boolean;
}

class Companies extends React.Component<any, State> {
  state: State = {
    search: "",
    currentPage: NaN,
    pageLimit: NaN,
    data: { columns },
    modal: false,
    companyName: "",
    companyFileKeys: [],
    companyId: NaN,
    loading: false,
    errorMessage: "",
    visible: false,
    companiesList: [],
    show: false,
    companyFileList: [],
    deleteComapanyId: "",
    fetchCompanyKeysLoading: false,
    fetchBwDataLoading: false,
    fetchCompanyAdvertiserLoading: false,
    advertisers: [],
    advertiserIds: [],
    selectedAdvertiserIds: [],
    accounts: [],
    accountId: "",
    isAdmin: this.props.auth.Role.name === "admin" ? true : false,
  };

  fetchDspAdvertisersList = async () => {
    try {
      this.setState({ fetchBwDataLoading: true });
      const data: any = await getAdvertisersList();
      let options = data.map((value: any) => {
        return { label: value.advertiser_name, value: value.advertiser_id };
      });
      this.setState({
        advertisers: options && options.length ? options : [],
        fetchBwDataLoading: false,
      });
    } catch (error) {
      this.setState({ fetchBwDataLoading: false });
      console.log({ error });
    }
  };

  fetchAccountsList = async () => {
    try {
      this.setState({ fetchBwDataLoading: true });
      const data: any = await getAccounts();
      this.setState({
        accounts: data && data.length ? data : [],
        fetchBwDataLoading: false,
      });
    } catch (error) {
      this.setState({ fetchBwDataLoading: false });
      console.log({ error });
    }
  };

  getCompaniesList = async () => {
    try {
      this.setState({ loading: true });
      let res = await getCompanies({ text: "" });
      debugger;
      this.setState({ companiesList: res.data, loading: false });
      this.createTableData();
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  componentDidMount = async () => {
    this.props.auth.Role.name === "admin"
      ? await this.fetchAccountsList()
      : await this.fetchDspAdvertisersList();
    //await this.getCompanyKeys();
    await this.getCompaniesList();
    if (this.state.companiesList.length > 500) {
      this.setState({ visible: true });
    }
  };

  $editCompanyName = async () => {
    try {
      let data: any = {
        id: this.state.companyId,
        companyFileKeys: [...this.state.companyFileKeys],
        companyName: this.state.companyName,
        ...(this.state.selectedAdvertiserIds.length && {
          advertiserIds: this.state.selectedAdvertiserIds.map(
            (item: any) => item.value
          ),
        }),
        ...(this.state.accountId && { accountId: this.state.accountId }),
      };

      data = compact(data);
      await editComapnyName({ data });
      this.getCompaniesList();
      this.toggle();
      toast.success("Updated Successfully!");
      this.createTableData();
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          errorMessage: error.response.data.message,
        });
      } else {
        this.setState({ loading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };

  //   delete comapny with id
  deteleComany = async () => {
    try {
      const { deleteComapanyId } = this.state;
      let deleteRef = await deleteComany(deleteComapanyId);
      if (deleteRef.data.message && deleteRef.data.deleted) {
        toast.success(deleteRef.data.message);
      }
      this.setState({ show: false });
      this.getCompaniesList();
      this.props.dispatch(
        fetchCompanies(
          { text: "" },
          (res: any) => async () => {},
          (error: any) => async () => {}
        )
      );
    } catch (error) {
      if (error.response && error.response.data.message) {
        this.setState({
          loading: false,
          show: false,
        });
        toast.error(error.response.data.message);
      } else {
        this.setState({ loading: false });
        toast.error("Something went terribly wrong!");
      }
    }
  };

  fetchCompanySuccess = async (res: any) => {};

  fetchCompanyFailure = async () => {};

  // getCompanyKeys = async () => {
  //   try {
  //     this.setState({ fetchCompanyKeysLoading: true });

  //     let response = await getCompanyKeys();
  //     let options = response.data.map((value: any) => {
  //       return { label: value, value: value };
  //     });
  //     this.setState({ options: options, fetchCompanyKeysLoading: false });
  //   } catch (error) {
  //     this.setState({ fetchCompanyKeysLoading: false });
  //   }
  // };

  /* Handle modal show hide */
  toggle = (): void => this.setState({ modal: !this.state.modal });

  /* Handle company name input field */
  handleComapnyNameChange = (event: any): void =>
    this.setState({ companyName: event.target.value });

  /* Handle file ids  */
  handleFileIds = (selected: any) => {
    if (selected && selected.length) {
      let ids = selected.map((v: any) => {
        return v.value;
      });
      this.setState({ companyFileKeys: [...ids] });
    } else {
      this.setState({ companyFileKeys: [] });
    }
  };

  handleEditClick = (company: any) => {
    this.toggle();
    this.setState({
      companyName: company.name,
      companyId: company.id,
      companyFileList: company.CompanyFiles.map((file: any) => {
        return file.CampaignKey.key;
      }),
      selectedAdvertiserIds: this.state.isAdmin
        ? []
        : this.getCompanyAdvertisers(company.CompanyAdvertisers),
      accountId: company.dspAccountId,
    });
  };

  //   login as comapny function
  handleLoginAsCompany = async (payload: any) => {
    try {
      let response = await loginAscompany({ companyId: payload.companyId });

      if (!this.props.auth.previousLoginState) {
        let previousLoginState = this.props.auth;
        localStorage.setItem(
          "previousLoginState",
          JSON.stringify(previousLoginState)
        );
        this.setPreviouseLoginState(previousLoginState);
      }
      await this.setUserAuth(response);
      message.success("Account switched!");
      this.props.history.push("/");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  setPreviouseLoginState = async (previousLoginState: any) => {
    this.props.dispatch({
      type: "SET_PREVIOUSE_LOGIN_STATE",
      payload: { ...previousLoginState },
    });
    // changeTabs()
  };
  setUserAuth = async (response: any) => {
    this.props.dispatch({
      type: "SET_USER_AUTH",
      payload: { ...response.data },
    });
    this.props.dispatch({
      type: "SET_USERS",
      payload: [],
    });
    this.props.dispatch({
      type: "SET_COMPANIES",
      payload: [],
    });
  };

  getCompanyAdvertisers = (companyAdvertisers: any) => {
    const arr: any = [];
    for (const ele of companyAdvertisers) {
      const item = this.state.advertisers.find(
        (item: any) => item.value === ele.advertiserId
      );
      arr.push(item);
    }
    return arr;
  };

  getDspAccountName = (dspId: any) => {
    if (!dspId) return "N/A";
    return this.state.accounts.find((item: any) => item.id === dspId).name;
  };

  /* Prepare date object for the datatable */
  createTableData = () => {
    debugger;
    let permissionOfComapny = this.props.auth.Role.$permissions.company;
    let rows = this.state.companiesList.map((company: any) => ({
      id: company.id,
      name: company.name,
      createdAt: new Date(company.createdAt).toLocaleString(),
      dspIds: this.state.isAdmin
        ? this.getDspAccountName(company?.dspAccountId)
        : this.getCompanyAdvertisers(company.CompanyAdvertisers)
            .map((item: any) => item.label)
            .join(" , "),
      companyFiles:
        company.CompanyFiles.map((file: any) => {
          return file.CampaignKey.key;
        }).toString() || "No Files",
      action: (
        <>
          {permissionOfComapny && permissionOfComapny.global !== "R" && (
            <>
              {/* <Button
                color="primary"
                disabled={false}
                onClick={async () => {
                  await this.handleLoginAsCompany({ companyId: company.id });
                }}
                className="mx-1"
                size="sm"
              >
                Login as
              </Button> */}
              <Button
                color="bg-transparent"
                onClick={() => {
                  this.handleEditClick(company);
                }}
                className="border-0 shadow-none p-0 mx-1"
                size="sm"
              >
                <img src={editIcon} width="22" alt="" />
              </Button>
            </>
          )}
          {permissionOfComapny && permissionOfComapny.global === "CRUD" && (
            <Button
              color="bg-transparent"
              className="border-0 shadow-none p-0"
              onClick={() => {
                this.setState({
                  show: true,
                  deleteComapanyId: company.id,
                });
              }}
            >
              <img src={deleteIcon} width="22" alt="" />
            </Button>
          )}
        </>
      ),
    }));
    this.setState({ data: { columns, rows } });
    return <></>;
  };

  isLoading = () => {
    const {
      loading,
      fetchCompanyAdvertiserLoading,
      fetchBwDataLoading,
      fetchCompanyKeysLoading,
    } = this.state;
    if (
      loading ||
      fetchCompanyAdvertiserLoading ||
      fetchBwDataLoading ||
      fetchCompanyKeysLoading
    )
      return true;
    return false;
  };

  handleSelect = (value: any, key: any) => {
    const newState = { [key]: value } as Pick<State, keyof State>;
    this.setState(newState);
  };

  render(): JSX.Element {
    const { data }: { data: any } = this.state;
    console.log({ accountId: this.state.accountId });
    return (
      <>
        <RenderToast />
        <CompaniesAlert visible={this.state.visible} />
        {this.isLoading() ? (
          <TableLoader columns={columns} length={10} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            responsive
            noBottomColumns
            data={data}
            small={true}
            className="mt-3"
          />
        )}
        {this.state.modal && (
          <EditModal
            toggle={this.toggle}
            handleFileIds={this.handleFileIds}
            companyName={this.state.companyName}
            modal={this.state.modal}
            companyFileList={this.state.companyFileList}
            handleSubmit={this.$editCompanyName}
            handleComapnyNameChange={this.handleComapnyNameChange}
            handleSelect={this.handleSelect}
            advertisers={this.state.advertisers}
            advertiserIds={this.state.selectedAdvertiserIds}
            isAdmin={this.state.isAdmin}
            accounts={this.state.accounts}
            accountId={this.state.accountId}
          />
        )}

        <SweetAlert
          warning
          customButtons={
            <>
              <Button
                color="info"
                size="md"
                onClick={() => {
                  this.setState({ show: false });
                }}
              >
                Dismiss
              </Button>
              <Button color="danger" size="md" onClick={this.deteleComany}>
                Delete
              </Button>
            </>
          }
          show={this.state.show}
          title="Are you sure?"
          onConfirm={() => {
            this.deteleComany();
          }}
        >
          You will not be able to recover this record!
        </SweetAlert>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
  companies: state.companies.list,
});

export default connect(mapStateToProps)(withRouter(Companies));
