import React from "react";
import { MDBDataTable } from "mdbreact";
import RenderToast from "../../commons/RenderToast";
import TableLoader from "../../commons/loaders/TableLoader";
import { targetAccountFileView } from "../../api/target-account";

interface State {
  data: Object;
  files: Array<any>;
  loading: Boolean;
  fileId: any;
  isModalOpen: Boolean;
}

const columns = [
  {
    label: "Email",
    field: "email",
    sort: "asc",
  },
];

class ViewTargetAccountFile extends React.Component<any, State> {
  state: State = {
    files: [],
    data: {
      columns,
    },
    loading: false,
    fileId: null,
    isModalOpen: false,
  };

  handleModalToggle = () =>
    this.setState({ isModalOpen: !this.state.isModalOpen });

  /* Prepare date object for the datatable */
  createTableData = () => {
    let { files } = this.state;
    let rows = files.map((file: any, index: any) => ({
      email: <label style={{ marginLeft: "10px" }}>{file.email}</label>,
      locality: file.locality,
      zipcode: file.zipcode,
    }));
    this.setState({ data: { columns: columns, rows } });
  };

  viewSelectedFile = async () => {
    try {
      this.setState({ loading: true });
      var res = await targetAccountFileView(this.state.fileId, "emails");
      this.setState({ files: res.data.rows, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  componentDidMount = async () => {
    try {
      let paramsId = this.props.match.params.id;
      await this.setState({ fileId: paramsId });
      await this.viewSelectedFile();
      this.createTableData();
    } catch (error) {}
  };

  render(): JSX.Element {
    const { data }: { data: any } = this.state;
    return (
      <>
        <RenderToast />
        <div className="mt-3 d-flex justify-content-end align-items-center">
          {/* <button
            className="btn btn-success btn-sm"
            onClick={() => {
              if (this.isAllSelected()) {
                this.setState({
                  checkedDomains: [],
                });
              } else {
                this.setState({
                  checkedDomains: this.state.files.map((file) => file.id),
                });
              }
            }}
          >
            {this.isAllSelected() ? "Unselect All" : "Select All"}
          </button>
          <button
            className="btn btn-success btn-sm"
            onClick={this.handleModalToggle}
          >
            Add Domains
          </button> */}
        </div>
        {this.state.loading ? (
          <TableLoader columns={columns} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            noBottomColumns
            responsive
            data={data}
            small={true}
          />
        )}
      </>
    );
  }
}

export default ViewTargetAccountFile;
