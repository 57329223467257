import { call, put, takeEvery } from "redux-saga/effects";
import * as userApi from "../../api/user";
import { SET_USERS, FACTCH_USERS } from "./types";

function* fetchUserList(action: any) {
  try {
    const userList = yield call(
      userApi.fatchAllUserListOfCompany,
      action.payload
    );

    yield put({ type: SET_USERS, payload: [...userList.data.rows] });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess, userList);
    }
  } catch (error) {
    if (action.meta.onFailure) {
      yield call(action.meta.onFailure, error);
    }
  }
}

export default [takeEvery(FACTCH_USERS, fetchUserList)];
