import React, { useState } from "react";
import classes from "./styles/BwStatsTopSection.module.css";
import { Select, Tooltip, Switch } from "antd";
import BwStatsModel from "./BwStatsModel";
import _ from "lodash";

const { Option } = Select;

const BwStatsTopSection = ({
  setMainFilters,
  mainFilters,
  companies,
  isAdmin,
  setCompanyId,
  companyId,
  lineItems,
  campaigns,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checked, setChecked] = useState<any>(-1);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (key: any, value: any) => {
    if (value === -1) {
      const newFilters = _.omit(mainFilters, [key]);
      setMainFilters({ ...newFilters });
    } else {
      setMainFilters({ ...mainFilters, [key]: value });
    }
  };

  React.useEffect(() => {
    if (checked !== -1) {
      if (checked) {
        if (mainFilters.line_item_id) {
          const newFilters = _.omit(mainFilters, ["line_item_id"]);
          if ((companyId || !isAdmin) && campaigns && campaigns.length)
            newFilters["campaign_id"] = campaigns[0].campaign_id;
          setMainFilters({ ...newFilters });
        }
      } else {
        if (mainFilters.campaign_id) {
          const newFilters = _.omit(mainFilters, ["campaign_id"]);
          if ((companyId || !isAdmin) && lineItems && lineItems.length)
            newFilters["line_item_id"] = lineItems[0].line_item_id;
          setMainFilters({ ...newFilters });
        }
      }
    }
  }, [checked]);

  return (
    <div className={classes.container}>
      <div className={classes.selectContainer}>
        {isAdmin ? (
          <Select
            className={classes.select}
            onChange={(value) => setCompanyId(value)}
            value={companyId ? companyId : 0}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (typeof option?.children === "string")
                return (
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              return (
                option?.children?.props?.title
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              );
            }}
          >
            <Option value={0}>Company: All</Option>
            {companies.map((company: any, index: any) => (
              <Option value={company.id} key={index}>
                <Tooltip title={company.name}>
                  <span>{company.name}</span>
                </Tooltip>
              </Option>
            ))}
          </Select>
        ) : null}
        <Select
          className={classes.select}
          onChange={handleChange}
          defaultValue={statusOptions[0].value}
        >
          {statusOptions.map((option, index) => (
            <Option value={option.value} key={index}>
              {option.title}
            </Option>
          ))}
        </Select>
        {checked ? (
          <>
            {!mainFilters.line_item_id ? (
              <Select
                dropdownClassName="drop_lg"
                showSearch
                className={classes.select}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (typeof option?.children === "string")
                    return (
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  return (
                    option?.children?.props?.title
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  );
                }}
                defaultValue={
                  isAdmin && !companyId ? -1 : campaigns?.[0]?.campaign_id
                }
                onChange={(value) => handleChange("campaign_id", value)}
              >
                {isAdmin && !companyId ? (
                  <Option value={-1}>Campaign: All</Option>
                ) : null}
                {campaigns.length
                  ? campaigns.map((option: any, index: any) => (
                      <Option value={option.campaign_id} key={index}>
                        <Tooltip title={option.campaign_name}>
                          <span>{option.campaign_name}</span>
                        </Tooltip>
                      </Option>
                    ))
                  : null}
              </Select>
            ) : null}
            {campaigns.length} {campaigns.length > 1 ? "Campaigns" : "Campaign"}
          </>
        ) : (
          <>
            {!mainFilters.campaign_id ? (
              <Select
                dropdownClassName="drop_lg"
                showSearch
                className={classes.select}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (typeof option?.children === "string")
                    return (
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  return (
                    option?.children?.props?.title
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  );
                }}
                defaultValue={
                  isAdmin && !companyId ? -1 : lineItems?.[0]?.line_item_id
                }
                onChange={(value) => handleChange("line_item_id", value)}
              >
                {isAdmin && !companyId ? (
                  <Option value={-1}>LineItems: All</Option>
                ) : null}
                {lineItems.length
                  ? lineItems.map((option: any, index: any) => (
                      <Option value={option.line_item_id} key={index}>
                        <Tooltip title={option.line_item_name}>
                          <span>{option.line_item_name}</span>
                        </Tooltip>
                      </Option>
                    ))
                  : null}
              </Select>
            ) : null}
            {lineItems.length} {lineItems.length > 1 ? "LineItems" : "LineItem"}
          </>
        )}
        <Switch
          checked={checked || checked === -1 ? true : false}
          onChange={(checked) => {
            setChecked(checked);
          }}
        />
        Switch to {checked ? "Line Items" : "Campaigns"}
      </div>
      {/* <div className={classes.filterContainer} onClick={() => showModal()}>
        <img src={filterImg} alt="filter-img" className={classes.img} />
      </div> */}
      {isModalVisible && (
        <BwStatsModel
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

const statusOptions = [
  {
    title: "Status: All",
    value: "all",
  },
  {
    title: "In Progress",
    value: "inProgress",
  },
  {
    title: "Pending",
    value: "pending",
  },
];

export default BwStatsTopSection;
