const headers = (showCampaignKey: Boolean) => {
 
  let headings = [
    {
      sortable: false,
      name: "Account",
    },
    {
      sortable: true,
      name: "Tier",
    },
    {
      sortable: false,
      name: "Keywords",
    },
    {
      sortable: false,
      name: "Top Locations",
    },
  ];
   if (showCampaignKey) {
     headings.splice(1, 0, {
       sortable: false,
       name: "Campaign Key",
     });
   }
  return headings;
};

export default headers;
