import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import PersonalFilter from "./PersonalFilter";
import { connect } from "react-redux";
import { campaignSetup } from "../../api/campaignFiles";
import { InputNumber, Tree, TreeSelect } from "antd";
import styled from "styled-components";
import Select from "react-select";
import { message } from "antd";
import { withRouter } from "react-router-dom";
import { fetchCampaignKeys, fetchTargetFiles } from "../../api/target-account";

import { jobFuntions } from "../../data/job-functions";
const { SHOW_PARENT } = TreeSelect;
const StyledCard = styled(Card)``;
class CampaignSetupForUser extends Component<any, any> {
  state: any = {
    fullName: "",
    jobTitle: "",
    jobFunctions: [],
    campaignId: null,
    campaignKeys: [],
    targetAccountFiles: [],
    targetFileOptions: [],
    isProcessing: false,
  };
  onCheck = (key: any, checkedKeys: any) =>
    this.setState({ [key]: checkedKeys });

  // $getCompanyKeys = async () => {
  //   try {
  //     let response = await fetchCampaignKeys("campaign");
  //     let res = await fetchCampaignKeys("accountDiscovery");
  //     response = await this.createReactSelectValue(response.data);
  //     res = await this.createReactSelectValue(res.data);
  //     this.setState({ campaignKeys: response, accountDiscoveryFiles: res });
  //   } catch (error) {}
  // };

  handleInputChange = (event: any) =>
    this.setState({ [event.target.name]: event.target.value });

  payloadGenerator = (payload: any) => {
    const data: any = new FormData();
    for (let key in payload) {
      data.append(key, payload[key]);
    }
    return data;
  };

  handleSubmit = async () => {
    try {
      await this.setState({ isProcessing: true });
      const { fullName, jobTitle, campaignId, targetAccountFiles } = this.state;
      let payload: any = {
        fullName,
        jobTitle,
        campaignId,
        targetAccountFiles: targetAccountFiles
          ? targetAccountFiles.map((item: any) => item.value)
          : null,
      };
      payload = this.payloadGenerator(payload);
      await campaignSetup(payload);
      await this.setState({ isProcessing: false });
      this.props.history.push("/campaigns");
      message.success("Successfully created!");
    } catch (error) {
      await this.setState({ isProcessing: false });
      console.log(error);
    }
  };
  $getCompanyKeys = async () => {
    try {
      let response = await fetchCampaignKeys("campaign");
      response = this.createReactSelectValue(response.data);
      this.setState({
        campaignKeys: response,
      });
    } catch (error) {}
  };
  createReactSelectValue(value: any) {
    let options: any = [];
    value.forEach((item: any) => {
      let option = {
        value: item.id,
        label: item.key,
      };
      options.push(option);
    });
    return options;
  }
  fetchTargetFiles = async () => {
    try {
      let res = await fetchTargetFiles();
      let targetFileOptions = res.data.rows.map((value: any) => {
        return { label: value.fileName, value: value.id };
      });
      this.setState({ targetFileOptions });
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  componentDidMount() {
    this.$getCompanyKeys();
    this.fetchTargetFiles();
  }
  render() {
    const { fullName, jobTitle, visitorId } = this.state;
    return (
      <>
        <Row>
          <Col md="12">
            <h6 className="mt-3 font-weight-bold">Campaign Form</h6>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <PersonalFilter
              fullName={fullName}
              jobTitle={jobTitle}
              hanleInputChange={this.handleInputChange}
            />
          </Col>
          <Col md="6">
            <StyledCard className="my-2">
              <CardBody>
                <Label>Campaign Id</Label>

                <Select
                  isMulti={false}
                  isClearable={true}
                  menuPlacement="auto"
                  onChange={async (value: any) => {
                    await this.setState({ campaignId: value.value });
                  }}
                  options={this.state.campaignKeys}
                />
              </CardBody>
            </StyledCard>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <StyledCard className="my-2">
              <CardHeader>
                <p className="font-weight-500 mb-0"> Job Function</p>
              </CardHeader>
              <CardBody>
                <Tree
                  style={{
                    minHeight: "250px",
                    maxHeight: "250px",
                    overflow: "auto",
                  }}
                  checkedKeys={this.state.jobFunctions}
                  checkable
                  onCheck={(checkedKeys: any) => {
                    this.onCheck("jobFunctions", checkedKeys);
                  }}
                  treeData={jobFuntions}
                />
                <br />
                <TreeSelect
                  {...{
                    treeData: jobFuntions,
                    allowClear: true,
                    value: this.state.jobFunctions,
                    onChange: (checkedKeys: any) => {
                      this.onCheck("jobFunctions", checkedKeys);
                    },
                    treeCheckable: true,
                    showCheckedStrategy: SHOW_PARENT,
                    placeholder: "Please select",
                    style: {
                      width: "100%",
                    },
                  }}
                />
              </CardBody>
            </StyledCard>
          </Col>
          <Col md="6">
            <StyledCard className="my-2">
              <CardHeader>
                <p className="font-weight-500 mb-0">Firmographic Filters</p>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Target Account File</Label>
                  <Select
                    isMulti={true}
                    menuPlacement="auto"
                    onChange={async (value: any) => {
                      await this.setState({ targetAccountFiles: value });
                    }}
                    options={this.state.targetFileOptions}
                    value={(() => {
                      let options: any = [];
                      if (
                        this.state.targetAccountFiles &&
                        this.state.targetAccountFiles.length
                      ) {
                        this.state.targetFileOptions.forEach((item: any) => {
                          this.state.targetAccountFiles.forEach((x: any) => {
                            if (item.value === x.value) {
                              options.push(item);
                            }
                          });
                        });
                        return options;
                      }
                    })()}
                  />{" "}
                </FormGroup>
              </CardBody>
            </StyledCard>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <button
            className="mx-3 float-right mb-4 btn btn-primary btn-sm"
            onClick={this.handleSubmit}
            disabled={this.state.isProcessing}
          >
            {this.state.isProcessing ? (
              <Spinner color="light" size="sm" />
            ) : (
              "Save"
            )}
          </button>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(CampaignSetupForUser));
