import React from "react";
import { Modal, Button, Input, message, Spin, DatePicker, Radio } from "antd";
import classes from "./styles/GeneralAudienceModal.module.css";
import { Row, Col, Label } from "reactstrap";
import PageTrackUrl from "./PageTrackUrl";
import Select from "react-select";
import { getSiteVisitors } from "../../api/campaign-setup";
import {
  createGeneralRetargetAudience,
  // updateGeneralRetargetAudience,
} from "../../api/audience";

import moment from "moment";
import { cloneDeep } from "lodash";

const { RangePicker } = DatePicker;
const expression =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const urlRegex = new RegExp(expression);
const filterOptions = [
  {
    label: "Exact",
    value: "exact",
  },
  {
    label: "Prefix",
    value: "prefix",
  },
  {
    label: "Keyword",
    value: "keyword",
  },
  {
    label: "Domain",
    value: "domain",
  },
];
interface filterUrl {
  label: string;
  value: string;
}
const CreateGenRetargetAudience = ({
  isModalVisible,
  handleCancel,
  setCreateAudienceModal,
  refetchHandler,
  editObj = {},
  setEditObj,
}: any) => {
  const [segmentName, setSegmentName] = React.useState("");
  const [siteVisitorOptions, setSiteVisitorOptions] = React.useState<any>([]);
  const [selectedSiteVisitor, setSelectedSiteVisitor] = React.useState<any>("");
  const [dateRadio, setDateRadio] = React.useState(1);
  const [filter, setFilter] = React.useState<filterUrl | null>(null);

  const [urls, setUrls] = React.useState<any>(null);
  const [url, setUrl] = React.useState<any>("");
  const [dateRange, setDateRange] = React.useState<any>([]);
  const [loading, setLoading] = React.useState({
    audienceCreate: false,
    siteVisitors: false,
  });

  const changeLoadingHandler = (key: any, value: any) => {
    setLoading((prev: any) => {
      return { ...prev, [key]: value };
    });
  };

  const isError = () => {
    if (!segmentName) {
      message.warn("Please provide Segment Name!");
      return true;
    }
    if (!selectedSiteVisitor) {
      message.warn("Please select Site Visitor!");
      return true;
    }
    if (dateRadio === 1 && (!dateRange || !dateRange.length)) {
      message.warn("Please select valid date");
      return true;
    }
    if (dateRadio === 2 && (!dateRange || dateRange.length < 2)) {
      message.warn("Please select Date Range!");
      return true;
    }
    if (!urls || Object.keys?.(urls).length <= 0) {
      message.warn("Please add URLs to continue!");
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      if (isError()) return;
      changeLoadingHandler("audienceCreate", true);
      const urlsData: any = [];
      Object.entries(urls).forEach(([key, values]: any) => {
        values.forEach(({ url }: any) => {
          urlsData.push({
            url,
            filter: key,
          });
        });
      });
      const payload = {
        segmentName,
        urls: urlsData,
        dateRange,
        siteVisitorId: selectedSiteVisitor.id,
      };
      // if (Object.keys(editObj).length > 0) {
      //   await updateGeneralRetargetAudience(editObj.id, payload);
      // } else
      await createGeneralRetargetAudience(payload);
      message.success(
        `Audience created ${
          Object.keys(editObj).length > 0 ? "updated" : "created"
        } successfully`
      );
      setEditObj({});
      changeLoadingHandler("audienceCreate", false);
      setCreateAudienceModal(false);
      refetchHandler();
    } catch (error) {
      changeLoadingHandler("audienceCreate", false);
      console.log({ error });
    }
  };

  const handleRange = (value: any) => {
    if (Array.isArray(value)) setDateRange(value);
    else setDateRange([value]);
  };
  const onDateRadioChange = (e: any) => {
    setDateRadio(e.target.value);
  };
  const urlRemoveHandler = (urlData: any) => {
    const cloneUrls = cloneDeep(urls);
    const filteredUrls: any = cloneUrls?.[urlData.filter]?.filter(
      (item: any) => item.id !== urlData.id
    );
    if (filteredUrls && filteredUrls.length > 0) {
      cloneUrls[urlData.filter] = filteredUrls;
    } else {
      delete cloneUrls[urlData.filter];
    }
    setUrls(cloneUrls);
  };
  // const setEditValues = () => {
  //   if (editObj && Object.keys(editObj).length) {
  //     const {
  //       segmentName = "",
  //       GeneralRetargetUrls,
  //       startTime,
  //       endTime,
  //       SiteVisitorOption,
  //     } = editObj;
  //     setSegmentName(segmentName);
  //     if (GeneralRetargetUrls.length) {
  //       const reformUrls: any = {};
  //       GeneralRetargetUrls.forEach((item: any) => {
  //         reformUrls[item.filter] = [
  //           ...(reformUrls[item.filter] ? reformUrls[item.filter] : []),
  //           {
  //             id: item.id,
  //             url: item.url,
  //           },
  //         ];
  //       });

  //       setUrls(reformUrls);
  //     }

  //     if (startTime && endTime) {
  //       setDateRange([moment(startTime), moment(endTime)]);
  //       setDateRadio(2);
  //     } else {
  //       setDateRange([moment(startTime)]);
  //       setDateRadio(1);
  //     }
  //     setSelectedSiteVisitor({
  //       id: SiteVisitorOption.id,
  //       label: SiteVisitorOption.pageIdentifier,
  //       value: SiteVisitorOption.pageIdentifier,
  //     });
  //   }
  // };
  React.useEffect(() => {
    const fetchSiteVisitors = async () => {
      try {
        const res = await getSiteVisitors();
        changeLoadingHandler("siteVisitors", true);
        let siteVisitors = res.data.map((value: any) => {
          return {
            id: value.id,
            label: value.pageIdentifier,
            value: value.pageIdentifier,
          };
        });
        if (!siteVisitors.length) return;
        setSiteVisitorOptions([...siteVisitors]);
        changeLoadingHandler("siteVisitors", false);
      } catch (error) {
        changeLoadingHandler("siteVisitors", false);
        console.log({ error });
      }
    };
    fetchSiteVisitors();
  }, []);
  // React.useEffect(() => {
  //   if (Object.keys(editObj).length) {
  //     setEditValues();
  //   }
  // }, []);
  const onChangeFilter = (data: any) => {
    setFilter(data);
  };
  const customStyles = {
    control: () => ({
      height: 41,
      minHeight: 41,
    }),
  };
  return (
    <Modal
      centered={true}
      title="Create Site Visitor Audience"
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      maskClosable={false}
      width={800}
      footer={
        editObj && Object.keys(editObj).length && editObj.status
          ? [
              <Button
                type="primary"
                className="btnPrimary"
                onClick={() => {
                  setEditObj({});
                  setCreateAudienceModal(false);
                }}
              >
                OK
              </Button>,
            ]
          : [
              <Button
                key="submit"
                type="primary"
                className="btnPrimary"
                onClick={handleSubmit}
                loading={loading.audienceCreate}
              >
                Submit
              </Button>,
            ]
      }
    >
      <>
        {!loading.siteVisitors ? (
          <div className={classes.container}>
            <Row>
              <Col md={6}>
                <Input
                  placeholder="Enter Segment Name"
                  className={classes.inp}
                  value={segmentName}
                  defaultValue={editObj?.segmentName ?? ""}
                  onChange={({ target: { value } }) => setSegmentName(value)}
                />
              </Col>
              <Col md={6}>
                <Select
                  isMulti={false}
                  onChange={(value: any) => {
                    setSelectedSiteVisitor(value);
                  }}
                  options={siteVisitorOptions}
                  value={selectedSiteVisitor}
                  className={classes.selectInp}
                  placeholder="Select Site Visitor Id"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Radio.Group onChange={onDateRadioChange} value={dateRadio}>
                <Radio value={1}>Run continously from start date</Radio>
                <Radio value={2}>Set start and end date</Radio>
              </Radio.Group>
              {dateRadio === 1 ? (
                <Col md={7}>
                  <DatePicker
                    onChange={handleRange}
                    className={classes.rangePicker}
                    value={dateRange[0] ?? moment()}
                  />
                </Col>
              ) : (
                <Col md={7}>
                  <RangePicker
                    onChange={handleRange}
                    className={classes.rangePicker}
                    value={dateRange}
                  />
                </Col>
              )}
            </Row>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Col md={7}>
                <Input
                  placeholder="Enter Url"
                  className={classes.inp}
                  value={url}
                  onChange={({ target: { value } }) => setUrl(value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && url) {
                      if (!filter) {
                        message.warn("Please select valid filter");
                        return;
                      }
                      if (!url.match(urlRegex) && filter?.value === "exact") {
                        message.warn("Invalid URL");
                        return;
                      }

                      const PrevIndex = urls?.[filter.value]?.find(
                        (item: any) =>
                          item.url.trim() === url.trim() &&
                          item.filter === filter.value
                      );
                      if (!PrevIndex)
                        setUrls((prev: any) => ({
                          ...prev,
                          [filter.value]: [
                            ...(prev?.[filter.value]
                              ? prev?.[filter.value]
                              : []),
                            {
                              url,
                              id: Date.now(),
                              include: true,
                              filter: filter?.value,
                            },
                          ],
                        }));
                      setUrl("");
                      setFilter(null);
                    }
                  }}
                />
              </Col>
              <Col md={3}>
                <Select
                  isMulti={false}
                  onChange={onChangeFilter}
                  options={filterOptions}
                  value={filter}
                  placeholder="Match Type"
                />
              </Col>
              <Col md={2}>
                <button
                  className="btn btn-success btn-md"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!filter) {
                      message.warn("Please select valid filter");
                      return;
                    }
                    if (url) {
                      if (!url.match(urlRegex) && filter?.value === "exact") {
                        message.warn("Invalid URL");
                        return;
                      }
                      const PrevIndex = urls?.[filter.value]?.find(
                        (item: any) =>
                          item.url.trim() === url.trim() &&
                          item.filter === filter.value
                      );
                      if (!PrevIndex) {
                        setUrls((prev: any) => ({
                          ...prev,
                          [filter.value]: [
                            ...(prev?.[filter.value]
                              ? prev?.[filter.value]
                              : []),
                            {
                              url,
                              id: Date.now(),
                              include: true,
                              filter: filter?.value,
                            },
                          ],
                        }));
                      }

                      setUrl("");
                      setFilter(null);
                    }
                  }}
                >
                  ADD
                </button>
              </Col>
            </Row>
            <div className={classes.urlRoot}>
              {urls && Object.values?.(urls)?.length > 0
                ? Object.entries?.(urls)?.map(([key, values]: any) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      <Label
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {key ? key.charAt(0).toUpperCase() + key.slice(1) : ""}
                      </Label>
                      {values.map((urlItem: any) => (
                        <PageTrackUrl
                          key={urlItem.id}
                          urlItem={urlItem}
                          filter={key}
                          urlRemoveHandler={urlRemoveHandler}
                        />
                      ))}
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : (
          <div
            className="w-100 justify-content-center d-flex align-items-center bg-white"
            style={{ minHeight: "30vh" }}
          >
            <Spin size="large" />
          </div>
        )}
      </>
    </Modal>
  );
};

export default CreateGenRetargetAudience;
