import React, { Component } from "react";
import { connect } from "react-redux";
import CampaignSetup from "./CampaignSetup";
import CampaignSetupForUser from "./CampaignSetupForUser";

class MainCampaignSetup extends Component<any, any> {
  render() {
    let {
      auth: {
        Role: { name: scope },
      },
    } = this.props;
    return (
      <>
        {scope === "user" ? (
          <CampaignSetupForUser />
        ) : scope === "admin" ? (
          <CampaignSetup />
        ) : (
          <CampaignSetupForUser />
        )}
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MainCampaignSetup);
