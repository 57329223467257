import React from "react";
import {
  Modal,
  Input,
  message,
  Spin,
  Tree,
  TreeSelect,
  Button,
  Checkbox,
} from "antd";
import { Row, Col, Card, CardBody, FormGroup, CardHeader } from "reactstrap";
import classes from "./styles/AudienceModal.module.css";
import { fetchTargetFiles } from "../../api/target-account";
import Select from "react-select";
import { JOB_FUNCTIONS_SINGLE_LEVEL } from "../../data/job-functions";
import { MANAGEMENT_LEVEL } from "../../data/management_level";
import styled from "styled-components";
import {
  createAudience,
  editAudience,
  modifyAudienceDbData,
} from "../../api/audience";
import { fetchCampaignKeys } from "../../api/target-account";

const StyledCard = styled(Card)``;
const { SHOW_PARENT } = TreeSelect;

const S3REGION: any = {
  EMEA: "Europe",
  APAC: "Asia Pacific",
  NAM: "North America",
};

const CreateAudienceModal = ({
  isModalVisible,
  handleCancel,
  setCreateAudienceModal,
  refetchHandler,
  editObj = {},
  setEditObj,
}: any) => {
  const [targetFileOptions, setTargetFileOptions] = React.useState([]);
  const [targetAccountFiles, setTargetAccountFiles] = React.useState([]);
  const [loading, setLoading] = React.useState({
    audienceCreate: false,
    targetFiles: false,
    campaignsKeys: false,
  });
  const [jobFunctions, setJobFunctions] = React.useState([]);
  const [managementLevel, setManagementLevel] = React.useState([]);
  const [segmentName, setSegmentName] = React.useState("");
  const [isIntent, setIsIntent] = React.useState(false);
  const [campaigns, setCampaigns] = React.useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = React.useState([]);
  const [regions, setRegion] = React.useState([
    {
      value: "NAM",
      label: "North America",
    },
  ]);

  const fetchTargetFilesHandler = async () => {
    try {
      loadingHandler("targetFiles", true);
      let res: any = await fetchTargetFiles();
      let targetFileOptions = res.data.rows.map((value: any) => {
        return { label: value.fileName, value: value.id };
      });
      setTargetFileOptions(targetFileOptions);
      loadingHandler("targetFiles", false);
    } catch (error) {
      loadingHandler("targetFiles", false);

      message.error("Something went wrong");
    }
  };

  const loadingHandler = (key: any, val: any) => {
    setLoading({ ...loading, [key]: val });
  };

  const setEditValues = () => {
    if (editObj.criteria && Object.keys(editObj.criteria).length) {
      const {
        jobFunctions = [],
        managementLevel = [],
        targetAccountFiles = [],
        selectedCampaigns: selCampaigns = [],
      } = editObj.criteria;
      if (jobFunctions.length) setJobFunctions(jobFunctions);
      if (managementLevel.length) setManagementLevel(managementLevel);
      if (targetAccountFiles.length) setTargetAccountFiles(targetAccountFiles);
      if (selCampaigns.length) {
        setIsIntent(true);
        setSelectedCampaigns(selCampaigns);
      }
    }
    setSegmentName(editObj.segmentName);
    const {
      criteria: { regions },
      region,
    } = editObj;
    let editedRegion: any;
    if (region && Object.keys(region).length > 0) {
      editedRegion = [{ value: region, label: S3REGION[region] }];
    } else {
      editedRegion = regions.map((item: any) => {
        const [key, label]: any = Object.entries(S3REGION).find(
          ([key, value]: any) => key === item
        );
        return {
          label: label,
          value: key,
        };
      });
    }
    setRegion(editedRegion);
  };

  React.useEffect(() => {
    fetchTargetFilesHandler();
  }, []);

  React.useEffect(() => {
    getCompanyKeys();
  }, []);

  React.useEffect(() => {
    if (Object.keys(editObj).length) {
      setEditValues();
    }
  }, []);

  const handleSubmit = async () => {
    try {
      if (!segmentName)
        return message.error("Please provide segment name to create audience");
      if (!targetAccountFiles || !targetAccountFiles.length)
        return message.error(
          "Please provide Target Account file to create audience"
        );
      if (isIntent && !selectedCampaigns.length)
        return message.error(
          "Please select a campaign to create an Intent Segment"
        );
      loadingHandler("audienceCreate", true);
      const payload = {
        segmentName,
        jobFunctions,
        managementLevel,
        targetAccountFiles,
        selectedCampaigns,
        regions: regions.map((item: any) => item.value),
      };
      if (Object.keys(editObj).length) {
        await modifyAudienceDbData(editObj.id, {
          segmentName,
          criteria: payload,
          regions: regions.map((item: any) => item.value),
          output: null,
          usersCount: null,
          statStatus: "pending",
        });
        setEditObj({});
        message.success("Audience updated successfully");
      } else {
        const res: any = await createAudience(payload);
        message.success("Audience created successfully");
      }
      loadingHandler("audienceCreate", false);
      setCreateAudienceModal(false);
      refetchHandler();
    } catch (error) {
      loadingHandler("audienceCreate", false);
      message.error("Something went wrong");
      console.log({ error });
    }
  };

  const handleChecboxChange = (e: any) => {
    setIsIntent(e.target.checked);
  };

  React.useEffect(() => {
    if (!isIntent && selectedCampaigns.length) setSelectedCampaigns([]);
  }, [isIntent]);

  const createReactSelectValue = (value: any) => {
    let options: any = [];
    value.forEach((item: any) => {
      let option = {
        value: item.id,
        label: item.keyName,
      };
      options.push(option);
    });
    return options;
  };

  const getCompanyKeys = async () => {
    try {
      loadingHandler("targetFiles", true);
      let response: any = await fetchCampaignKeys("campaign");
      response = createReactSelectValue(response.data);
      setCampaigns(response);
      loadingHandler("targetFiles", false);
    } catch (error) {
      loadingHandler("targetFiles", false);
    }
  };

  return (
    <Modal
      centered={true}
      title="Create Audience"
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      maskClosable={false}
      width={1024}
      footer={
        editObj && Object.keys(editObj).length && editObj.segment_status
          ? [
              <Button
                type="primary"
                className="btnPrimary"
                onClick={() => {
                  setEditObj({});
                  setCreateAudienceModal(false);
                }}
              >
                OK
              </Button>,
            ]
          : [
              <Button
                key="submit"
                type="primary"
                className="btnPrimary"
                onClick={handleSubmit}
                loading={loading.audienceCreate}
              >
                Submit
              </Button>,
            ]
      }
    >
      {!loading.targetFiles && !loading.campaignsKeys ? (
        <div className={classes.container}>
          <Row>
            <Col md={6}>
              <Input
                placeholder="Enter Segment Name"
                className={classes.inp}
                value={segmentName}
                onChange={({ target: { value } }) => setSegmentName(value)}
              />
            </Col>
            <Col md={6}>
              <Select
                isMulti={true}
                menuPlacement="auto"
                onChange={(value: any) => {
                  setTargetAccountFiles(value);
                }}
                placeholder="Select Target Account File"
                options={targetFileOptions}
                value={(() => {
                  let options: any = [];
                  if (targetAccountFiles && targetAccountFiles.length) {
                    targetFileOptions.forEach((item: any) => {
                      targetAccountFiles.forEach((x: any) => {
                        if (item.value === x.value) {
                          options.push(item);
                        }
                      });
                    });
                    return options;
                  }
                })()}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md={6} style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={isIntent}
                onChange={handleChecboxChange}
                className="font-weight-500"
              >
                Get Intent Segments
              </Checkbox>
            </Col>
            <Col md={6}>
              <Select
                isMulti={true}
                menuPlacement="auto"
                onChange={(value: any) => {
                  setRegion(value);
                }}
                placeholder="Select Region"
                options={[
                  { value: "NAM", label: S3REGION["NAM"] },
                  { value: "EMEA", label: S3REGION["EMEA"] },
                  { value: "APAC", label: S3REGION["APAC"] },
                ]}
                value={regions}
              />
            </Col>
          </Row>
          {isIntent ? (
            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col md={6}>
                <Select
                  isMulti={true}
                  menuPlacement="auto"
                  onChange={(value: any) => {
                    setSelectedCampaigns(value);
                  }}
                  placeholder="Select Campaigns"
                  options={campaigns}
                  value={(() => {
                    let options: any = [];
                    if (selectedCampaigns && selectedCampaigns.length) {
                      campaigns.forEach((item: any) => {
                        selectedCampaigns.forEach((x: any) => {
                          if (item.value === x.value) {
                            options.push(item);
                          }
                        });
                      });
                      return options;
                    }
                  })()}
                />
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col md={6}>
              <StyledCard className="my-3">
                <CardHeader>
                  <p className="font-weight-500 mb-0">Job Functions</p>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Tree
                      checkedKeys={jobFunctions}
                      style={{
                        minHeight: "250px",
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                      checkable
                      onCheck={(checkedKeys: any) => {
                        setJobFunctions(checkedKeys);
                      }}
                      treeData={JOB_FUNCTIONS_SINGLE_LEVEL}
                    />
                    <br />
                    <TreeSelect
                      {...{
                        treeData: JOB_FUNCTIONS_SINGLE_LEVEL,
                        allowClear: true,
                        value: jobFunctions,
                        onChange: (checkedKeys: any) => {
                          setJobFunctions(checkedKeys);
                        },
                        treeCheckable: true,
                        showCheckedStrategy: SHOW_PARENT,
                        placeholder: "Please select",
                        style: {
                          width: "100%",
                          paddingBottom: "14px",
                        },
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </StyledCard>
            </Col>
            <Col md={6}>
              <StyledCard className="my-3">
                <CardHeader>
                  <p className="font-weight-500 mb-0">Job Level</p>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Tree
                      checkedKeys={managementLevel}
                      style={{
                        minHeight: "250px",
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                      checkable
                      onCheck={(checkedKeys: any) => {
                        setManagementLevel(checkedKeys);
                      }}
                      treeData={MANAGEMENT_LEVEL}
                    />
                    <br />
                    <TreeSelect
                      {...{
                        treeData: MANAGEMENT_LEVEL,
                        allowClear: true,
                        value: managementLevel,
                        onChange: (checkedKeys: any) => {
                          setManagementLevel(checkedKeys);
                        },
                        treeCheckable: true,
                        showCheckedStrategy: SHOW_PARENT,
                        placeholder: "Please select",
                        style: {
                          width: "100%",
                          paddingBottom: "14px",
                        },
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </StyledCard>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className="w-100 justify-content-center d-flex align-items-center bg-white"
          style={{ minHeight: "30vh" }}
        >
          <Spin size="large" />
        </div>
      )}
    </Modal>
  );
};

export default CreateAudienceModal;
