import { Checkbox, Tooltip } from "antd";
import React, { Component } from "react";
import Select from "react-select";
import { Card, CardBody, CardHeader, Col, Form, FormGroup } from "reactstrap";
import { fetchCampaignKeys, fetchTargetFiles } from "../../api/target-account";

class RoleMangementForm extends Component<any, any> {
  state = {
    campaignKeys: [],
    publisherKeys: [],
    targetFileOptions: [],
    // accountDiscoveryFiles: [],
  };

  $getCompanyKeys = async () => {
    try {
      console.log("");
      let response = await fetchCampaignKeys("campaign");
      response = this.createReactSelectValue(response.data);
      if (this.props.auth.Role.name === "user") {
        await this.$setPublisherKeys();
      } else {
        this.setState({
          publisherKeys: response,
        });
      }
      this.setState({
        campaignKeys: response,
      });
      // await this.$setAccountDiscoveryKeys();
    } catch (error) {}
  };

  $setPublisherKeys = async () => {
    try {
      let publisherResponse = await fetchCampaignKeys("publisher");
      publisherResponse = this.createReactSelectValue(publisherResponse.data);
      this.setState({
        publisherKeys: publisherResponse,
      });
    } catch (error) {}
  };

  // $setAccountDiscoveryKeys = async () => {
  //   try {
  //     let res = await fetchCampaignKeys("accountDiscovery");
  //     res = this.createReactSelectValue(res.data);
  //     this.setState({
  //       // campaignKeys: response,
  //       accountDiscoveryFiles: res,
  //     });
  //   } catch (error) {}
  // };

  createReactSelectValue(value: any) {
    let options: any = [];
    value.forEach((item: any) => {
      let option = {
        value: item.id,
        label: item.key,
      };
      options.push(option);
    });
    return options;
  }

  // for fetch target file
  $fetchTargetFiles = async () => {
    try {
      let res = await fetchTargetFiles();
      let targetFileOptions = res.data.rows.map((value: any) => {
        return { label: value.fileName, value: value.id };
      });
      this.setState({ targetFileOptions });
    } catch (error) {}
  };
  //   $fetchAccountDiscoveryFiles = async () => {
  //     try {
  //       let res = await getAccountDiscovery();
  //       let accountDiscoveryFiles = res.data.map((value: any) => {
  //         return { label: value.id, value: value.id };
  //       });
  //       this.setState({ accountDiscoveryFiles });
  //     } catch (error) {}
  //   };

  componentDidMount = async () => {
    try {
      await this.$getCompanyKeys();
      await this.$fetchTargetFiles();
      //   await this.$fetchAccountDiscoveryFiles();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      handleChange,
      subAccountRole,
      allowSubAccounts,
      companyPermission,
      allowCompanyCreate,
      allowABMStatsView,
      allowCampaignStatsView,
      allowCampaignStatsAdminView,
      // allowAccountDiscovery,
      // targetFileRole,
      //   allowTargetFiles,
      //   campaignsRole,
      //   allowCampaigns,
      // allowAudienceManager,
      // handleAudienceManager,
      audiencePermission,
      // accountDiscoveryPermission,
      campaignSetupRole,
      allowCampaignSetup,
      handleSelectedFiles,
      allowAudienceManager,
      allowPublisherView,
      publisherViewRole,
      // campaignSetupFiles,
      // publisherViewFiles,
      // accountDiscoveries,
      // audienceManagerFiles,
    } = this.props;
    return (
      <Col md="6">
        <Card className="mt-4 ">
          <CardHeader className="" tag="h4">
            Account Permissions
          </CardHeader>
          <CardBody className="">
            <Form>
              <FormGroup className="mt-1 d-flex">
                <div className="pt-3">
                  <Checkbox
                    name="allowSubAccounts"
                    onChange={handleChange}
                    checked={allowSubAccounts}
                  >
                    This user can
                  </Checkbox>
                </div>
                <select
                  name="subAccountRole"
                  value={subAccountRole}
                  onChange={handleChange}
                  className="form-control w-auto shadow-none border-bottom border-top-0 pt-0  mx-2 border-left-0 border-right-0 rounded-0"
                >
                  <option value="R">Read</option>
                  <option value="RU">Read & Update</option>
                  <option value="CRUD">Read, Update, Create & Delete</option>
                </select>
                <span
                  className="pt-3"
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#000000a6",
                  }}
                >
                  {" "}
                  user accounts belonging to his own company.
                </span>
              </FormGroup>
            </Form>

            <Form>
              <FormGroup className="mt-1 d-flex">
                <div className="pt-3">
                  <Checkbox
                    name="allowCompanyCreate"
                    onChange={handleChange}
                    checked={allowCompanyCreate}
                    className="text-truncate"
                  >
                    Give this user agency permissions, and allow him to
                  </Checkbox>
                </div>
                <select
                  name="companyPermission"
                  value={companyPermission}
                  onChange={handleChange}
                  className="form-control w-auto shadow-none border-bottom border-top-0 pt-0  mx-2 border-left-0 border-right-0 rounded-0"
                >
                  <option value="R">Read</option>
                  <option value="RU">Read & Update</option>
                  <option value="CRUD">Read, Update, Create & Delete</option>
                </select>
                <Tooltip
                  placement="topLeft"
                  title="other companies belonging to the organization."
                  arrowPointAtCenter
                >
                  <span
                    className="pt-3 text-truncate"
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#000000a6",
                    }}
                  >
                    other companies.
                  </span>
                </Tooltip>
              </FormGroup>
              {/* {this.props.editUser &&
              !Object.keys(this.props.editUser).length ? (
                <>
                  <div className="mt-4">
                    <p className=" mb-0">
                      Add Tag for the access level(Optional)
                    </p>
                  </div>
                  <Input
                    name="roleName"
                    className="form-control w-75 border-bottom border-top-0 shadow-none pt-0 border-left-0 pl-1 border-right-0 rounded-0"
                    style={{ fontSize: "14px" }}
                    placeholder="Add Role Name"
                    value={roleName}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )} */}
            </Form>
          </CardBody>
        </Card>
        <Card className="mt-4 ">
          <CardHeader className="" tag="h4">
            Campaign Permissions
          </CardHeader>
          <CardBody>
            <Form>
              <div className="">
                <h5 className="w-100">Campaigns</h5>
                {/* <p className="text-muted">
                  All the campaign setup settings go here
                </p> */}
              </div>
              <FormGroup className=" d-flex">
                <div className="pt-3">
                  <Checkbox
                    name="allowCampaignSetup"
                    checked={allowCampaignSetup}
                    onChange={handleChange}
                  >
                    The user has
                  </Checkbox>
                </div>
                <select
                  name="campaignSetupRole"
                  value={campaignSetupRole}
                  onChange={handleChange}
                  className=" form-control w-25 shadow-none border-bottom border-top-0 pt-0  mx-1 border-left-0 border-right-0 rounded-0"
                >
                  <option value="R">Read</option>
                  {/* <option value="RU">Read & Update</option> */}
                  <option value="CRUD">Read, Update, Create & Delete</option>
                </select>{" "}
                <span
                  className="pt-3"
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#000000a6",
                  }}
                >
                  access for campaigns.
                </span>
              </FormGroup>
              {allowCampaignSetup && (
                <Select
                  menuPlacement="auto"
                  isMulti={true}
                  onChange={(value) => {
                    handleSelectedFiles("campaignSetupFiles", value);
                  }}
                  name="campaignSetupFiles"
                  options={this.state.campaignKeys}
                  value={(() => {
                    let options: any = [];
                    this.state.campaignKeys.forEach((file: any) => {
                      this.props.campaignSetupFiles.forEach((item: any) => {
                        if (file.value === parseInt(item)) {
                          options.push(file);
                        }
                      });
                    });
                    return options;
                  })()}
                />
              )}
            </Form>

            {/* <hr /> */}
            {/* <Form>
              <div className="">
                <h5 className="w-100">Account Discovery</h5>
                <p className="text-muted">
                  All the account discovery settings go here
                </p>
              </div>
              <FormGroup className="mt-1 d-flex">
                <div className="pt-3">
                  <Checkbox
                    type="checkbox"
                    className="mt-2"
                    checked={allowAccountDiscovery}
                    name="allowAccountDiscovery"
                    onChange={handleChange}
                  >
                    <span className="w-25">The user has</span>
                  </Checkbox>
                </div>
                <select
                  name="accountDiscoveryPermission"
                  value={accountDiscoveryPermission}
                  onChange={handleChange}
                  className=" form-control w-25 shadow-none border-bottom border-top-0 pt-0  mx-1 border-left-0 border-right-0 rounded-0"
                >
                  <option value="R">Read</option>
                  <option value="RU">Read & Update</option>
                  <option value="CRUD">Read, Update, Create & Delete</option>
                </select>{" "}
                <span
                  className="pt-3"
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#000000a6",
                  }}
                >
                  access for account discovery.
                </span>
              </FormGroup>
              {allowAccountDiscovery && (
                <Select
                  menuPlacement="auto"
                  isMulti={true}
                  onChange={(value) => {
                    handleSelectedFiles("accountDiscoveries", value);
                  }}
                  name="accountDiscoveries"
                  options={this.state.accountDiscoveryFiles}
                  value={(() => {
                    let options: any = [];
                    this.state.accountDiscoveryFiles.forEach((file: any) => {
                      this.props.accountDiscoveries.forEach((item: any) => {
                        if (file.value === parseInt(item)) {
                          options.push(file);
                        }
                      });
                    });
                    return options;
                  })()}
                />
              )}
            </Form> */}
            <hr />
            <Form>
              <div className="">
                <h5 className="w-100">Audience Manager</h5>
                {/* <p className="text-muted">
                  All the targetcampaign settings go here
                </p> */}
              </div>
              <FormGroup className="mt-1 d-flex">
                <div className="pt-3">
                  <Checkbox
                    // type="checkbox"
                    // className="mt-2"
                    checked={allowAudienceManager}
                    name="allowAudienceManager"
                    onChange={handleChange}
                  >
                    <span className="w-25" style={{ fontWeight: 400 }}>
                      The user has
                    </span>
                  </Checkbox>
                </div>
                <select
                  name="audiencePermission"
                  value={audiencePermission}
                  onChange={handleChange}
                  className=" form-control w-25 shadow-none border-bottom border-top-0 pt-0  mx-1 border-left-0 border-right-0 rounded-0"
                >
                  <option value="R">Read</option>
                  <option value="RU">Read & Update</option>
                  <option value="CRUD">Read, Update, Create & Delete</option>
                </select>
                <Tooltip
                  placement="topLeft"
                  title="access for the following target account files."
                  arrowPointAtCenter
                >
                  <span
                    className="pt-3 text-truncate text-muted"
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#000000a6",
                    }}
                  >
                    access for the following target account files.
                  </span>
                </Tooltip>
              </FormGroup>
              {allowAudienceManager && (
                <Select
                  menuPlacement="auto"
                  isMulti={true}
                  onChange={(value) => {
                    handleSelectedFiles("audienceManagerFiles", value);
                  }}
                  name="audienceManagerFiles"
                  options={this.state.targetFileOptions}
                  value={(() => {
                    let options: any = [];
                    this.state.targetFileOptions.forEach((file: any) => {
                      this.props.audienceManagerFiles.forEach((item: any) => {
                        if (file.value === parseInt(item)) {
                          options.push(file);
                        }
                      });
                    });
                    return options;
                  })()}
                />
              )}
            </Form>
            <hr />
            <Form>
              <div className="">
                <h5 className="w-100">ABM</h5>
                {/* <p className="text-muted">
                  All the campaign setup settings go here
                </p> */}
              </div>
              <FormGroup className=" d-flex">
                <div className="pt-3">
                  <Checkbox
                    name="allowABMStatsView"
                    checked={allowABMStatsView}
                    onChange={handleChange}
                  >
                    The user has READ access of associated campaign's ABM
                    statistics
                  </Checkbox>
                </div>
              </FormGroup>
            </Form>
            <hr />
            <Form>
              <div className="">
                <h5 className="w-100">Campaign Stats</h5>
                {/* <p className="text-muted">
                  All the campaign setup settings go here
                </p> */}
              </div>
              <FormGroup className=" d-flex">
                <div className="pt-3">
                  <Checkbox
                    name="allowCampaignStatsView"
                    checked={allowCampaignStatsView}
                    onChange={handleChange}
                  >
                    The user has READ access of associated campaign's statistics
                  </Checkbox>
                </div>
              </FormGroup>
              <FormGroup className=" d-flex">
                <div className="pt-3">
                  <Checkbox
                    name="allowCampaignStatsAdminView"
                    checked={allowCampaignStatsAdminView}
                    onChange={handleChange}
                  >
                    The user has READ access of full campaign's statistics
                  </Checkbox>
                </div>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default RoleMangementForm;
