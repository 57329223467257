import React, { Component } from "react";
import { Table } from "reactstrap";
import Expression from "./Expression";
import {isUniqueShow,isSessionsShow,isPageVisitShow} from '../../utility/campaign-view'
class SiteVisitorTable extends Component<any, any> {
  
  render() {
    let { data } = this.props;
    return (
      <div>
        <Table className="table-sm table-striped table-hover table-borderless">
          <thead>
            <tr>
              <th className="font-weight-bold border-bottom-0"></th>
              <th className="font-weight-bold border-bottom-0">Current Week</th>
              <th className="font-weight-bold border-bottom-0">Previous Week</th>
              <th className="font-weight-bold border-bottom-0">Current Month</th>
              <th className="font-weight-bold border-bottom-0">Previous Month</th>
            </tr>
          </thead>
          <tbody>
            {isUniqueShow(data) ?  <tr>
              <td> Unique</td>
              <td>
                <Expression

                  value={data.weeklySiteVisitorUniqueCurrent}
                  oldValue={data.weeklySiteVisitorUniquePrevious}
                  percentAgeValue={`${data.weeklySiteVisitorUniquePercentChange}%`}
                />
              </td>
              <td>{data.weeklySiteVisitorUniquePrevious}</td>
              <td>
                <Expression
                  oldValue={data.monthlySiteVisitorUniquePrevious}
                  value={data.monthlySiteVisitorUniqueCurrent}
                  percentAgeValue={`${data.monthlySiteVisitorUniquePercentChange}%`}
                />
              </td>
              <td>{data.monthlySiteVisitorUniquePrevious}</td>
            </tr>
              : ''}
            {isSessionsShow(data) ?
            <tr>
              <td> Sessions</td>
              <td>
                <Expression

                  value={data.weeklySiteVisitorSessionsCurrent}
                  oldValue={data.weeklySiteVisitorSessionsPrevious}
                  percentAgeValue={`${data.weeklySiteVisitorSessionsPercentChange}%`}
                />
              </td>
              <td>{data.weeklySiteVisitorSessionsPrevious}</td>
              <td>
                <Expression
                  value={data.monthlySiteVisitorSessionsCurrent}
                  oldValue={data.monthlySiteVisitorSessionsPrevious}
                  percentAgeValue={`${data.monthlySiteVisitorSessionsPercentChange}%`}
                />
              </td>
              <td>{data.monthlySiteVisitorSessionsPrevious}</td>
            </tr>
               : ''}
            {isPageVisitShow(data) ?
            <tr>
              <td> Page Visits</td>
              <td>
                <Expression

                  value={data.weeklySitePageVisitsCurrent}
                  oldValue={data.weeklySitePageVisitsPrevious}
                />
              </td>
              <td>{data.weeklySitePageVisitsPrevious}</td>
              <td>

                <Expression
                  value={data.monthlySitePageVisitsCurrent}
                  oldValue={data.monthlySitePageVisitsPrevious}

                />
              </td>
              <td>{data.monthlySitePageVisitsPrevious}</td>
            </tr>
   : ''}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SiteVisitorTable;
