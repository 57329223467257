import axios from "./axios-configured";

export const loginUser = async (payload: any) => {
  let { email, password } = payload;
  let loginDetails = { loginID: email, password };
  return axios.post("/auth/signin", loginDetails);
};

export const loginAdmin = async (payload: any) => {
  let { email, password } = payload;
  let loginDetails = { email, password };
  return axios.post("/admin/auth/signin", loginDetails);
};

export const loginAs = async (loginAsPayload: any) => {
  return axios.get(`/get/user/login/${loginAsPayload.userId}`);
};
