import React, { Component } from "react";
import { Table } from "reactstrap";
// import Expression from "./Expression";
import "./L1SubTable.css";
import Expression from "./Expression";

class L1SubTable extends Component<any, any> {
  render() {
    let typeValue =
      this.props.data.l1TrendingTopicsCountPreviousWeek >
        this.props.data.l1TrendingTopicsCountCurrentWeek
        ? "down"
        : "up";
    let actualValue =
      typeValue === "up"
        ? (100 * this.props.data.l1TrendingTopicsCountPreviousWeek) /
        (this.props.data.l1TrendingTopicsCountPreviousWeek +
          this.props.data.l1TrendingTopicsCountCurrentWeek)
        : (100 * this.props.data.l1TrendingTopicsCountCurrentWeek) /
        (this.props.data.l1TrendingTopicsCountPreviousWeek +
          this.props.data.l1TrendingTopicsCountCurrentWeek);
    if (isNaN(actualValue)) actualValue = 0;
    return (
      <div>
        <h5 style={{ fontWeight: 500 }}>
          Weekly Change 
          <Expression
            type={typeValue}
            value=""
            percentAgeValue={`${Math.floor(actualValue)}%`}
          />
        </h5>
        {/* <div className="row">

            <div className="offset-md-4 col-md-4">
                <div className="text-below">
                    {this.props.data.l1TrendingTopicsCountPreviousWeek}
                    <sub>Previous </sub>
                </div>
                <svg className="bi bi-chevron-right" width="32" height="32" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clip-rule="evenodd"></path></svg>
                To
                <svg className="bi bi-chevron-right" width="32"     height="32" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clip-rule="evenodd"></path></svg>
                <div className="text-below">
                    <sub>Current </sub>
                    {this.props.data.l1TrendingTopicsCountCurrentWeek}
                </div>
            </div>
        </div> */}

        <div>
          <Table className="table-sm table-striped table-hover table-borderless">
            <thead>
              <tr>
                <th className="font-weight-bold border-bottom-0">Topic</th>
              </tr>
            </thead>
            <tbody>
              {this.props.laading ? (
                <>
                  <tr>
                    <td>Loading...</td>
                  </tr>
                </>
              ) : (
                  <>
                    {this.props.data &&
                      this.props.data.LeadAppendDetailL1TrendingTopics.map((item: any, index: number) => {
                        return (
                          <tr key={`l1_topics_sub_table_${index}`}>
                            <td className="text-capitalize">{item.l1TrendingTopic}</td>
                          </tr>
                        );
                      })}
                  </>
                )}
            </tbody>
          </Table>
        </div>

      </div>
    );
  }
}

export default L1SubTable;
