import React from "react";
import { Table } from "antd";
import { getHybridDomainsBySiteVisitor } from "../../../api/abm-stats";
import CustomAntTable from "../../../commons/CustomAntTable";

const antColumns = [
  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
  },
  {
    title: "Page Views Current",
    dataIndex: "totalpagescur",
    key: "totalpagescur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Page Views Delta",
    dataIndex: "totalpagesdelta",
    key: "totalpagesdelta",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Unique Visitors Current",
    dataIndex: "uniquevisitorscur",
    key: "uniquevisitorscur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Pages Per Unique User Current",
    dataIndex: "pagesperuniquevisitordelta",
    key: "pagesperuniquevisitordelta",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Pages Per Unique User Previous",
    dataIndex: "pagesperuniquevisitorprev",
    key: "pagesperuniquevisitorprev",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Pages Per Unique User Delta",
    dataIndex: "pagesperuniquevisitorcur",
    key: "pagesperuniquevisitorcur",
    sorter: true,
    sortOrder: false,
  },
];
const PageViewsMetrics = ({
  tableData,
  setTableData,
  changeLoadingHandler,
  campaigns,
  loading,
  filters,
  filterChangeHandler,
}: any) => {
  const [columns, setColumns] = React.useState(antColumns);
  const fetchData = async () => {
    try {
      changeLoadingHandler("tableData", true);
      // const inputData = formatSiteVisitorMetrics();
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getHybridDomainsBySiteVisitor(filters);
      const row: any = res?.data?.[0]?.result?.Row;
      const data = row.map((item: any) => {
        return {
          domain: item.domain,
          uniquevisitorscur: item.uniquevisitorscur,
          totalpagescur: item.totalpagescur,
          totalpagesdelta: item.totalpagesdelta,
          pagesperuniquevisitorcur: item.pagesperuniquevisitorcur,
          pagesperuniquevisitorprev: item.pagesperuniquevisitorprev,
          pagesperuniquevisitordelta: item.pagesperuniquevisitordelta,
        };
      });

      setTableData(data ?? []);
      changeLoadingHandler("tableData", false);
    } catch (error) {
      changeLoadingHandler("tableData", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchData();
    }
  }, [campaigns, filters]);
  return (
    <CustomAntTable
      columns={columns}
      setColumns={setColumns}
      tableData={tableData}
      loading={loading}
      filterChangeHandler={filterChangeHandler}
      filters={filters}
    />
  );
};

export default PageViewsMetrics;
