import React from "react";
import { Tooltip } from "antd";
import { Popover } from "antd";
import { TAConstants } from "../targetAccountConstants";

const remainingItmes = (data: any) => (

  <ul
    style={{
      overflowY: "auto",
      maxHeight: "150px",
      listStyleType: "none",
      padding: 0,
    }}
  >
    {data &&
      data
        .map((item: any, index: number) => {
          return (
            <li className="text-capitalize" key={`remaining_${index}`}>
              {item.keyword} ({item.currentCount})
            </li>
          );
        })}
  </ul>
);

const TrendingKeywordsTd = ({ data }: { data: any }) => {
  data = data && data.filter((item: any) => item.type === "week").sort((a: any, b: any) => {
    return b.currentCount - a.currentCount
  })

  return (
    <td className="">
      {data &&
        data
          .slice(0, TAConstants.MAX_TD_ROWS)
          .map((item: any, index: number) => (
            <Tooltip
              key={`keyword_${index}`}
              title={<span className="text-capitalize">{item.keyword} ({item.currentCount})</span>}
            >
              <div className="d-flex">
                <small
                  className="d-inline-block text-capitalize text-truncate text-muted"
                  style={{ maxWidth: "120px" }}
                >
                  {item.keyword} ({item.currentCount})
                </small>
              </div>
            </Tooltip>
          ))}
      {data && data.length > TAConstants.MAX_TD_ROWS && (
        <Popover content={remainingItmes(data)}>
          <small
            className="d-inline-block text-truncate"
            style={{ maxWidth: "120px", color: "#007bff", cursor: "cursor" }}
          >
            View All {data.length}
          </small>
        </Popover>
      )}
    </td>
  );
};

export default TrendingKeywordsTd;
