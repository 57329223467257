import axios from "./axios-configured";

export const getCompanyFiles = async (payload: any) => {
  return axios.get(`/company/files`, { params: payload.params });
};

export const viewFileData = async (id: any) => {
  return axios.get(`/campaignFileRecords/${id}`);
};

export const campaignSetup = async (payload: any) => {
  return axios.post(`/campaign-setup/create`, payload);
};

export const getAllCampaignSettings = async (payload: any) => {
  if (!payload.type) payload["type"] = "campaign";
  return axios.get(`/campaign-setup`, { params: payload });
};

export const deleteCampaignSettingsEntry = async (campaignSetupId: any) => {
  return axios.delete(`/campaign-setup/${campaignSetupId}`);
};

export const fetchKeywordFiles = async () => {
  return axios.get(`/keyword-files`);
};

//its is acctully target-account api call
export const getSetupCampaignEdit = async (setupId: number) => {
  return axios.get(`/campaign-setup/${setupId}`);
};
export const updateTargetAccount = async (data: any) => {
  return axios.put(`/campaign-setup/${data.id}`, data.payload);
};

export const updateCampaign = async (data: any) => {
  return axios.put(`/campaign-setup/${data.id}/update`, data.payload);
};

export const fetchLeadAppendFilters = (payload: any) => {
  return axios.get(`/leadAppend/${payload.leadAppendId}/filters`);
};

export const downloadFile = async (payload: any) => {
  return axios.get(
    `/campaign-setup/${payload.id}/download?type=${payload.type}`
  );
};

export const fetchCampaignFilters = async (payload: any) => {
  return axios.get(`/campaign-setup/${payload.campaignId}/filters`, {
    params: {
      type: payload.type,
    },
  });
};

export const fetchLeadAppendForDomainGeo = async (payload: any) => {
  return axios.post(`/leadAppend/getLeadAppendForDomainGeo`, payload);
};
