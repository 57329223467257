import { combineReducers } from "redux";
import userAuthReducer from "./user/reducers";
import campaigns from "./campaigns/reducers";
import editUserReducer from "./selectedUser/reducers";
import companiesReducers from "./companies/reducers";
import allusersReducers from "./users/reducers";

export default combineReducers({
  auth: userAuthReducer,
  companies: companiesReducers,
  userForEdit: editUserReducer,
  users: allusersReducers,
  campaigns: campaigns,
});
