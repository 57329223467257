export default [
   {
    label: "Name",
    field: "name",
  },
  {
    label: "Key",
    field: "key",
    sort: "asc",
  },
  {
    label: "Status",
    field: "status",
    sort: "disabled",
  },
  {
    label: "Total Records",
    field: "totalRecords",
    sort: "asc",
  },
  {
    label: "CreatedAt",
    field: "createdAt",
    sort: "asc",
  },
  {
    label: "Last Updated",
    field: "completedAt",
    sort: "asc",
  },
  {
    label: "Action",
    field: "action",
    sort: "disabled",
  },
];
