import React from "react";
import { getInfluencedMetrics } from "../../../api/abm-stats";
import moment from "moment";
import _ from "lodash";
import { Table } from "antd";

const antColumns = [
  {
    title: "Page Url",
    dataIndex: "pageurl",
    key: "pageurl",
  },
  {
    title: "Page Visits",
    dataIndex: "pagevisits",
    key: "pagevisits",
  },
  {
    title: "Influenced Page Visits",
    dataIndex: "influencedpagevisits",
    key: "influencedpagevisits",
  },
  {
    title: "Unique Users",
    dataIndex: "uniqueusers",
    key: "uniqueusers",
  },
  {
    title: "Influenced Unique Users",
    dataIndex: "influenceduniqueusers",
    key: "influenceduniqueusers",
  },
];

const InfluencedMetrics = ({
  tableData,
  setTableData,
  changeLoadingHandler,
  campaigns,
  loading,
  filters,
  filterChangeHandler,
}: any) => {
  const [columns, setColumns] = React.useState(antColumns);

  const fetchData = async () => {
    try {
      setTableData([]);
      changeLoadingHandler("tableData", true);
      let startDate = "";
      let endDate = "";
      if (filters.range) {
        const range = filters.range.split(",");
        startDate = range[0];
        endDate = range[1];
      } else {
        startDate = moment()
          .subtract(filters.dayspan, "day")
          .format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }
      const campaign = campaigns?.find(
        (item: any) => item?.CampaignKey?.key === filters.campaignid
      );
      const { SiteVisitors = [] } = campaign;
      if (!SiteVisitors.length) {
        changeLoadingHandler("tableData", false);
        return;
      }
      const customid = SiteVisitors[0]?.siteVisitorId;
      const filterObj = {
        customid,
        startdateyyyymmdd: startDate,
        enddateyyyymmdd: endDate,
      };
      const res: any = await getInfluencedMetrics(filterObj);
      let data = res?.data?.map((item: any) => {
        return {
          pageurl: item.pageurl,
          pagevisits: item.pagevisits,
          influencedpagevisits: item.influencedpagevisits,
          uniqueusers: item.uniqueusers,
          influenceduniqueusers: item.influenceduniqueusers,
        };
      });
      setTableData(data ?? []);
      changeLoadingHandler("tableData", false);
    } catch (error) {
      changeLoadingHandler("tableData", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchData();
    }
  }, [campaigns, filters]);

  return <Table columns={columns} dataSource={tableData} loading={loading} />;
};

export default InfluencedMetrics;
