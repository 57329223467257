import React from "react";
import { getReports, getDownloadLinkUrl } from "../../../api/abm-stats";
import { useSelector } from "react-redux";
import { Table } from "antd";

const { REACT_APP_API_BASE_URL } = process.env;

const antColumns = [
  {
    title: "Report",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Scheduled Date",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];

const ABMReports = () => {
  const [tableData, setTableData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const donwloadFile = async (id: any) => {
    let url: any = await getDownloadLinkUrl({ id: id });
    window.open(url.data.pop(), "Download");
  };

  const getColor = (status: any) => {
    if (status === "completed") return "#00c851";
    else if (status === "error") return "#d9534f";
    else return "#055697";
  };
  const token = useSelector((state: any) => state.auth.token);

  const fetchReports = async () => {
    try {
      setLoading(true);
      const res = await getReports();
      const data = res?.data?.map((item: any) => {
        return {
          fileName: <div>{item.reportName}</div>,
          status: (
            <div
              style={{
                textTransform: "capitalize",
                color: `${getColor(item.status)}`,
              }}
            >
              {item.status === "uploadedOnSftp" ? "Pending" : item.status}
            </div>
          ),
          createdAt: (
            <div>{new Date(item.createdAt).toLocaleString() || ""}</div>
          ),
          actions: (
            <div>
              {item.category === "offline" ? (
                <button
                  className="btn btn-success btn-sm "
                  disabled={item?.status !== "completed" ? true : false}
                  onClick={() => donwloadFile(item.id)}
                >
                  Download
                </button>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${REACT_APP_API_BASE_URL}/abm-stats/reports/online/${item.id}?token=${token}`}
                  className="btn btn-success btn-sm "
                >
                  DOWNLOAD
                </a>
              )}
            </div>
          ),
        };
      });
      setTableData(data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    fetchReports();
  }, []);

  return (
    <>
      <Table columns={antColumns} dataSource={tableData} loading={loading} />
    </>
  );
};

export default ABMReports;
