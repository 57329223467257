import React from "react";
import { message, Table, Spin } from "antd";
import classes from "./styles/Audience.module.css";
import CreateEmailAudienceModal from "./CreateEmailAudienceModal";
import { getEmailAudienceList, editEmailAudience } from "../../api/audience";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

const antColumns = [
  {
    title: "Segment",
    dataIndex: "segmentName",
    key: "segmentName",
  },
  {
    title: "Total Domains",
    dataIndex: "totalDomains",
    key: "totalDomains",
  },
  {
    title: "Domains With Coverage",
    dataIndex: "domainsWithCoverage",
    key: "domainsWithCoverage",
  },
  {
    title: "Coverage",
    dataIndex: "coverage",
    key: "coverage",
  },
  {
    title: "User Count",
    dataIndex: "userCount",
    key: "userCount",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];

const EmailAudience = () => {
  const [createAudienceModal, setCreateAudienceModal] = React.useState(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [editObj, setEditObj] = React.useState<any>({});
  const [loading, setLoading] = React.useState({
    createAudience: false,
    fetchAudience: false,
    execAudience: false,
  });
  const [refetch, setRefetch] = React.useState(false);
  const [activeId, setActiveId] = React.useState(null);
  const [visible, setVisible] = React.useState(false);

  const refetchHandler = () => {
    setRefetch(!refetch);
  };

  const audienceToggle = (e: any) => {
    e.preventDefault();
    setCreateAudienceModal(!createAudienceModal);
  };

  const handleCancel = () => {
    setEditObj({});
    setCreateAudienceModal(false);
  };

  const loadingHandler = (key: any, val: any) => {
    setLoading({ ...loading, [key]: val });
  };

  const getStatus = (status: any) => {
    if (status !== "completed" && status !== "pending" && status !== "error")
      return "processing";
    return status;
  };

  const getColor = (status: any) => {
    if (status === "completed") return "#00c851";
    else if (status === "error") return "#d9534f";
    else if (status === "pending") return "#055697";
    else return "#33b5e5";
  };

  const handleExecAudience = async () => {
    try {
      loadingHandler("fetchAudience", true);
      await editEmailAudience(activeId);
      loadingHandler("fetchAudience", false);
      setActiveId(null);
      setVisible(false);
      refetchHandler();
    } catch (error) {
      loadingHandler("fetchAudience", false);

      console.log({ error });
      message.error("Something went wrong");
    }
  };

  React.useEffect(() => {
    if (activeId) setVisible(true);
  }, [activeId]);

  const fetchAudience = async () => {
    try {
      loadingHandler("fetchAudience", true);
      const res: any = await getEmailAudienceList();
      const data: any = res?.data?.map((item: any) => {
        return {
          id: item.id,
          createdAt: item.createdAt,
          segmentName: (
            <div>
              <div>{item.segmentName}</div>
              <div>
                Created At: {new Date(item.createdAt).toLocaleString() || ""}
              </div>
              {item?.status ? (
                <div>
                  Status:{" "}
                  <label
                    style={{
                      textTransform: "capitalize",
                      color: `${getColor(item.status)}`,
                    }}
                  >
                    {getStatus(item.status)}
                  </label>
                </div>
              ) : null}
            </div>
          ),
          totalDomains: item?.totalDomains,
          domainsWithCoverage: item?.domainsWithCoverage,
          coverage: <div>{item?.coverage && `${item?.coverage}%`}</div>,
          userCount: item?.userCount,
          actions: (
            <div>
              <button
                className="btn btn-success btn-sm "
                onClick={() => setActiveId(item.id)}
                disabled={item?.status ? true : false}
              >
                Run
              </button>
              <button
                className="btn btn-success btn-sm "
                onClick={() => setEditObj(item)}
              >
                {item?.status ? "View" : "Edit"}
              </button>
            </div>
          ),
        };
      });

      setTableData(data ?? []);
      loadingHandler("fetchAudience", false);
    } catch (error) {
      loadingHandler("fetchAudience", false);
      console.log({ error });
      message.error("Oops,something went wrong!");
    }
  };

  React.useEffect(() => {
    fetchAudience();
  }, [refetch]);

  React.useEffect(() => {
    if (Object.keys(editObj).length) setCreateAudienceModal(true);
  }, [editObj]);

  return (
    <>
      <div className={classes.headContainer}>
        <button
          className="btn btn-success btn-sm float-right"
          onClick={audienceToggle}
        >
          Create
        </button>
      </div>
      <Table
        columns={antColumns}
        dataSource={tableData}
        loading={loading.fetchAudience}
      />
      {createAudienceModal && (
        <CreateEmailAudienceModal
          handleCancel={handleCancel}
          isModalVisible={createAudienceModal}
          setCreateAudienceModal={setCreateAudienceModal}
          refetchHandler={refetchHandler}
          editObj={editObj}
          setEditObj={setEditObj}
        />
      )}
      <SweetAlert
        warning
        customButtons={
          <>
            <Button
              color="info"
              size="md"
              onClick={() => {
                setActiveId(null);
                setVisible(false);
              }}
            >
              Dismiss
            </Button>
            <Button color="info" size="md" onClick={() => handleExecAudience()}>
              Create
            </Button>
          </>
        }
        show={visible}
        title="Are you sure you want to create segment?"
        onConfirm={() => {}}
        onCancel={() => {
          setActiveId(null);
          setVisible(false);
        }}
        focusCancelBtn
      />
    </>
  );
};

export default EmailAudience;
