import React from "react";
import { Line } from "react-chartjs-2";

const options: any = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "A",
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "B",
      },
    ],
  },
};

const BwStatsChart = ({ chartReport, chartLabels, chartFields }: any) => {
  const [chartData, setChartData] = React.useState<any>({});

  React.useEffect(() => {
    formatData();
  }, [chartReport]);

  const formatData = () => {
    const labelsArr = [];
    const firstArr = [];
    const secondArr = [];
    for (const obj of chartReport) {
      labelsArr.push(obj[chartFields.dateParam]);
      let val: any = chartFields["firstParam"];
      const firstKey = keyMappingObject[val];
      val = chartFields["secondParam"];
      const secondKey = keyMappingObject[val];
      firstArr.push(obj[firstKey]);
      secondArr.push(obj[secondKey]);
    }
    data.datasets[0].label = chartLabels.firstParam;
    data.datasets[0].data = firstArr.reverse();
    data.datasets[1].label = chartLabels.secondParam;
    data.datasets[1].data = secondArr.reverse();
    data["labels"] = labelsArr.reverse();
    setChartData({ ...data });
  };

  return <Line options={options} data={chartData} height={80} />;
};

const data: any = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "rgb(255, 99, 132)",
      fill: false,
      yAxisID: "A",
    },
    {
      label: "",
      data: [],
      borderColor: "rgb(53, 162, 235)",
      fill: false,
      yAxisID: "B",
    },
  ],
};

const keyMappingObject: any = {
  spend: "Spend",
  revenue: "Revenue",
  impression: "Impressions",
  clicks: "Clicks",
  ctr: "CTR",
  cpm: "CPM",
  cpc: "CPC",
  conversion_count: "Conversions",
  clickthrough_conversions: "Clickthrough Conversions",
};

export default BwStatsChart;
