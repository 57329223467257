import React from "react";
import { MDBDataTable } from "mdbreact";
import RenderToast from "../../commons/RenderToast";
import TableLoader from "../../commons/loaders/TableLoader";
import { targetAccountFileView, removeDomains } from "../../api/target-account";
import { Checkbox } from "antd";
import { Button } from "reactstrap";

interface State {
  data: Object;
  files: Array<any>;
  loading: Boolean;
  fileId: any;
  checkedDomains: Array<any>;
  isModalOpen: Boolean;
}

const columns = [
  {
    label: "Domain",
    field: "domain",
    sort: "asc",
  },
  {
    label: "Locality",
    field: "locality",
    sort: "asc",
  },
  {
    label: "Zip Code",
    field: "zipcode",
    sort: "asc",
  },
];

class ViewTargetAccountFile extends React.Component<any, State> {
  state: State = {
    files: [],
    data: {
      columns,
    },
    loading: false,
    fileId: null,
    checkedDomains: [],
    isModalOpen: false,
  };

  handleModalToggle = () =>
    this.setState({ isModalOpen: !this.state.isModalOpen });

  onCheckboxChange = (e: any) => {
    if (e.target.checked) {
      this.setState({
        checkedDomains: this.state.checkedDomains.length
          ? [...this.state.checkedDomains, e.target.value]
          : [e.target.value],
      });
    } else {
      const filteredArray = this.state.checkedDomains.filter(
        (value) => value !== e.target.value
      );
      this.setState({
        checkedDomains: filteredArray.length ? [...filteredArray] : [],
      });
    }
  };

  isAllSelected = () => {
    return this.state.files.length &&
      this.state.files.length === this.state.checkedDomains.length
      ? true
      : false;
  };

  selectAllHandler = (e: any) => {
    this.setState({
      checkedDomains: e.target.checked
        ? this.state.files.map((file) => file.id)
        : [],
    });
  };

  /* Prepare date object for the datatable */
  createTableData = () => {
    let { files } = this.state;
    let rows = files.map((file: any, index: any) => ({
      domain: (
        <>
          <Checkbox
            value={file.id}
            onChange={this.onCheckboxChange}
            checked={this.state.checkedDomains.includes(file.id)}
          />
          <label style={{ marginLeft: "10px" }}>{file.domain}</label>
        </>
      ),
      locality: file.locality,
      zipcode: file.zipcode,
    }));
    this.setState({ data: { columns: columns, rows } });
  };

  viewSelectedFile = async () => {
    try {
      this.setState({ loading: true });
      var res = await targetAccountFileView(this.state.fileId);
      this.setState({ files: res.data.rows, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  componentDidMount = async () => {
    try {
      let paramsId = this.props.match.params.id;
      await this.setState({ fileId: paramsId });
      await this.viewSelectedFile();
      this.createTableData();
    } catch (error) {}
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.state.checkedDomains.length !== prevState.checkedDomains.length) {
      this.createTableData();
    }
  };

  deleteRecordHandler = async (e: any) => {
    e.preventDefault();
    try {
      let paramsId = this.props.match.params.id;
      await removeDomains(paramsId, {
        domainIdsToRemove: this.state.checkedDomains,
      });
      this.props.history.push("/audience-manger");
    } catch (error) {}
  };

  render(): JSX.Element {
    const { data }: { data: any } = this.state;
    return (
      <>
        <RenderToast />
        <div className="mt-3 d-flex justify-content-end align-items-center">
          {/* <button
            className="btn btn-success btn-sm"
            onClick={() => {
              if (this.isAllSelected()) {
                this.setState({
                  checkedDomains: [],
                });
              } else {
                this.setState({
                  checkedDomains: this.state.files.map((file) => file.id),
                });
              }
            }}
          >
            {this.isAllSelected() ? "Unselect All" : "Select All"}
          </button>
          <button
            className="btn btn-success btn-sm"
            onClick={this.handleModalToggle}
          >
            Add Domains
          </button> */}
          <button
            className="btn btn-success btn-sm"
            onClick={this.deleteRecordHandler}
            disabled={this.state.checkedDomains.length ? false : true}
          >
            Delete Records
          </button>
        </div>
        {this.state.loading ? (
          <TableLoader columns={columns} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            noBottomColumns
            responsive
            data={data}
            small={true}
          />
        )}
        
      </>
    );
  }
}

export default ViewTargetAccountFile;
