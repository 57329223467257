export default (obj: any) => {
  let compactObj = {};
  for (let key in obj) {
    if (obj[key] && !Array.isArray(obj[key]) && !(obj[key] instanceof Object)) {
      Object.assign(compactObj, {
        [key]: obj[key],
      });
    } else if (Array.isArray(obj[key]) && obj[key].length) {
      Object.assign(compactObj, {
        [key]: obj[key],
      });
    } else if (
      obj[key] instanceof Object &&
      Object.keys(obj[key]) &&
      Object.keys(obj[key]).length
    ) {
      Object.assign(compactObj, {
        [key]: obj[key],
      });
    }
  }
  return compactObj;
};
