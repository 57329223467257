const jobFuntions = [
  {
    children: [],
    title: "Consulting",
    key: "Consulting",
  },
  {
    children: [
      {
        children: [],
        title: "Biometrics",
        key: "Biometrics",
      },
      {
        children: [],
        title: "Biotechnical Engineering",
        key: "Biotechnical Engineering",
      },
      {
        children: [],
        title: "Chemical Engineering",
        key: "Chemical Engineering",
      },
      {
        children: [],
        title: "Civil Engineering",
        key: "Civil Engineering",
      },
      {
        children: [],
        title: "Database Engineering & Design",
        key: "Database Engineering & Design",
      },
      {
        children: [],
        title: "Engineering",
        key: "Engineering",
      },
      {
        children: [],
        title: "Hardware Engineering",
        key: "Hardware Engineering",
      },
      {
        children: [],
        title: "Industrial Engineering",
        key: "Industrial Engineering",
      },
      {
        children: [],
        title: "Information Technology",
        key: "Information Technology",
      },
      {
        children: [],
        title: "Quality Assurance",
        key: "Quality Assurance",
      },
      {
        children: [],
        title: "Software Engineering",
        key: "Software Engineering",
      },
      {
        children: [],
        title: "Technical",
        key: "Technical",
      },
      {
        children: [],
        title: "Web Development",
        key: "Web Development",
      },
    ],
    title: "Engineering & Technical",
    key: "Engineering & Technical",
  },
  {
    children: [
      {
        children: [],
        title: "Accounting",
        key: "Accounting",
      },
      {
        children: [],
        title: "Banking",
        key: "Banking",
      },
      {
        children: [],
        title: "Investment Banking",
        key: "Investment Banking",
      },
      {
        children: [],
        title: "Wealth Management",
        key: "Wealth Management",
      },
    ],
    title: "Finance",
    key: "Finance",
  },
  {
    children: [],
    title: "General Management",
    key: "General Management",
  },
  {
    children: [
      {
        children: [],
        title: "Benefits & Compensation",
        key: "Benefits & Compensation",
      },
      {
        children: [],
        title: "Diversity",
        key: "Diversity",
      },
      {
        children: [],
        title: "Human Resources Generalist",
        key: "Human Resources Generalist",
      },
      {
        children: [],
        title: "Recruiting",
        key: "Recruiting",
      },
      {
        children: [],
        title: "Sourcing",
        key: "Sourcing",
      },
    ],
    title: "Human Resources",
    key: "Human Resources",
  },
  {
    children: [
      {
        children: [],
        title: "Governmental Lawyers & Legal Professionals",
        key: "Governmental Lawyers & Legal Professionals",
      },
      {
        children: [],
        title: "Lawyers & Legal Professionals",
        key: "Lawyers & Legal Professionals",
      },
    ],
    title: "Legal",
    key: "Legal",
  },
  {
    children: [
      {
        children: [],
        title: "Advertising",
        key: "Advertising",
      },
      {
        children: [],
        title: "Branding",
        key: "Branding",
      },
      {
        children: [],
        title: "Communications",
        key: "Communications",
      },
      {
        children: [],
        title: "E-Biz",
        key: "E-Biz",
      },
      {
        children: [],
        title: "General Marketing",
        key: "General Marketing",
      },
      {
        children: [],
        title: "Product Management",
        key: "Product Management",
      },
    ],
    title: "Marketing",
    key: "Marketing",
  },
  {
    children: [
      {
        children: [],
        title: "Dentists",
        key: "Dentists",
      },
      {
        children: [],
        title: "Doctors",
        key: "Doctors",
      },
      {
        children: [],
        title: "Health Professionals",
        key: "Health Professionals",
      },
      {
        children: [],
        title: "Nurses",
        key: "Nurses",
      },
    ],
    title: "Medical & Health",
    key: "Medical & Health",
  },
  {
    children: [
      {
        children: [],
        title: "Change Management",
        key: "Change Management",
      },
      {
        children: [],
        title: "Compliance",
        key: "Compliance",
      },
      {
        children: [],
        title: "Contracts",
        key: "Contracts",
      },
      {
        children: [],
        title: "Customer Relations",
        key: "Customer Relations",
      },
      {
        children: [],
        title: "Facilities",
        key: "Facilities",
      },
      {
        children: [],
        title: "Logistics",
        key: "Logistics",
      },
      {
        children: [],
        title: "Quality Management",
        key: "Quality Management",
      },
    ],
    title: "Operations",
    key: "Operations",
  },
  {
    children: [
      {
        children: [],
        title: "Business Development",
        key: "Business Development",
      },
    ],
    title: "Sales",
    key: "Sales",
  },
  {
    children: [],
    title: "Scientists",
    key: "Scientists",
  },
];

const JOB_FUNCTIONS_SINGLE_LEVEL = [
  {
    children: [],
    title: "Consulting",
    key: "Consulting",
  },
  {
    children: [],
    title: "Engineering & Technical",
    key: "Engineering & Technical",
  },
  {
    children: [],
    title: "Finance",
    key: "Finance",
  },
  {
    children: [],
    title: "General Management",
    key: "General Management",
  },
  {
    children: [],
    title: "Human Resources",
    key: "Human Resources",
  },
  {
    children: [],
    title: "Legal",
    key: "Legal",
  },
  {
    children: [],
    title: "Marketing",
    key: "Marketing",
  },
  {
    children: [],
    title: "Medical & Health",
    key: "Medical & Health",
  },
  {
    children: [],
    title: "Operations",
    key: "Operations",
  },
  {
    children: [],
    title: "Sales",
    key: "Sales",
  },
  {
    children: [],
    title: "Scientists",
    key: "Scientists",
  },
];

export { jobFuntions, JOB_FUNCTIONS_SINGLE_LEVEL };
