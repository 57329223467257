import React from "react";
import { newPassword, getTokenResponse } from "../../api/user";
import { toast } from "react-toastify";
import RenderToast from "../../commons/RenderToast";
import { Card, CardBody, Form, Input, Button } from "reactstrap";
import { passwordandConfirmpass } from "../../utility/validations";

class SetNewPassword extends React.Component<any, any> {
  state: any = {
    password: "",
    confirmPassword: "",
    email: "",
    loading: false
  };
  extractToken = async () => {
    const params = new URLSearchParams(this.props.location.search);
    var token = params.get("token");
    const response = await getTokenResponse({ token: token });
    return response.data.email;
  };
  componentDidMount = async () => {
    this.setState({ email: await this.extractToken() });
  };
  handleSubmit = async (event: React.SyntheticEvent) => {
    this.setState({ loading: true });
    event.preventDefault();
    try {
      let validation = passwordandConfirmpass(
        this.state.password,
        this.state.confirmPassword
      );
      if (!validation.status) {
        throw validation.message;
      }
      await newPassword({
        email: this.state.email,
        password: this.state.confirmPassword
      });
      toast.success("password updated");
      this.props.history.push("/login");
      this.setState({ loading: false });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        toast.error("something went wrong");
      }
      toast.error(error);
      this.setState({ loading: false });
    }
  };
  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  render(): JSX.Element {
    return (
      <div className="bgImgLogin" style={{ minHeight: "100vh" }}>
        <RenderToast />
        <div
          className="justify-content-center d-flex align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <Card className="card-signin w-25 login-card">
            <CardBody className="my-4">
              <h4 className="card-title text-light text-center my-4">
                Set New Password
              </h4>
              <Form>
                <Input
                  className="text-light shadow-none 
                                                  bg-transparent border-bottom 
                                                  border-top-0 border-left-0 
                                                  border-right-0 rounded-0"
                  type="password"
                  name="password"
                  placeholder="enter password"
                  onChange={this.handleChange}
                />
                <br />
                <Input
                  className="text-light shadow-none 
                                                    bg-transparent border-bottom 
                                                    border-top-0 border-left-0 
                                                    border-right-0 rounded-0"
                  type="password"
                  name="confirmPassword"
                  placeholder="confirm Password"
                  onChange={this.handleChange}
                />
                <Button
                  disabled={
                    this.state.password &&
                    this.state.confirmPassword &&
                    !this.state.loading
                      ? false
                      : true
                  }
                  className="btn btn-lg btn-sm w-100 rounded-pill  mt-4 "
                  color="primary"
                  size="md"
                  onClick={this.handleSubmit}
                >
                  {this.state.loading ? "loading.." : "save"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
export default SetNewPassword;
