import React from "react";
import { Table } from "antd";
import { getHybridDomainsBySiteVisitor } from "../../../api/abm-stats";
import CustomAntTable from "../../../commons/CustomAntTable";

const antColumns = [
  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
  },
  {
    title: "Impressions Current",
    dataIndex: "impressionscur",
    key: "impressionscur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Impressions Delta",
    dataIndex: "impressionsdelta",
    key: "impressionsdelta",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Unique Visitors Current",
    dataIndex: "uniquevisitorscur",
    key: "uniquevisitorscur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Unique Visitors Delta",
    dataIndex: "uniquevisitorsdelta",
    key: "uniquevisitorsdelta",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Page Views Current",
    dataIndex: "totalpagescur",
    key: "totalpagescur",
    sorter: true,
    sortOrder: false,
  },
  {
    title: "Page Views Delta",
    dataIndex: "totalpagesdelta",
    key: "totalpagesdelta",
    sorter: true,
    sortOrder: false,
  },
];

const AccountsVisited = ({
  tableData,
  setTableData,
  changeLoadingHandler,
  campaigns,
  loading,
  filters,
  filterChangeHandler,
}: any) => {
  const [columns, setColumns] = React.useState(antColumns);

  const fetchData = async () => {
    try {
      changeLoadingHandler("tableData", true);
      // const inputData = formatSiteVisitorMetrics();
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getHybridDomainsBySiteVisitor(filters);
      const row: any = res?.data?.[0]?.result?.Row;
      const data = row.map((item: any) => {
        return {
          domain: item.domain,
          impressionscur: item.impressionscur,
          impressionsdelta: item.impressionsdelta,
          uniquevisitorscur: item.uniquevisitorscur,
          uniquevisitorsdelta: item.uniquevisitorsdelta,
          totalpagescur: item.totalpagescur,
          totalpagesdelta: item.totalpagesdelta,
        };
      });

      setTableData(data ?? []);
      changeLoadingHandler("tableData", false);
    } catch (error) {
      changeLoadingHandler("tableData", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchData();
    }
  }, [campaigns, filters]);

  return (
    <CustomAntTable
      columns={columns}
      setColumns={setColumns}
      tableData={tableData}
      loading={loading}
      filterChangeHandler={filterChangeHandler}
      filters={filters}
    />
  );
};

export default AccountsVisited;
