import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  CardHeader,
} from "reactstrap";
import styled from "styled-components";
import Select from "react-select";
import { create, getSiteVisitors } from "../../api/campaign-setup";
import {
  message,
  Select as AntSelect,
  Upload,
  Radio,
  Spin,
  Tree,
  Slider,
  Input,
  TreeSelect,
  InputNumber,
  Skeleton,
} from "antd";
import { withRouter } from "react-router";
import { fetchTargetFiles } from "../../api/target-account";
import { fetchKeywordFiles } from "../../api/campaignFiles";
import { UploadOutlined } from "@ant-design/icons";
import { jobFuntions } from "../../data/job-functions";
import { TOPICS } from "../../data/topics";
import { locations } from "../../data/locatoins";
import { INDUSTRIESDATA } from "../../data/INDUSTRIESDATA";
import {
  getCampaignSetupData,
  updateCampaignFile,
} from "../../api/campaign-setup";
import KeywordsDomain from "./KeywordsDomain";

import { fetchCompany } from "../../api/company";
import { connect } from "react-redux";
import { getLineItems, getAccounts } from "../../api/beeswax";

const StyledCard = styled(Card)``;
const { SHOW_PARENT } = TreeSelect;

class CreateCampainSetup extends Component<any, any> {
  state = {
    campaignId: "",
    campaignName: "",
    targetAccountFiles: [],
    targetFileOptions: [],
    idForEdit: NaN,
    CampaignSetupTargetFilesKeys: [],
    selectedKeywordsFiles: [],
    selectedKeywordsFileIds: [],
    previouseKeywordsFiles: [],
    selectedFile: null,
    keys: [],
    type: "campaign",
    loading: false,
    siteVisitorIds: [],
    siteVisitorOptions: [],
    topics: [],
    jobFunctions: [],
    employeeSizeMin: 0,
    employeeSizeMax: 1000000,
    annualRevenueMin: 0,
    annualRevenueMax: 10,
    industries: [],
    selectedLocations: [],
    editableData: {},
    duration: "",
    impressions: 10,
    companyId: "",
    companies: [],
    dspIds: [],
    bwLineItems: [],
    accounts: [],
    accountId: "",
    fetchBwAccountsLoading: false,
    isAdmin: this.props.auth.Role.name === "admin",
    dspIdsToUpdate: [],
    fetchLineItemsLoading: false,
  };

  createJoinArry = (value: any) => {
    return value.join("|");
  };

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleRadioChange = (event: any) => {
    this.setState({ type: event.target.value });
  };

  handleEmployeeRange = (range: any) => {
    let [employeeSizeMin, employeeSizeMax] = range;
    this.setState({
      employeeSizeMin,
      employeeSizeMax,
    });
  };

  handleRevenueRange = (range: any) => {
    let [annualRevenueMin, annualRevenueMax] = range;
    this.setState({
      annualRevenueMin,
      annualRevenueMax,
    });
  };

  fetchTargetFiles = async () => {
    try {
      let res = await fetchTargetFiles();
      let targetFileOptions = res.data.rows.map((value: any) => {
        return { label: value.fileName, value: value.id };
      });
      this.setState({ targetFileOptions });
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  handleSelection = (key: any, value: any) => {
    this.setState({ [key]: value });
  };

  onCheck = (key: string, checkedKeys: any) => {
    this.setState({ [key]: checkedKeys });
  };

  keywordsFile = async () => {
    try {
      let res = await fetchKeywordFiles();
      let keys = res.data.map((value: any) => {
        return { label: value.fileName, value: value.id };
      });
      this.setState({ keys });
    } catch (error) {
      console.log("Oops, error while fetching target files!");
    }
  };

  handlepreviousKeywordFilesSelect = (ids: any) => {
    this.setState({ selectedKeywordsFileIds: [...ids] });
  };
  handleFileUpload = async (event: any) => {
    if (event.file.status === "removed") {
      this.setState({ selectedFile: "" });
    } else {
      this.setState({ selectedFile: event.file.originFileObj });
    }
  };

  getSelectValue(value: any) {
    let data = value.map((item: any) => item.value);
    return data;
  }
  handleSubmit = async () => {
    try {
      const {
        campaignId,
        targetAccountFiles,
        selectedFile: file,
        selectedKeywordsFileIds: keywordFileIds,
        type,
        topics,
        jobFunctions,
        industries,
        employeeSizeMax,
        employeeSizeMin,
        annualRevenueMax,
        annualRevenueMin,
        siteVisitorIds,
        selectedLocations,
        duration,
        impressions,
        companyId,
        campaignName,
        dspIds,
      } = this.state;
      const targetFiles = this.getSelectValue(targetAccountFiles);
      const visitorIds = this.getSelectValue(siteVisitorIds);
      const lineItemIds = this.getSelectValue(dspIds);
      let payload: any = {
        campaignId,
        campaignName,
        targetAccountFiles: targetFiles,
        file,
        keywordFileIds,
        type,
        jobFunctions: this.createJoinArry(jobFunctions),
        topics: this.createJoinArry(topics),
        industries: this.createJoinArry(industries),
        motrolist: this.createJoinArry(selectedLocations),
        siteVisitorIds: visitorIds ?? [],
        employeemin: employeeSizeMin,
        employeemax: employeeSizeMax,
        revenueinthousandsmin: annualRevenueMin,
        revenueinthousandsmax: annualRevenueMax,
        duration,
        impressions,
        companyId,
        dspIds: lineItemIds ?? [],
      };
      payload = this.payloadGenerator(payload);
      await create(payload);
      message.success("Successfully created!");
      this.props.history.push("/campaign-setup");
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  getDataForUpdate = async () => {
    try {
      this.setState({ loading: true });
      let res: any = await getCampaignSetupData(this.state.idForEdit);
      if (!res.data.length) return;
      // try {
      //   const mappingArr: any = res.data[0]?.BwCampaignMappings;
      //   await this.fetchBwLineItems(
      //     mappingArr?.length ? mappingArr.map((obj: any) => obj.dspId) : []
      //   );
      // } catch (error) {
      //   this.setState({ loading: false });
      //   console.log("Unable to fetch beeswax campaigns");
      // }

      let options = res.data[0].CampaignSettingTargetFiles.map((item: any) => {
        return {
          value: item.targetFileId,
          label: item.targetFileId,
        };
      });
      let visitorOptions = res.data[0]?.SiteVisitors.map((item: any) => {
        return {
          value: item.siteVisitorId,
          label: item.siteVisitorId,
        };
      });
      let keywords: any = [];
      res.data[0].keywordFiles.map((item: any) => {
        return keywords.push(parseInt(item.id));
      });
      let editData = res.data[0].criteria;
      editData = JSON.parse(editData);

      this.setState({
        campaignId: res.data[0].CampaignKey.key,
        campaignName: res.data[0].CampaignKey.keyName,
        targetAccountFiles: options,
        selectedKeywordsFileIds: keywords,
        loading: false,
        type: res.data[0].CampaignKey.type,
        editableData: editData,
        duration: res.data[0]?.duration ?? "",
        impressions: res.data[0]?.impressions ?? 10,
        companyId: res.data[0]?.company?.id ?? "",
        siteVisitorIds: visitorOptions,
        accountId: res.data[0]?.company?.dspAccountId ?? "",
        dspIdsToUpdate: res.data[0]?.BwCampaignMappings?.length
          ? res.data[0]?.BwCampaignMappings?.map((item: any) => item.dspId)
          : [],
      });
      await this.setStateForEdit();
    } catch (error) {
      this.setState({ loading: false });
      message.error("Something went wrong");
    }
  };

  convertStringToArry = (value: any) => {
    if (value && value.length) {
      return value.split("|");
    } else {
      return [];
    }
  };

  setStateForEdit = () => {
    let { editableData }: { editableData: any } = this.state;
    this.setState({
      jobFunctions: this.convertStringToArry(editableData.jobFunctions),
      topics: this.convertStringToArry(editableData.topics),
      industries: this.convertStringToArry(editableData.industries),
      selectedLocations: this.convertStringToArry(editableData.motrolist),
      employeeSizeMax: editableData.employeemax,
      employeeSizeMin: editableData.employeemin,
      annualRevenueMax: editableData.revenueinthousandsmax,
      annualRevenueMin: editableData.revenueinthousandsmin,
    });
  };

  updateCampaignSetting = async () => {
    try {
      const {
        campaignId,
        targetAccountFiles,
        selectedFile: file,
        selectedKeywordsFileIds: keywordFileIds,
        type,
        selectedLocations,
        jobFunctions,
        industries,
        employeeSizeMax,
        employeeSizeMin,
        annualRevenueMax,
        annualRevenueMin,
        topics,
        duration,
        impressions,
        companyId,
        siteVisitorIds,
        campaignName,
        dspIds,
      } = this.state;
      const targetFiles = this.getSelectValue(targetAccountFiles);
      const visitorIds = this.getSelectValue(siteVisitorIds);
      const lineItemIds = this.getSelectValue(dspIds);
      let payload: any = {
        campaignId,
        targetAccountFiles: targetFiles,
        file,
        keywordFileIds,
        type,
        metrolist: this.createJoinArry(selectedLocations),
        industrylabellist: this.createJoinArry(industries),
        jobfunctionlist: this.createJoinArry(jobFunctions),
        topics: this.createJoinArry(topics),
        employeemax: employeeSizeMax,
        employeemin: employeeSizeMin,
        revenueinthousandsmax: annualRevenueMax,
        revenueinthousandsmin: annualRevenueMin,
        duration,
        impressions,
        companyId,
        siteVisitorIds: visitorIds,
        campaignName,
        dspIds: lineItemIds,
      };
      payload = this.payloadGenerator(payload);
      await updateCampaignFile({ payload, id: this.state.idForEdit });
      this.props.history.push("/campaign-setup");
      message.success("Successfully updated!");
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  payloadGenerator = (payload: any) => {
    let data = new FormData();
    for (let key in payload) {
      data.append(key, payload[key]);
    }
    return data;
  };

  fetchSiteVisitors = async () => {
    try {
      const res = await getSiteVisitors();
      let siteVisitorOptions = res.data.map((value: any) => {
        return { label: value.pageIdentifier, value: value.pageIdentifier };
      });
      this.setState({ siteVisitorOptions });
    } catch (error) {
      console.log({ error });
    }
  };

  fetchBwLineItems = async () => {
    try {
      this.setState({ fetchLineItemsLoading: true });
      const data: any = await getLineItems(this.state.accountId ?? "");
      const bwLineItemOptions = data.map((value: any) => {
        return { label: value.line_item_name, value: value.line_item_id };
      });
      const dspIds = [];
      for (const id of this.state.dspIdsToUpdate) {
        const lineItem = data.find((item: any) => item.line_item_id === id);
        if (lineItem) {
          dspIds.push({
            label: lineItem.line_item_name,
            value: lineItem.line_item_id,
          });
        }
      }
      this.setState({
        fetchLineItemsLoading: false,
        bwLineItems: bwLineItemOptions,
        dspIds,
      });
    } catch (error) {
      this.setState({ fetchLineItemsLoading: false });
      console.log({ error });
    }
  };

  fetchAccountsList = async () => {
    try {
      this.setState({ fetchBwAccountsLoading: true });
      const data: any = await getAccounts();
      this.setState({
        accounts: data && data.length ? data : [],
        fetchBwAccountsLoading: false,
      });
    } catch (error) {
      this.setState({ fetchBwAccountsLoading: false });
      console.log({ error });
    }
  };

  componentDidMount = async () => {
    try {
      let id = this.props.match.params.id;
      if (id) {
        await this.setState({ idForEdit: id });
        this.getDataForUpdate();
      }
      this.fetchTargetFiles();
      this.fetchSiteVisitors();
      if (this.props.auth.Role.name === "admin") this.fetchAccountsList();
      else if (!id) this.fetchBwLineItems();

      // this.keywordsFile();
    } catch (error) {}
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (prevState.accountId !== this.state.accountId) {
      this.fetchBwLineItems();
    }
  };

  handleImpressionsChange = (value: any) => {
    this.setState({ impressions: value });
  };

  render() {
    const { companies } = this.props;

    return (
      <div>
        {this.state.loading || this.state.fetchBwAccountsLoading ? (
          <div
            className="w-100 justify-content-center d-flex align-items-center bg-white"
            style={{ minHeight: "75vh" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Row>
              <Col md="6">
                <h4 className="mt-3 font-weight-bold">Campaign Setup Form</h4>
              </Col>
              {/* <Col md="6" className="d-flex justify-content-end align-item-end">
                <div className="mt-3">
                  <Radio.Group
                    onChange={this.handleRadioChange}
                    value={this.state.type}
                  >
                    <Radio value={"campaign"}>Campaign</Radio>
                    <Radio value={"accountDiscovery"}>Account Discovery</Radio>
                    <Radio value={"keywords"}>Keywords</Radio>
                  </Radio.Group>
                </div>
              </Col> */}
            </Row>
            {this.state.type !== "keywords" ? (
              <>
                <Row>
                  <Col md="6">
                    <StyledCard className="my-3">
                      <CardHeader>
                        <p className="font-weight-500 mb-0">
                          Campaign Settings
                        </p>
                      </CardHeader>
                      <CardBody>
                        <FormGroup>
                          <Label className="text-capitalize"> Company </Label>
                          <AntSelect
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            onChange={(value: any) => {
                              this.handleSelection("companyId", value);
                            }}
                            value={this.state.companyId ?? ""}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            }
                          >
                            {companies.map((company: any) => (
                              <AntSelect.Option value={company.id}>
                                {company.name}
                              </AntSelect.Option>
                            ))}
                          </AntSelect>
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label className="text-capitalize">
                                {" "}
                                Campaign Id{" "}
                              </Label>
                              <Input
                                type="text"
                                name="campaignId"
                                onChange={this.handleChange}
                                value={
                                  this.state.campaignId
                                    ? this.state.campaignId
                                    : ""
                                }
                                disabled={this.state.idForEdit ? true : false}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="text-capitalize">
                                {" "}
                                Campaign Name{" "}
                              </Label>
                              <Input
                                type="text"
                                name="campaignName"
                                onChange={this.handleChange}
                                value={
                                  this.state.campaignName
                                    ? this.state.campaignName
                                    : ""
                                }
                                disabled={this.state.idForEdit ? true : false}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        {this.state.type === "campaign" && (
                          <>
                            <FormGroup>
                              <Label className="text-capitalize">
                                Site Visitor Id
                              </Label>
                              <Select
                                isMulti={true}
                                onChange={(value: any) => {
                                  this.handleSelection("siteVisitorIds", value);
                                }}
                                options={this.state.siteVisitorOptions}
                                value={(() => {
                                  let options: any = [];
                                  if (
                                    this.state.siteVisitorIds &&
                                    this.state.siteVisitorIds.length
                                  ) {
                                    this.state.siteVisitorOptions.forEach(
                                      (item: any) => {
                                        this.state.siteVisitorIds.forEach(
                                          (x: any) => {
                                            if (item.value === x.value) {
                                              options.push(item);
                                            }
                                          }
                                        );
                                      }
                                    );
                                    return options;
                                  }
                                })()}
                              />
                            </FormGroup>
                            {this.state.isAdmin && (
                              <FormGroup>
                                <Label
                                  for="emailAddress"
                                  className="text-muted"
                                >
                                  DSP Account ID
                                </Label>
                                <AntSelect
                                  placeholder="Select account id"
                                  style={{ width: "100%" }}
                                  onChange={(value) =>
                                    this.handleSelection("accountId", value)
                                  }
                                  value={this.state.accountId}
                                >
                                  {this.state.accounts.length
                                    ? this.state.accounts.map(
                                        (account: any) => (
                                          <AntSelect.Option value={account.id}>
                                            {account.name}
                                          </AntSelect.Option>
                                        )
                                      )
                                    : null}
                                </AntSelect>
                              </FormGroup>
                            )}

                            <FormGroup>
                              <Label className="text-capitalize">
                                DSP Line Item Id
                              </Label>
                              {this.state.fetchLineItemsLoading ? (
                                <Skeleton.Button
                                  size={"default"}
                                  style={{ width: "100%" }}
                                  active
                                />
                              ) : (
                                <Select
                                  isMulti={true}
                                  onChange={(value: any) => {
                                    this.handleSelection("dspIds", value);
                                  }}
                                  options={this.state.bwLineItems}
                                  placeholder={`${
                                    !this.state.accountId && this.state.isAdmin
                                      ? "Select Account first"
                                      : "Select..."
                                  }`}
                                  value={(() => {
                                    let options: any = [];
                                    if (
                                      this.state.dspIds &&
                                      this.state.dspIds.length
                                    ) {
                                      this.state.bwLineItems.forEach(
                                        (item: any) => {
                                          this.state.dspIds.forEach(
                                            (x: any) => {
                                              if (item.value === x.value) {
                                                options.push(item);
                                              }
                                            }
                                          );
                                        }
                                      );
                                      return options;
                                    }
                                  })()}
                                />
                              )}
                            </FormGroup>

                            {/* <div>
                              <Label>Keyword Files</Label>
                              <AntSelect
                                mode="multiple"
                                style={{ width: "100%" }}
                                disabled={
                                  this.state.selectedFile ? true : false
                                }
                                placeholder="Select Keywords"
                                onChange={this.handlepreviousKeywordFilesSelect}
                                optionLabelProp="label"
                                value={[...this.state.selectedKeywordsFileIds]}
                              >
                                {this.state.keys.map(
                                  (file: any, index: any) => (
                                    <Option
                                      key={`keyword_file_${index}`}
                                      value={file.value}
                                      label={file.label}
                                    >
                                      <div className="demo-option-label-item">
                                        {file.label}
                                      </div>
                                    </Option>
                                  )
                                )}
                              </AntSelect>
                              <br />

                              {!this.state.selectedKeywordsFileIds.length ? (
                                <Upload
                                  {...this.props}
                                  onChange={this.handleFileUpload}
                                >
                                  <Button color="light" size="sm">
                                    <UploadOutlined />
                                    Upload now
                                  </Button>
                                </Upload>
                              ) : (
                                ""
                              )}
                            </div> */}
                          </>
                        )}
                      </CardBody>
                    </StyledCard>
                  </Col>
                  {/* <Col md="6">
                    <StyledCard className="my-3">
                      <CardHeader>
                        <p className="font-weight-500 mb-0">Persona Filters</p>
                      </CardHeader>
                      <CardBody>
                        <h6 className="text-capitalize"> Job Function </h6>
                        <Tree
                          checkedKeys={this.state.jobFunctions}
                          style={{
                            minHeight: "250px",
                            maxHeight: "250px",
                            overflow: "auto",
                          }}
                          checkable
                          onCheck={(checkedKeys: any) => {
                            this.onCheck("jobFunctions", checkedKeys);
                          }}
                          treeData={jobFuntions}
                        />
                        <br />
                        <TreeSelect
                          {...{
                            treeData: jobFuntions,
                            allowClear: true,
                            value: this.state.jobFunctions,
                            onChange: (checkedKeys: any) => {
                              this.onCheck("jobFunctions", checkedKeys);
                            },
                            treeCheckable: true,
                            showCheckedStrategy: SHOW_PARENT,
                            placeholder: "Please select",
                            style: {
                              width: "100%",
                              paddingBottom: "14px",
                            },
                          }}
                        />
                      </CardBody>
                    </StyledCard>
                  </Col> */}
                  <Col md="6">
                    <StyledCard className="my-3">
                      <CardHeader>
                        <p className="font-weight-500 mb-0">Topics</p>
                      </CardHeader>
                      <CardBody>
                        <FormGroup>
                          <Tree
                            checkedKeys={this.state.topics}
                            style={{
                              minHeight: "250px",
                              maxHeight: "250px",
                              overflow: "auto",
                            }}
                            checkable
                            onCheck={(checkedKeys: any) => {
                              this.onCheck("topics", checkedKeys);
                            }}
                            treeData={TOPICS}
                          />
                          <br />
                          <TreeSelect
                            {...{
                              treeData: TOPICS,
                              allowClear: true,
                              value: this.state.topics,
                              onChange: (checkedKeys: any) => {
                                this.onCheck("topics", checkedKeys);
                              },
                              treeCheckable: true,
                              showCheckedStrategy: SHOW_PARENT,
                              placeholder: "Please select",
                              style: {
                                width: "100%",
                              },
                            }}
                          />
                        </FormGroup>
                      </CardBody>
                    </StyledCard>
                    <br></br>
                    {/* <StyledCard>
                      <CardHeader>
                        <p className="font-weight-500 mb-0">Locations</p>
                      </CardHeader>
                      <CardBody>
                      <FormGroup>
                            <Tree
                              checkedKeys={this.state.selectedLocations}
                              style={{
                                minHeight: "250px",
                                maxHeight: "250px",
                                overflow: "auto",
                              }}
                              checkable
                              onCheck={(checkedKeys: any) => {
                                this.onCheck("selectedLocations", checkedKeys);
                              }}
                              treeData={locations}
                            />
                            <br />
                            <TreeSelect
                              {...{
                                treeData: locations,
                                allowClear: true,
                                value: this.state.selectedLocations,
                                onChange: (checkedKeys: any) => {
                                  this.onCheck(
                                    "selectedLocations",
                                    checkedKeys
                                  );
                                },
                                treeCheckable: true,
                                showCheckedStrategy: SHOW_PARENT,
                                placeholder: "Please select",
                                style: {
                                  width: "100%",
                                },
                              }}
                            />
                          </FormGroup>
                      </CardBody>
                    </StyledCard> */}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <StyledCard>
                      <CardHeader>
                        <p className="font-weight-500 mb-0">
                          Firmographic Filters
                        </p>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="12">
                            {this.state.type === "campaign" && (
                              <FormGroup>
                                <Label>Target Account File</Label>
                                <Select
                                  isMulti={true}
                                  menuPlacement="auto"
                                  onChange={(value: any) => {
                                    this.handleSelection(
                                      "targetAccountFiles",
                                      value
                                    );
                                  }}
                                  options={this.state.targetFileOptions}
                                  value={(() => {
                                    let options: any = [];
                                    if (
                                      this.state.targetAccountFiles &&
                                      this.state.targetAccountFiles.length
                                    ) {
                                      this.state.targetFileOptions.forEach(
                                        (item: any) => {
                                          this.state.targetAccountFiles.forEach(
                                            (x: any) => {
                                              if (item.value === x.value) {
                                                options.push(item);
                                              }
                                            }
                                          );
                                        }
                                      );
                                      return options;
                                    }
                                  })()}
                                />{" "}
                              </FormGroup>
                            )}

                            {/* <FormGroup>
                              <Label className="text-capitalize">
                                {" "}
                                Industries{" "}
                              </Label>
                              <Tree
                                checkedKeys={this.state.industries}
                                style={{
                                  minHeight: "250px",
                                  maxHeight: "250px",
                                  overflow: "auto",
                                }}
                                checkable
                                onCheck={(checkedKeys: any) => {
                                  this.onCheck("industries", checkedKeys);
                                }}
                                treeData={INDUSTRIESDATA}
                              />
                              <br />
                              <TreeSelect
                                {...{
                                  treeData: INDUSTRIESDATA,
                                  allowClear: true,
                                  value: this.state.industries,
                                  onChange: (checkedKeys: any) => {
                                    this.onCheck("industries", checkedKeys);
                                  },
                                  treeCheckable: true,
                                  showCheckedStrategy: SHOW_PARENT,
                                  placeholder: "Please select",
                                  style: {
                                    width: "100%",
                                  },
                                }}
                              />
                            </FormGroup> */}

                            {/* <>
                                <FormGroup>
                                  <h6> Employe size </h6>
                                  <br />
                                  <div className="mx-2">
                                    <Slider
                                      range
                                      value={[
                                        this.state.employeeSizeMin,
                                        this.state.employeeSizeMax,
                                      ]}
                                      onChange={this.handleEmployeeRange}
                                    />
                                  </div>
                                  <br />
                                  <h6> Annual Revenue </h6>
                                  <br />
                                  <div className="mx-2">
                                    <Slider
                                      range
                                      value={[
                                        this.state.annualRevenueMin,
                                        this.state.annualRevenueMax,
                                      ]}
                                      onChange={this.handleRevenueRange}
                                    />
                                  </div>
                                </FormGroup>
                              </> */}
                          </Col>
                        </Row>
                      </CardBody>
                    </StyledCard>
                  </Col>
                  <Col md="6">
                    <StyledCard>
                      <CardHeader>
                        <p className="font-weight-500 mb-0">Frequency Cap</p>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg="6" md="12">
                            <FormGroup>
                              <Label>Duration</Label>
                              <AntSelect
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                onChange={(value: any) => {
                                  this.handleSelection("duration", value);
                                }}
                                value={this.state.duration ?? ""}
                              >
                                <AntSelect.Option value={"1 week"}>
                                  1 week
                                </AntSelect.Option>
                                <AntSelect.Option value={"2 weeks"}>
                                  2 weeks
                                </AntSelect.Option>
                                <AntSelect.Option value={"4 weeks"}>
                                  4 weeks
                                </AntSelect.Option>
                                <AntSelect.Option value={"forever"}>
                                  Forever
                                </AntSelect.Option>
                              </AntSelect>
                            </FormGroup>
                          </Col>
                          <Col lg="6" md="12">
                            <FormGroup>
                              <Label>Impressions</Label>
                              <InputNumber
                                min={1}
                                style={{ width: "100%" }}
                                onChange={this.handleImpressionsChange}
                                value={this.state.impressions}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </StyledCard>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Button
                    className="mx-3"
                    size="sm"
                    color="primary"
                    onClick={() => {
                      if (this.state.idForEdit) {
                        this.updateCampaignSetting();
                      } else {
                        this.handleSubmit();
                      }
                    }}
                    // disabled={
                    //   this.state.campaignId &&
                    //   this.state.targetAccountFiles &&
                    //   this.state.targetAccountFiles.length
                    //     ? false
                    //     : true
                    // }
                  >
                    {this.state.idForEdit ? "Update" : "Save"}
                  </Button>
                </Row>
              </>
            ) : (
              <KeywordsDomain />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  companies: state.companies.list,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(CreateCampainSetup));
