import axios from "./axios-configured";
import { formatUrl } from "../utilities";

export const get = async () => {
  return axios.get("/admin-campaign-setup");
};

export const getAssignedCampaigns = async (companyId = 0) => {
  return companyId
    ? axios.get(
        `/admin-campaign-setup/assigned-campaigns?companyId=${companyId}`
      )
    : axios.get(`/admin-campaign-setup/assigned-campaigns`);
};

export const create = async (payload: any) => {
  return axios.post("/admin-campaign-setup", payload);
};

export const remove = async (id: number) => {
  return axios.delete(`/admin-campaign-setup/${id}`);
};
export const getCampaignSetupData = async (id: number) => {
  return axios.get(`/admin-campaign-setup/${id}`);
};
export const updateCampaignFile = async (data: any) => {
  return axios.put(`/admin-campaign-setup/${data.id}`, data.payload);
};
export const uploadFile = async (data: any, type: String) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(`/admin_campaign_file_setup?type=${type}`, data, config);
};
export const getLatestFileDetail = async () => {
  let { data } = await axios.get(`/admin_campaign_file_setup/latestFileDetail`);
  return data;
};

export const saveCampignKeys = async (payload: any, fileId: number) => {
  return axios.post(
    `/admin_campaign_file_setup/${fileId}/saveCampignKeys`,
    payload
  );
};

export const submitCampignKeys = async (payload: any, fileId: number) => {
  return axios.post(`/admin_campaign_file_setup/${fileId}/job`, payload);
};
export const campaignNameUploadFile = async (data: any) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(`/admin-campaign-setup/campaignName`, data, config);
};

export const getSiteVisitors = async () => {
  return axios.get(`/site-visitors`);
};
