import React from "react";
import Expression from "../Expression";

const SiteVistitorTd = ({ data }: { data: any }) => {
  
  return (
      <td className="text-center">
        <div className="d-flex">

          <small
            className="d-inline-block text-truncate text-muted"
          >
            Current Week: &nbsp;&nbsp;
            <Expression
                                    oldValue={data.weeklySiteVisitorUniquePrevious}
                                    value={data.weeklySiteVisitorUniqueCurrent}
                                    percentAgeValue={`${data.weeklySiteVisitorUniquePercentChange || 0}%`}
                                />
           
          </small>
        </div>
        <div className="d-flex">
          <small
            className="d-inline-block text-truncate text-muted"
          >
            Current Month:  &nbsp;&nbsp;
            <Expression
                                    oldValue={data.monthlySiteVisitorUniquePrevious}
                                    value={data.monthlySiteVisitorUniqueCurrent}
                                    percentAgeValue={`${data.monthlySiteVisitorUniquePercentChange || 0}%`}
                                />
            
          </small>
        </div>
      </td>
  );
};

export default SiteVistitorTd;
